import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const UltraSound = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [ultraSounds, setUltraSounds] = useState(null);
	useEffect(() => {
		const getUltraSounds = async () => {
			const response = await fetchWrapper.get(
				`/patient/profile/${id}/ultraSound?page=${page}`
			);
			setUltraSounds(response);
		};
		try {
			getUltraSounds();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>SN</th>
								<th>Date</th>
								<th>Gest. Age (weeks)</th>
								<th className="text-center">Estimated Baby Weight (gm)</th>
								<th className="text-center">Abdominal Circumference (cm)</th>
								<th className="text-center">Head Circumference (cm)</th>
								<th className="text-center">Humerus Length (cm)</th>
								<th className="text-center">Placenta Position</th>
								<th className="text-center">Presentation</th>
								<th className="text-center">Fetal Heart Rate (bpm)</th>
								<th className="text-center">CRL (cm)</th>
								<th className="text-center">BPD (cm)</th>
								<th className="text-center">FL (cm)</th>
								<th className="text-center">AFI (cm)</th>
								<th>USG Date</th>
								<th>Remarks</th>
							</tr>
						</thead>
						<tbody>
							{ultraSounds?.results?.map((ultrasound, i) => (
								<tr key={ultrasound?._id}>
									<td>{i + 1}</td>
									<td>{ultrasound?.postedAt}</td>
									<td>{ultrasound?.gestAge}</td>
									<td className="text-center">{ultrasound?.estBodyWeight}</td>
									<td className="text-center">{ultrasound?.abdoCircum}</td>
									<td className="text-center">{ultrasound?.HeadCircum}</td>
									<td className="text-center">{ultrasound?.humerusLength}</td>
									<td className="text-center">
										{ultrasound?.PlacentaPosition}
									</td>
									<td className="text-center">{ultrasound?.Presentation}</td>
									<td className="text-center">{ultrasound?.fetalHeartRate}</td>
									<td className="text-center">{ultrasound?.CRL}</td>
									<td className="text-center">{ultrasound?.BPD}</td>
									<td className="text-center">{ultrasound?.FL}</td>
									<td className="text-center">{ultrasound?.AFI}</td>
									<td>{ultrasound?.USGExpectedDate?.split('T')[0]}</td>
									<td>{ultrasound?.remarks}</td>
								</tr>
							))}
							{/* <tr>
								<td>1</td>
								<td>2022/09/10</td>
								<td>7</td>
								<td className="text-center">3</td>
								<td className="text-center">5</td>
								<td className="text-center">6</td>
								<td>2023/04/05</td>
							</tr> */}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={ultraSounds?.totalResults}
			/>
		</Fragment>
	);
};

export default UltraSound;
