import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Table, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import styled from '@emotion/styled';

const PostNatal = () => {
	const { id, pageNum } = useParams();
	const [postNatalType, setPostNatalType] = useState('mother');
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [postNatal, setPostNatal] = useState(null);
	useEffect(() => {
		const getPostNatal = async () => {
			let response = await fetchWrapper.get(
				`/patient/profile/${id}/postNatal?page=${page}`
			);
			setPostNatal(response);
		};
		try {
			getPostNatal();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<div className="d-flex p-3 align-items-center">
					<Form.Label htmlFor="trimester-select" className="mr-3 my-0">
						Trimester
					</Form.Label>
					<Form.Control
						as="select"
						id="trimester-select"
						className="w-25"
						onChange={(e) => setPostNatalType(e.target.value)}
					>
						<option value="" disabled>
							Select
						</option>
						<option
							value="mother"
							{...(postNatalType === 'mother' && { selected: true })}
						>
							Mother {postNatalType === 'mother' && '::Active'}
						</option>
						<option
							value="baby"
							{...(postNatalType === 'baby' && {
								selected: true,
							})}
						>
							Baby {postNatalType === 'baby' && '::Active'}
						</option>
					</Form.Control>
				</div>
				<StyledTable>
					<Table striped hover responsive>
						<thead>
							<Fragment>
								{postNatalType === 'mother' && (
									<Fragment>
										<tr>
											<th rowSpan={2}>No Issue</th>
											<th rowSpan={2}>Nausea</th>
											<th rowSpan={2}>Vomiting</th>
											<th rowSpan={2}>Abdominal Pain</th>
											<th rowSpan={2}>Abdominal Distension</th>
											<th rowSpan={2}>Fever</th>
											<th rowSpan={2}>Decreased Appetite</th>
											<th rowSpan={2}>PV Bleeding</th>
											<th rowSpan={2}>PV Discharge</th>
											<th rowSpan={2}>PV Spotting</th>
											<th rowSpan={2}>PV Discharge Foul Smelling</th>
											<th rowSpan={2}>Headache</th>
											<th rowSpan={2}>Jaundice</th>
											<th colSpan={3} className="text-center">
												Swelling
											</th>
											<th rowSpan={2}>Blurring Of Vision</th>
											<th rowSpan={2}>Convulsions</th>
											<th rowSpan={2}>Shortness Of Breath</th>
											<th rowSpan={2}>Burning Urination</th>
											<th rowSpan={2}>Diarrhea</th>
											<th rowSpan={2}>Constipation</th>
											<th rowSpan={2}>Cough</th>
											<th rowSpan={2}>Decreased Urine Output</th>
											<th rowSpan={2}>Engorged Breast</th>
											<th rowSpan={2}>Breast Feeding</th>
										</tr>
										<tr>
											<th>Face</th>
											<th>Feet</th>
											<th>Whole Body</th>
										</tr>
									</Fragment>
								)}
								{postNatalType === 'baby' && (
									<tr>
										<th>Is Fine</th>
										<th>Is Sucking Well</th>
										<th>Is Feeding Well</th>
										<th>Excessive Crying</th>
										<th>Lethargic</th>
										<th>Difficult Breathing</th>
										<th>Increased Breathing</th>
										<th>No Breathing</th>
										<th>Fever</th>
										<th>Yellowish Discoloration</th>
										<th>Distended Abdomen</th>
										<th>Vomiting</th>
										<th>Abnormal Stools</th>
										<th>Crying During Micturation</th>
										<th>Hypothermia</th>
										<th>Abnormal Body Movements</th>
										<th>Cyanosis</th>
										<th>Skin Rash</th>
									</tr>
								)}
							</Fragment>
						</thead>
						<tbody>
							{postNatal?.results?.map((pn) => (
								<tr key={pn?._id}>
									{postNatalType === 'mother' && (
										<Fragment>
											<td>{pn?.noIssue}</td>
											<td>{pn?.nausea}</td>
											<td>{pn?.vomiting}</td>
											<td>{pn?.abdominalPain}</td>
											<td>{pn?.abdominalDistension}</td>
											<td>{pn?.fever}</td>
											<td>{pn?.decreasedAppetite}</td>
											<td>{pn?.PVBleeding}</td>
											<td>{pn?.PVDischarge}</td>
											<td>{pn?.PVSpotting}</td>
											<td>{pn?.PVDischargeFoulSmelling}</td>
											<td>{pn?.headache}</td>
											<td>{pn?.jaundice}</td>
											<td>{pn?.swelling?.face}</td>
											<td>{pn?.swelling?.feet}</td>
											<td>{pn?.swelling?.wholeBody}</td>
											<td>{pn?.blurringOfVision}</td>
											<td>{pn?.convulsions}</td>
											<td>{pn?.shortnessOfBreath}</td>
											<td>{pn?.burningUrination}</td>
											<td>{pn?.diarrhea}</td>
											<td>{pn?.constipation}</td>
											<td>{pn?.cough}</td>
											<td>{pn?.decreasedUrineOutput}</td>
											<td>{pn?.engorgedBreast}</td>
											<td>{pn?.breastFeeding}</td>
										</Fragment>
									)}
									{postNatalType === 'baby' && (
										<Fragment>
											<td>{pn?.baby?.isFine}</td>
											<td>{pn?.baby?.isSuckingWell}</td>
											<td>{pn?.baby?.isFeedingWell}</td>
											<td>{pn?.baby?.ExcessiveCrying}</td>
											<td>{pn?.baby?.Lethargic}</td>
											<td>{pn?.baby?.Difficultbreathing}</td>
											<td>{pn?.baby?.IncreasedBreathing}</td>
											<td>{pn?.baby?.NoBreathing}</td>
											<td>{pn?.baby?.Fever}</td>
											<td>{pn?.baby?.YellowishDiscoloration}</td>
											<td>{pn?.baby?.DistendedAbdomen}</td>
											<td>{pn?.baby?.Vomiting}</td>
											<td>{pn?.baby?.AbnormalStools}</td>
											<td>{pn?.baby?.CryingDuringMicturation}</td>
											<td>{pn?.baby?.Hypothermia}</td>
											<td>{pn?.baby?.AbnormalBodyMovements}</td>
											<td>{pn?.baby?.Cyanosis}</td>
											<td>{pn?.baby?.SkinRash}</td>
										</Fragment>
									)}
								</tr>
							))}
						</tbody>
					</Table>
				</StyledTable>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={postNatal?.totalResults}
			/>
		</Fragment>
	);
};

export default PostNatal;

const StyledTable = styled.div`
	width: calc(100vw - 300px);
	table {
		table-layout: auto;
	}
`;
