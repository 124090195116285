import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Accordion, Badge, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import symptoms from "../../../_datas/symptoms";
import threshold from "../../../_datas/threshold";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import PaginationComp from "../../../components/Pagination";

const trimesterMap = {
  first: "FirstTrimester",
  second: "SecondTrimester",
  third: "ThirdTrimester",
  postnatal: "PostNatalMother",
  termination: "termination",
};

const DailySymptoms = () => {
  const { id, pageNum, trimester } = useParams();
  const [trimesterState, setTrimesterState] = useState(trimesterMap[trimester]);

  const history = useHistory();
  const [dailySymptoms, setDailySymptoms] = useState({ results: [] });
  const [page, setPage] = useState(pageNum);
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  const handleSetTrimester = (e) => {
    setTrimesterState(e.target.value);
  };

  const getDailySymptoms = useCallback(async () => {
    let url = "";
    switch (trimesterState) {
      case "FirstTrimester":
        url = `/patient/profile/${id}/dailySymptoms?page=${pageNum}&symptomsType=FirstTrimester`;
        break;
      case "SecondTrimester":
        url = `/patient/profile/${id}/dailySymptoms?page=${pageNum}&symptomsType=SecondTrimester`;
        break;
      case "ThirdTrimester":
        url = `/patient/profile/${id}/dailySymptoms?page=${pageNum}&symptomsType=ThirdTrimester`;
        break;
      case "PostNatalMother":
        url = `/patient/profile/${id}/postnatal?page=${pageNum}`;
        break;
      case "PostNatalBaby":
        url = `/patient/profile/${id}/babySymptom?page=${pageNum}`;
        break;
      case "termination":
        url = `/patient/profile/${id}/terminationSymptoms?page=${pageNum}`;
        break;
      default:
        url = `/patient/profile/${id}/dailySymptoms?page=${pageNum}&symptomsType=FirstTrimester`;
        break;
    }
    let response = await fetchWrapper.get(url);
    setDailySymptoms(response);
  }, [id, pageNum, trimesterState]);

  useEffect(() => {
    try {
      getDailySymptoms();
    } catch (error) {}
  }, [getDailySymptoms]);
  //
  return (
    <Fragment>
      <Card>
        <div>
          <div className="d-flex p-3 align-items-center">
            <Form.Label htmlFor="trimester-select" className="mr-3 my-0">
              Trimester/Phase
            </Form.Label>
            <Form.Control
              as="select"
              id="trimester-select"
              className="w-25"
              onChange={handleSetTrimester}
            >
              <option value="" disabled>
                Select Trimester
              </option>
              <option
                value="FirstTrimester"
                {...(trimesterState === "FirstTrimester" && { selected: true })}
              >
                First Trimester {trimester === "first" && "::Active"}
              </option>
              <option
                value="SecondTrimester"
                {...(trimesterState === "SecondTrimester" && {
                  selected: true,
                })}
              >
                Second Trimester {trimester === "second" && "::Active"}
              </option>
              <option
                value="ThirdTrimester"
                {...(trimesterState === "ThirdTrimester" && { selected: true })}
              >
                Third Trimester {trimester === "third" && "::Active"}
              </option>
              <option
                value="PostNatalMother"
                {...(trimesterState === "postnatal" && {
                  selected: true,
                })}
              >
                Post Natal Mother {trimester === "postnatal" && "::Active"}
              </option>
              <option
                value="PostNatalBaby"
                {...(trimesterState === "postnatal" && { selected: true })}
              >
                Post Natal Baby {trimester === "postnatal" && "::Active"}
              </option>
              <option
                value="termination"
                {...(trimesterState === "termination" && { selected: true })}
              >
                Termination {trimester === "termination" && "::Active"}
              </option>
            </Form.Control>
          </div>
          <Accordion defaultActiveKey="0">
            {dailySymptoms?.results?.map((symptom, index) => (
              <Card key={index}>
                <Card.Header>
                  <Accordion.Toggle variant="link" eventKey={`${index}`}>
                    <div>
                      <h5>
                        {symptom?.postedAt}{" "}
                        {moment(symptom?.createdAt).format("hh:mm A")}
                      </h5>
                      <div
                        className="d-flex py-1"
                        style={{ columnGap: "0.5rem" }}
                      >
                        {symptom?.noSymptoms ? (
                          <Badge variant="success">Everything is fine</Badge>
                        ) : (
                          <Badge variant="warning">Needs Attention</Badge>
                        )}
                        {symptom?.emergency && (
                          <Badge variant="danger">Emergency</Badge>
                        )}
                      </div>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body style={{ backgroundColor: "#e4e4e4" }}>
                    <Row>
                      {symptoms[trimesterState]?.map((s, i) => (
                        <Cell
                          key={i}
                          symptom={symptom}
                          symptomStructure={s}
                          trimesterState={trimesterState}
                        />
                      ))}
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
          {/* <StyledTable>
            <Table striped responsive hover>
              {trimesterState === "FirstTrimester" ? (
                <thead>
                  <tr>
                    <th rowSpan={2}>Date/Time</th>
                    <th rowSpan={2}>Symptoms Type</th>
                    <th rowSpan={2}>Symptoms</th>
                    <th colSpan={12} className="text-center">
                      Symptoms
                    </th>
                    <th rowSpan={2}>Sucidal Thoughts</th>
                    <th rowSpan={2}>Depression</th>
                    <th rowSpan={2}>Anxiety</th>
                    <th rowSpan={2}>Happiness</th>
                    <th rowSpan={2}>Dizziness</th>
                    <th rowSpan={2}>Others</th>
                  </tr>
                  <tr>
                    <th>PV Bleeding</th>
                    <th>PV Discharge</th>
                    <th>PV Discharge Foul Smelling</th>
                    <th>PV Show</th>
                    <th>PV Spotting</th>
                    <th>Abdominal Distension</th>
                    <th>Abdominal Pain</th>
                    <th>Decreased Appetite</th>
                    <th>Dizziness</th>
                    <th>Fever</th>
                    <th>Nausea</th>
                    <th>Vomiting</th>
                  </tr>
                </thead>
              ) : (
                <thead>
                  <tr>
                    <th rowSpan={3}>Date/Time</th>
                    <th rowSpan={3}>Symptoms Type</th>
                    <th rowSpan={3} className="text-center">
                      Symptoms
                    </th>
                    <th colSpan={19} className="text-center">
                      Symptoms
                    </th>
                    <th rowSpan={3}>Sucidal Thoughts</th>
                    <th rowSpan={3}>Depression</th>
                    <th rowSpan={3}>Anxiety</th>
                    <th rowSpan={3}>Happiness</th>
                    <th rowSpan={3}>Dizziness</th>
                    <th rowSpan={3}>Others</th>
                  </tr>
                  <tr>
                    <th rowSpan={2} className="text-center">
                      Decreased Fetal Movement
                    </th>
                    <th rowSpan={2} className="text-center">
                      Upper Abdominal Pain
                    </th>
                    <th rowSpan={2} className="text-center">
                      Back Pain
                    </th>
                    <th rowSpan={2} className="text-center">
                      Jaundice
                    </th>
                    <th rowSpan={2} className="text-center">
                      Engorged Breast
                    </th>
                    <th rowSpan={2} className="text-center">
                      Cough
                    </th>
                    <th rowSpan={2} className="text-center">
                      Headache
                    </th>
                    <th rowSpan={2} className="text-center">
                      Blurring Of Vision
                    </th>
                    <th rowSpan={2} className="text-center">
                      Convulsions
                    </th>
                    <th rowSpan={2} className="text-center">
                      Shortness Of Breath
                    </th>
                    <th rowSpan={2} className="text-center">
                      Burning Urination
                    </th>
                    <th rowSpan={2} className="text-center">
                      Diarrhoea
                    </th>
                    <th rowSpan={2} className="text-center">
                      Constipation
                    </th>
                    <th rowSpan={2} className="text-center">
                      Water Break
                    </th>

                    <th className="text-center" colSpan={2}>
                      Lower Abdominal Pain
                    </th>
                    <th className="text-center" colSpan={3}>
                      Swelling
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center">Continuous</th>
                    <th className="text-center">Episodic</th>
                    <th className="text-center">Face</th>
                    <th className="text-center">Feet</th>
                    <th className="text-center">Whole Body</th>
                  </tr>
                </thead>
              )}
              {trimesterState === "FirstTrimester" ? (
                <tbody>
                  {dailySymptoms?.results?.map((symptom) => (
                    <tr key={symptom?._id}>
                      <td>
                        {symptom?.postedAt}{" "}
                        {moment(symptom?.createdAt).format("hh:mm A")}
                      </td>
                      <td>{symptom?.symptomsType}</td>
                      <td>{symptom?.noSymptoms ? "No" : "Yes"}</td>
                      <td>{symptom?.PVBleeding ? "Yes" : "No"}</td>
                      <td>{symptom?.PVDischarge ? "Yes" : "No"}</td>
                      <td>{symptom?.PVDischargeFoulSmelling ? "Yes" : "No"}</td>
                      <td>{symptom?.PVShow ? "Yes" : "No"}</td>
                      <td>{symptom?.PVSpotting ? "Yes" : "No"}</td>
                      <td>{symptom?.abdominalDistension}</td>
                      <td>{symptom?.abdominalPain}</td>
                      <td>{symptom?.decreasedAppetite}</td>
                      <td>{symptom?.dizziness}</td>
                      <td>{symptom?.fever ? "Yes" : "No"}</td>
                      <td>{symptom?.nausea}</td>
                      <td>{symptom?.vomiting}</td>
                      <td>{symptom?.selfHarm}</td>
                      <td>{symptom?.stress}</td>
                      <td>{symptom?.peace}</td>
                      <td>{symptom?.happiness}</td>
                      <td>{symptom?.sadness}</td>
                      <td>{symptom?.other}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {dailySymptoms?.results?.map((symptom) => (
                    <tr key={symptom?._id}>
                      <td>
                        {symptom?.postedAt}{" "}
                        {moment(symptom?.createdAt).format("hh:mm A")}
                      </td>
                      <td>{symptom?.symptomsType}</td>
                      <td>{symptom?.noSymptoms ? "No" : "Yes"}</td>
                      <td className="text-center">
                        <span>
                          {symptom?.decreasedFetalMovement ? "Yes" : "No"}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.upperAbdominalPain === 10
                              ? "emergency-badge"
                              : ""
                          }
                        >
                          {symptom?.upperAbdominalPain}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.backPain === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.backPain}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.jaundice === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.jaundice}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.engorgedBreast === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.engorgedBreast}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={symptom?.cough === 10 && "emergency-badge"}
                        >
                          {symptom?.cough}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.headache === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.headache}
                        </span>
                      </td>
                      <td className="text-center">
                        <span>{symptom?.blurringOfVision ? "Yes" : "No"}</span>
                      </td>
                      <td className="text-center">
                        <span>{symptom?.convulsions ? "Yes" : "No"}</span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.shortnessOfBreath === 10 &&
                            "emergency-badge"
                          }
                        >
                          {symptom?.shortnessOfBreath}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.burningUrination === 10 &&
                            "emergency-badge"
                          }
                        >
                          {symptom?.burningUrination}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.Diarrhea === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.Diarrhea ? "Yes" : "No"}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.constipation === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.constipation}
                        </span>
                      </td>
                      <td className="text-center">
                        <span>{symptom?.waterBreak ? "Yes" : "No"}</span>
                      </td>

                      <td className="text-center">
                        <span
                          className={
                            symptom?.lowerAbdominalPain?.continuous === 10 &&
                            "emergency-badge"
                          }
                        >
                          {symptom?.lowerAbdominalPain?.continuous}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.lowerAbdominalPain?.episodic === 10 &&
                            "emergency-badge"
                          }
                        >
                          {symptom?.lowerAbdominalPain?.episodic}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.swelling?.face === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.swelling?.face}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.swelling?.feet === 10 && "emergency-badge"
                          }
                        >
                          {symptom?.swelling?.feet}
                        </span>
                      </td>
                      <td className="text-center">
                        <span
                          className={
                            symptom?.swelling?.wholeBody === 10 &&
                            "emergency-badge"
                          }
                        >
                          {symptom?.swelling?.wholeBody}
                        </span>
                      </td>
                      <td>{symptom?.selfHarm}</td>
                      <td>{symptom?.stress}</td>
                      <td>{symptom?.peace}</td>
                      <td>{symptom?.happiness}</td>
                      <td>{symptom?.sadness}</td>
                      <td>{symptom?.other}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </StyledTable> */}
        </div>
      </Card>
      <PaginationComp
        page={page}
        total={dailySymptoms?.totalResults}
        setPage={handleSetPage}
      />
    </Fragment>
  );
};

export default DailySymptoms;

function Cell({ symptom, symptomStructure, trimesterState }) {
  const Component = ({ data, threshold }) => (
    <Col sm={6} as={Row} className="mb-3">
      <Col
        className={[isEmergency(data, threshold) ? "text-danger" : ""].join(
          " "
        )}
        style={{ fontWeight: "bold" }}
      >
        {symptomStructure.title}
      </Col>
      <Col
        className={[isEmergency(data, threshold) ? "text-danger" : ""].join(
          " "
        )}
      >
        {data}
      </Col>
    </Col>
  );
  const isEmergency = (d, th) => {
    if (Array.isArray(th)) {
      return th.includes(d);
    }
    if (typeof d === "boolean") {
      if (d === th) return true;
    } else if (typeof d === "string") {
      if (d === th) return true;
    } else if (d >= th) {
      return true;
    }
    return false;
  };
  if (symptomStructure.values) {
    return symptomStructure.values.map((sub, id) => {
      let symptomInfo = null;

      switch (symptomStructure.type) {
        case "bool":
          symptomInfo = symptom?.[sub.name] ? "छ" : "छैन";
          break;
        default:
          symptomInfo = symptom?.[sub.name];
          break;
      }
      return (
        <Component
          data={symptomInfo}
          threshold={threshold?.[trimesterState]?.[symptomStructure.name]}
        />
      );
    });
  } else {
    let symptomInfo = null;

    switch (symptomStructure.type) {
      case "bool":
        symptomInfo = symptom?.[symptomStructure.name] ? "छ" : "छैन";
        break;
      default:
        symptomInfo = symptom?.[symptomStructure.name];
        break;
    }
    return (
      <Component
        data={symptomInfo}
        threshold={threshold?.[trimesterState]?.[symptomStructure.name]}
      />
    );
  }
}
