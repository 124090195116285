import React, { Fragment, useState } from 'react';
import { Card, Table, Button, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import moment from 'moment';
import { FaUser, FaEye } from 'react-icons/fa';

const EmergencyAlert = () => {
	const { pageNum, id } = useParams();
	const history = useHistory();
	const [alerts, setAlerts] = useState(null);
	const [page, setPage] = useState(pageNum);
	const [showRemark, setShowRemark] = useState(false);
	const [remarks, setRemarks] = useState();
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	useEffect(() => {
		const getAlerts = async () => {
			try {
				const response = await fetchWrapper.get(
					`/alert?page=${page}&patient=${id}`
				);
				setAlerts(response);
			} catch (error) {
				console.log(error);
			}
		};
		getAlerts();
	}, [page, id]);
	async function getRemarks(id) {
		try {
			const response = await fetchWrapper.get(`/alert/${id}/remarks`);
			setRemarks(response);
			setShowRemark(true);
		} catch (error) {
			console.error(error);
		}
	}
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th rowSpan={2}>Date / Time</th>
								<th rowSpan={2}>Healthpost</th>
								<th rowSpan={2}>ANM</th>
								<th className="text-center" colSpan={2}>
									Reason
								</th>
								<th>Comments</th>
							</tr>
							<tr>
								<th>Cause</th>
								<th>Alerts</th>
							</tr>
						</thead>
						<tbody>
							{alerts?.results?.map((alert) => (
								<tr key={alert?._id}>
									<td>
										{alert?.postedAt} <br />
										{moment(alert?.createdAt).format('hh:mm A')}
									</td>
									<td>{alert?.healthpost?.name}</td>
									<td>{alert?.anm?.name}</td>
									<td>{alert?.reason?.cause}</td>
									<td>
										{JSON.stringify(alert?.reason?.alert || {})
											.replace(/[{}"]/g, ' ')
											.split(',')
											.map((t, i) => (
												<p key={i}>{t}</p>
											))}
									</td>
									<td>
										{alert?.remarks?.length > 0 && (
											<Button size="sm" onClick={() => getRemarks(alert?._id)}>
												<FaEye />
											</Button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={alerts?.totalResults}
			/>
			{showRemark && (
				<Modal show={showRemark} onHide={() => setShowRemark(false)}>
					<Modal.Header>
						<h2>Comments</h2>
					</Modal.Header>
					<Modal.Body>
						<div>
							{remarks && remarks?.remarks?.length > 0 ? (
								remarks?.remarks?.map((remark) => (
									<div className="p-2 border-bottom mb-2 shadow-sm">
										<h4 className="d-flex align-items-center">
											<FaUser className="mr-2" />
											<span>{remark?.postedBy?.profile?.name}</span>
										</h4>
										<p className="mb-2">
											<small>
												<i>{remark?.postedAt}</i>
											</small>
										</p>
										<p>{remark?.remark}</p>
									</div>
								))
							) : (
								<>No Comments</>
							)}
						</div>
					</Modal.Body>
				</Modal>
			)}
		</Fragment>
	);
};

export default EmergencyAlert;
