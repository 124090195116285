const rules = {
	admin: {
		static: [
			'view:settings',
			'view:palika',
			'view:healthpost',
			'view:anm',
			'view:doctor',
			'view:help',
			'view:fchv',
			'view:patient',
			'view:materials',
			'view:reports',
			'view:support',
			'view:faq',
		],
	},
	healthpostUser: {
		static: [
			'view:settings',
			'view:anm',
			'view:fchv',
			'view:patient',
			'view:materials',
			'view:reports',
			'view:support',
			'view:faq',
		],
	},
	palikaUser: {
		static: [
			'view:settings',
			'view:healthpost',
			'view:anm',
			'view:fchv',
			'view:patient',
			'view:materials',
			'view:reports',
			'view:support',
			'view:faq',
		],
	},
};

export default rules;
