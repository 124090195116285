import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';

export const initialState = {
	loading: false,
	allPalikas: [],
	districts: [],
	palika: [],
	errors: null,
};

// A slice

const palikaSlice = createSlice({
	name: 'palika',
	initialState,
	reducers: {
		loadingPalika: (state, action) => {
			state.loading = true;
		},
		stopLoadingPalika: (state, action) => {
			state.loading = false;
		},
		setAllPalika: (state, action) => {
			state.allPalikas = action.payload;
		},
		setDistricts: (state, action) => {
			state.districts = action.payload;
		},
		setPalika: (state, action) => {
			state.palika = action.payload;
		},
	},
});

const { loadingPalika, stopLoadingPalika, setAllPalika, setDistricts, setPalika } = palikaSlice.actions;

export const palikaSelector = (state) => state.palika;

const palikaReducer = palikaSlice.reducer;

export default palikaReducer;

//Actions
export const getPalikas = () => async (dispatch) => {
	dispatch(loadingPalika());
	try {
		let response = await fetchWrapper.get('palika/all');
		const districts = await response?.map((res) => ({ pid: res?.pid, districts: res?.districts }));
		const dist = await districts?.map((dis) => dis.districts).flat();
		const palika = await dist?.map((dis) => ({
			did: dis.did,
			palika: dis.local_levels,
		}));
		await dispatch(setAllPalika(response));
		await dispatch(setDistricts(districts));
		await dispatch(setPalika(palika));
	} catch (error) {
		console.log(error);
	}
	dispatch(stopLoadingPalika());
};
