/** @jsxRuntime classic */
/** @jsx jsx */
import {
  jsx,
  // css
} from "@emotion/react";

import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
import FormError from "../../../components/Form/FormError";
import EditorComp from "../../../components/Form/EditorComp";
import { useHistory, useParams } from "react-router-dom";
import {
  addMaterials,
  editMaterial,
  getSingleMaterial,
} from "../../../_redux/slices/materials";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";

const AddMaterials = () => {
  const history = useHistory();
  const { type, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const material = useSelector((state) => state.materials.material);
  const [file, setFile] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (id) {
      dispatch(getSingleMaterial(id));
    }
  }, [dispatch, id]);

  const onSubmit = async (data) => {
    try {
      let body = { type, ...data };
      if (type === "audio" && file) {
        const formData = new FormData();
        formData.append("file", file);
        let res = await fetchWrapper.post("/file", formData);
        body.content = await res?.data;
      }
      if (id) {
        dispatch(editMaterial(id, body));
      } else {
        dispatch(addMaterials(body));
      }
      reset();
      history.push("/settings/materials/1");
    } catch (error) {}
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>{id ? "सामग्री सम्पादन गर्नुहोस्" : " नयाँ सामग्री"}</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                maxLength={"200"}
                {...register("title")}
                placeholder="सामग्रीको नाम लेख्नुहोस्"
                {...(id && { defaultValue: material?.title })}
              />
              <FormError error={errors && errors?.name} />
            </Form.Group>
          </Row>
          {type === "video" && (
            <>
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Description</Form.Label>
                  <EditorComp
                    id="description"
                    {...(id && { defaultValue: material?.description })}
                    setValue={setValue}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Enter Video URL</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("content")}
                    {...(id && { defaultValue: material?.content })}
                  />
                </Form.Group>
              </Row>
            </>
          )}
          {type === "article" && (
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Write Article</Form.Label>
                <EditorComp
                  id="content"
                  setValue={setValue}
                  {...(id && { defaultValue: material?.content })}
                />
              </Form.Group>
            </Row>
          )}

          {type === "audio" && (
            <Row>
              <Form.Group as={Col} md={4}>
                <Form.Label>Choose Audio File</Form.Label>
                <Form.Control
                  type="file"
                  accept="audio/*"
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setFile(e.target.files[0]);
                    }
                  }}
                  className="mb-3"
                />
                <p className="mb-3">OR</p>
                <Form.Label>Enter Audio URL</Form.Label>
                <Form.Control
                  type="text"
                  {...register("content")}
                  {...(id && { defaultValue: material?.content })}
                />
              </Form.Group>
            </Row>
          )}
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>Order</Form.Label>
              <Form.Control type="number" {...register("order")} />
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button variant="secondary" className="mr-2">
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disabled={isLoading}>
            पेश गर्नुहोस्
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AddMaterials;
