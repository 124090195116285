// import { toast } from 'react-toastify';
// import { apiKey } from '../constant';
import { axiosInstance } from './axiosInstance';
import { toast } from 'react-toastify';

async function get(url) {
	try {
		let response = await axiosInstance.get(url);
		if (!response.error) {
			return response;
		} else {
			throw response;
		}
	} catch (err) {
		console.log(err, 'ERROR');
		toast.error(err.message);
		// return err;
	}
}

async function post(url, body, contentType = 'application/json') {
	try {
		const response = await axiosInstance.post(url, body, { headers: { contentType } });
		if (!response.error) {
			return response;
		} else {
			throw response;
		}
	} catch (error) {
		console.log(error, '---ERROR---');
		toast.error(error.message);
		// return error;
	}
}

async function put(url, body, contentType = 'application/json') {
	try {
		const response = await axiosInstance.put(url, body, { headers: { contentType } });
		if (!response.error) {
			return response;
		} else {
			throw response;
		}
	} catch (error) {
		console.log(error, 'ERROR');
		toast.error(error.message);
		// return error;
	}
}

async function patch(url, body, contentType = 'application/json') {
	try {
		const response = await axiosInstance.patch(url, body, { headers: { contentType } });
		if (!response.error) {
			return response;
		} else {
			throw response;
		}
	} catch (error) {
		console.log(error, 'ERROR');
		toast.error(error.message);
		// return error;
	}
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
	try {
		const response = await axiosInstance.delete(url);
		if (!response.error) {
			return response;
		} else {
			throw response;
		}
	} catch (error) {
		console.log(error, 'ERROR');
		toast.error(error.message);
		// return error;
	}
}

export const fetchWrapper = {
	get,
	post,
	put,
	patch,
	delete: _delete,
};
