/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FaEye, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackButton from "../../../_elements/BackButton";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { ageCalculator } from "../../../_helpers/methods";
import DeleteModal from "../../../components/DeleteModal";
import PaginationComp from "../../../components/Pagination";
// import { VscLoading } from 'react-icons/vsc';

const Patient = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [patients, setPatients] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const user = useSelector((state) => state.users.user);
  const [filter, setFilter] = useState({
    name: null,
  });

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  useEffect(() => {
    const getPatients = async () => {
      let response = await fetchWrapper.get(
        `patient?page=${pageNum}${filter?.name ? `&name=${filter?.name}` : ""}`
      );
      setPatients(response);
    };
    try {
      getPatients();
    } catch (error) {}
  }, [pageNum, filter]);

  const onDelete = async (id) => {
    try {
      await fetchWrapper.delete(`patient/${id}`);
      let tempPatients = patients.results.filter((p) => p?._id !== id);
      setPatients((prev) => ({
        ...prev,
        totalResults: prev.totalResults - 1,
        results: tempPatients,
      }));
    } catch (error) {}
  };

  return (
    <Fragment>
      <BackButton />

      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom d-flex justify-content-between">
              <h2>गर्भवती/सुत्केरी महिला</h2>

              <div
                className="card-header-form"
                css={css`
                  min-width: 260px;
                `}
              >
                <div className="input-group">
                  <Form.Control
                    type="text"
                    className="custom-search"
                    placeholder="Search Patient Name"
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />

                  <div className="input-group-btn">
                    <button className="btn btn-primary input-search-icon">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>Contact No.</th>
                      <th>Age</th>
                      <th>Healthpost</th>
                      <th colSpan={2}>
                        <p>Responsible Person</p>
                        <p>Contact</p>
                      </th>
                      <th colSpan={2} className="text-left">
                        कार्य
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients?.results?.map((patient) => (
                      <tr>
                        <td>{patient?.name}</td>
                        <td>{patient?.user?.phone}</td>
                        <td>
                          {ageCalculator(user?.today, patient?.dateOfBirth)}
                        </td>
                        <td>{patient?.healthpost?.name}</td>
                        <td colSpan={2}>
                          <p>{patient?.responsiblePersonName}</p>
                          <p>{patient?.responsiblePersonNumber}</p>
                        </td>
                        <td colSpan={2}>
                          <Button
                            variant="primary"
                            onClick={() =>
                              history.push(`/profile/${patient?._id}`)
                            }
                          >
                            <FaEye />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => setShowDelete(patient?._id)}
                            className="ml-2"
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={page}
        total={patients?.totalResults}
        setPage={handleSetPage}
      />
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        onDelete={() => onDelete(showDelete)}
      />
    </Fragment>
  );
};

export default Patient;
