import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ title, show, onHide, onDelete, message }) => {
	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header>
				<Modal.Title className="text-primary">{title || 'Delete'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5 className="text-center">{message || 'Are you sure?'}</h5>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Cancel
				</Button>
				<Button
					variant="danger"
					onClick={() => {
						onDelete();
						onHide();
					}}
				>
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteModal;
