const commonTrimesterData = [
  {
    title: "वाक्-वाक् लागेको छ?",
    name: "nausea",
    id: 1,
    t1order: 1,
    t2order: 1,
    t3order: 1,
  },
  {
    title: "बान्ता भएको छ?",
    name: "vomiting",
    id: 2,
    t1order: 2,
    t2order: 2,
    t3order: 2,
  },
  {
    title: "खाना खान मन लागेको छैन?",
    name: "decreasedAppetite",
    id: 5,
    t1order: 3,
    t2order: 3,
    t3order: 3,
  },
  {
    title: "पेट फुलेको जस्तो भएको छ?",
    name: "abdominalDistension",
    id: "abdominalDistension",
    t1order: 4,
    t2order: 4,
    t3order: 4,
  },

  {
    title: "माथिल्लो पेट दुखेको छ?",
    name: "upperAbdominalPain",
    id: "upperAbdominalPain",
    t1order: 5,
    t2order: 5,
    t3order: 5,
  },
  {
    title: "कब्जियत भएको/दिसा गोटा परेको छ?",
    name: "constipation",
    id: "constipation",
    t1order: 6,
    t2order: 6,
    t3order: 6,
  },
  {
    title: "पखला लागेको छ?",
    name: "Diarrhea",
    id: "diarrhea",
    type: "bool",
    t1order: 7,
    t2order: 7,
    t3order: 7,
  },
  {
    title: "ज्वरो आएको छ?",
    name: "fever",
    id: "fever",
    type: "bool",
    t1order: 8,
    t2order: 9,
    t3order: 9,
  },
  {
    title: "टाउको दुखेको छ?",
    name: "headache",
    id: 7,
    t1order: 9,
    t2order: 10,
    t3order: 10,
  },
  {
    title: "ढाड दुखेको छ?",
    name: "backPain",
    id: "backpain",
    t1order: 10,
    t2order: 11,
    t3order: 11,
  },
  {
    title: "चक्कर लागेको छ?",
    name: "dizziness",
    id: "dizziness",
    t1order: 11,
    t2order: 12,
    t3order: 12,
  },
  {
    title: "खोकी लागेको छ?",
    name: "cough",
    id: "cough",
    t1order: 12,
    t2order: 13,
    t3order: 13,
  },
  {
    title: "स्वाँ-स्वाँ भएको छ?",
    name: "shortnessOfBreath",
    id: "shortnessOfBreath",
    t1order: 13,
    t2order: 14,
    t3order: 14,
  },
  {
    title: "पिसाब पोलेको छ?",
    name: "burningUrination",
    id: 6,
    t1order: 14,
    t2order: 16,
    t3order: 16,
  },
  {
    title: "पिसाब कम भएको छ?",
    name: "decreasedUrineOutput",
    id: "decreasedUrineOutput",
    type: "bool",
    t1order: 15,
    t2order: 17,
    t3order: 17,
  },
  {
    title: "तल्लो पेट दुखेको छ?",
    name: "lowerAbdominalPain",
    t1order: 16,
    t2order: 22,
    t3order: 22,
    values: [
      {
        title: "लगातार दुखेको छ?",
        name: "continuous",
        id: "ContinousLowerAbdominalPain",
      },
      {
        title: "दुख्ने-छोड्ने, दुख्ने-छोड्ने भएको छ?",
        name: "episodic",
        id: "EpisodicLowerAbdominalPain",
      },
    ],
    id: "lowerAbdominalPain",
  },
  {
    title: "योनिबाट अलि-अलि रगतको दाग देखिएको छ?",
    name: "PVSpotting",
    id: 37,
    type: "bool",
    t1order: 17,
    t2order: 24,
    t3order: 24,
  },
  {
    title: "योनिबाट रगत बगेको छ?",
    name: "PVBleeding",
    id: 36,
    type: "bool",
    t1order: 18,
    t2order: 25,
    t3order: 25,
  },
  {
    title: "योनिबाट सेतो पानी बगेको छ?",
    name: "PVDischarge",
    id: 39,
    type: "bool",
    t1order: 19,
    t2order: 26,
    t3order: 26,
  },
  {
    title: "योनिबाट गन्हाउने पानी बगेको छ?",
    name: "PVDischargeFoulSmelling",
    id: 40,
    type: "bool",
    t1order: 20,
    t2order: 27,
    t3order: 27,
  },
  {
    title: "योनिबाट रगत मिसिएको सेतो पदार्थ बगेको छ?",
    name: "PVShow",
    id: 41,
    type: "bool",
    t1order: 21,
    t2order: 28,
    t3order: 28,
  },
  {
    title: "तपाइलाई निन्द्रा नलाग्ने समस्या कत्तिको छ जनाउनुहोस?",
    name: "sleep",
    id: 8,
    t1order: 21.5,
    t2order: 30,
    t3order: 31,
  },
  {
    title: "सधैं छटपटी/अशान्त जस्तो अनुभव गर्नुहुन्छ?",
    name: "peace",
    options: [
      { key: "हुँदै होइन", value: "हुँदै होइन" },
      { key: "त्यति बढ़ी होईन", value: "त्यति बढ़ी होईन" },
      { key: "अलि बढ़ी", value: "अलि बढ़ी" },
      { key: "वास्तवमा धेरै नै", value: "वास्तवमा धेरै नै" },
    ],
    id: 33,
    type: "bool",
    t1order: 22,
    t2order: 31,
    t3order: 32,
  },
  {
    title: "चिन्ताजनक विचारहरू दिमागमा आउँछन्?",
    name: "stress",
    options: [
      { key: "बिरलै", value: "बिरलै" },
      {
        key: "कहिले-कहिले तर त्यति धेरै आउँदैन",
        value: "कहिले-कहिले तर त्यति धेरै आउँदैन",
      },
      { key: "धेरै-जसो", value: "धेरै-जसो" },
      { key: "अत्यधिक मात्रामा", value: "अत्यधिक मात्रामा" },
    ],
    id: 32,
    type: "bool",
    t1order: 23,
    t2order: 32,
    t3order: 33,
  },
  {
    title: "दिक्क लाग्ने, निराश हुने वा केही गर्न सक्दिन जस्तो लाग्छ?",
    name: "sadness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 35,
    type: "bool",
    t1order: 24,
    t2order: 33,
    t3order: 34,
  },
  {
    title:
      "अन्य व्यक्तिहरू जति रमाउँछन्, त्यत्तिको रमाउन नसक्ने/खुशी हुन नसक्ने वा काम गर्न मन नलाग्ने हुन्छ?",
    name: "happiness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 34,
    type: "bool",
    t1order: 25,
    t2order: 34,
    t3order: 35,
  },
  {
    title: "तपाईँलाई आफूले आफूलाई हानि गरूँ भन्ने जस्तो विचार आएको छ?",
    name: "selfHarm",
    id: 31,
    type: "bool",
    options: [
      { key: "छ", value: "छ" },
      { key: "छैन", value: "छैन" },
    ],
    t1order: 26,
    t2order: 35,
    t3order: 36,
  },
];

const trimesterOneSymptoms = [...commonTrimesterData];

const trimesterTwoSymptoms = [
  {
    title: "आँखा/छाला पहेंलो भएको छ?",
    name: "jaundice",
    id: 3,
    t2order: 8,
    t3order: 8,
  },
  {
    title: "मुटुको ढुकढुकी बढेको छ?",
    name: "palpitation",
    id: "palpitation",
    t2order: 15,
    t3order: 15,
  },
  {
    title: "आँखा हेर्दा धमिलो भएको छ?",
    name: "blurringOfVision",
    id: 14,
    type: "bool",
    t2order: 18,
    t3order: 18,
  },
  {
    title: "सुन्निएको?",
    name: "swelling",
    values: [
      {
        title: "मुख सुन्निएको छ?",
        name: "face",
        id: 11.1,
      },
      {
        title: "खुट्टा सुन्निएको छ?",
        name: "feet",
        id: 11.2,
      },
      {
        title: "पूरै शरीर सुन्निएको छ?",
        name: "wholeBody",
        id: 11.3,
      },
    ],
    id: 11,
    t2order: 19,
    t3order: 19,
  },
  {
    title: "कम्पन हुने वा बेहोस हुने भएको छ?",
    name: "convulsions",
    id: 15,
    type: "bool",
    t2order: 20,
    t3order: 20,
  },

  {
    title: "स्तन गान्निएको / सुन्निएको छ?",
    name: "engorgedBreast",
    id: 9,
    t2order: 21,
    t3order: 21,
  },
  {
    title: "पेटको बच्चा चल्न कम भएको छ?",
    name: "decreasedFetalMovement",
    id: 13,
    type: "bool",
    t2order: 23,
    t3order: 23,
  },
  {
    title: "एक्कासी ह्वाल्ल पानी फूटेको वा रोकी-रोकी पानी बगीरहेको छ?",
    name: "waterBreak",
    id: 17,
    type: "bool",
    t2order: 29,
    t3order: 29,
  },

  ...commonTrimesterData,
];

const trimesterThreeSymptoms = [
  ...trimesterTwoSymptoms,
  {
    title: "तपाईलाई व्यथा लाग्न शुरु भयो कि जस्तो लागेको छ ?",
    name: "labor",
    id: 19,
    type: "bool",
    options: [
      { key: "छ", value: "छ" },
      { key: "छैन", value: "छैन" },
      { key: "हुनसक्छ", value: "हुनसक्छ" },
    ],
    t3order: 30,
  },
];

const postNatalMother = [
  {
    title: "वाक्-वाक् लागेको छ?",
    name: "nausea",
    id: 15,
    order: 1,
  },
  {
    title: "बान्ता भएको छ?",
    name: "vomiting",
    id: 16,
    order: 2,
  },
  {
    title: "पेट फुलेको जस्तो भएको छ?",
    name: "abdominalDistension",
    id: 18,
    order: 3,
  },
  {
    title: "पेट दुखेको छ?",
    name: "abdominalPain",
    id: 17,
    order: 4,
  },
  {
    title: "कब्जियत भएको/दिसा गोटा परेको छ?",
    name: "constipation",
    id: 23,
    order: 5,
  },
  {
    title: "पखला लागेको छ?",
    name: "Diarrhea",
    id: 12,
    type: "bool",
    order: 6,
  },
  {
    title: "योनिबाट रगत बगेको छ?",
    name: "PVBleeding",
    id: 7,
    type: "bool",
    order: 7,
  },
  {
    title: "योनिबाट सेतो पानी बगेको छ?",
    name: "PVDischarge",
    id: 8,
    type: "bool",
    order: 8,
  },
  {
    title: "योनिबाट गन्हाउने पानी बगेको छ?",
    name: "PVDischargeFoulSmelling",
    id: 9,
    type: "bool",
    order: 9,
  },
  {
    title: "आँखा/छाला पहेंलो भएको छ?",
    name: "jaundice",
    id: 19,
    order: 10,
  },
  {
    title: "ज्वरो आएको छ?",
    name: "fever",
    id: "fever",
    type: "bool",
    order: 11,
  },
  {
    title: "टाउको दुखेको छ?",
    name: "headache",
    id: 20,
    order: 12,
  },
  {
    title: "सुन्निएको?",
    name: "swelling",
    values: [
      {
        title: "मुख सुन्निएको छ?",
        name: "face",
        id: 11.1,
      },
      {
        title: "खुट्टा सुन्निएको छ?",
        name: "feet",
        id: 11.2,
      },
      {
        title: "पूरै शरीर सुन्निएको छ?",
        name: "wholeBody",
        id: 11.3,
      },
    ],
    id: 26,
    order: 13,
  },
  {
    title: "आँखा हेर्दा धमिलो भएको छ?",
    name: "blurringOfVision",
    id: 10,
    type: "bool",
    order: 14,
  },
  {
    title: "कम्पन हुने वा बेहोस हुने भएको छ?",
    name: "convulsions",
    id: 11,
    type: "bool",
    order: 15,
  },
  {
    title: "खोकी लागेको छ?",
    name: "cough",
    id: 24,
    order: 16,
  },
  {
    title: "स्वाँ-स्वाँ भएको छ?",
    name: "shortnessOfBreath",
    id: 21,
    order: 17,
  },
  {
    title: "पिसाब पोलेको छ?",
    name: "burningUrination",
    id: 22,
    order: 18,
  },
  {
    title: "पिसाब कम भएको छ?",
    name: "decreasedUrineOutput",
    id: 13,
    type: "bool",
    order: 19,
  },
  {
    title: "स्तन गान्निएको / सुन्निएको छ?",
    name: "engorgedBreast",
    id: 25,
    order: 20,
  },
  {
    title: "स्तनपान गराइराखेको छु?",
    name: "breastFeeding",
    id: 14,
    type: "bool",
    order: 21,
  },
  {
    title: "तपाइलाई निन्द्रा नलाग्ने समस्या कत्तिको छ जनाउनुहोस?",
    name: "sleep",
    id: 27,
    order: 22,
  },
  {
    title: "तपाईँलाई खानाको रुची कस्तो छ?",
    name: "appetite",
    options: [
      { key: "एकदम ठिक छ", value: "एकदम ठिक छ" },
      { key: "ठिक ठिकै छ", value: "ठिक ठिकै छ" },
      { key: "अलि कम छ", value: "अलि कम छ" },
      { key: "कत्ति पनि रुची छैन", value: "कत्ति पनि रुची छैन" },
    ],
    id: 4,
    type: "bool",
    order: 23,
  },
  {
    title: "तपाईँलाई बच्चाको आवश्यक हेरविचार गर्नसक्दिन कि जस्तो लाग्दछ ?",
    name: "canTakeCareOfBaby",
    id: 2,
    type: "bool",
    options: [
      { key: "हो", value: "हो" },
      { key: "होइन", value: "होइन" },
    ],
    order: 24,
  },
  {
    title:
      "तपाईँलाई बच्चाको स्वास्थ्यलाई लिएर चिन्ताजनक विचारहरु कत्तिको आउँछन् ?",
    name: "stressAboutBaby",
    options: [
      { key: "बिरलै", value: "बिरलै" },
      {
        key: "कहिले-कहिले तर त्यति धेरै आउँदैन",
        value: "कहिले-कहिले तर त्यति धेरै आउँदैन",
      },
      { key: "धेरै-जसो", value: "धेरै-जसो" },
      { key: "अत्यधिक मात्रामा", value: "अत्यधिक मात्रामा" },
    ],
    id: 3,
    type: "bool",
    order: 25,
  },
  {
    title: "तपाईँको मनमा आफूलाई वा बच्चालाई हानि पुर्‍याउने बिचारहरु आएको छ ?",
    name: "selfOrBabyHarm",
    id: 1,
    type: "bool",
    options: [
      { key: "छ", value: "छ" },
      { key: "छैन", value: "छैन" },
    ],
    order: 26,
  },
  {
    title:
      "अन्य व्यक्तिहरू जति रमाउँछन्, त्यत्तिको रमाउन नसक्ने/खुशी हुन नसक्ने वा काम गर्न मन नलाग्ने हुन्छ?",
    name: "happiness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 5,
    type: "bool",
    order: 27,
  },
  {
    title: "दिक्क लाग्ने, निराश हुने वा केही गर्न सक्दिन जस्तो लाग्छ?",
    name: "sadness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 6,
    type: "bool",
    order: 28,
  },
];

const postNatalBaby = [
  { name: "isFine", title: "बच्चा ठिक छ?", id: 1, type: "bool", order: 1 },
  {
    name: "isSuckingWell",
    title: " दुध राम्ररी चुसेको छ?",
    id: 2,
    type: "bool",
    order: 2,
  },
  {
    name: "isFeedingWell",
    title: "राम्ररी खाईराखेको छ?",
    id: 3,
    type: "bool",
    order: 3,
  },
  {
    name: "ExcessiveCrying",
    title: "अत्यधिक रोएको छ?",
    id: 4,
    type: "bool",
    order: 4,
  },
  {
    name: "Lethargic",
    title: "सुस्त देखिएको छ?",
    id: 5,
    type: "bool",
    order: 5,
  },
  {
    name: "Difficultbreathing",
    title: "घ्यारघ्यार गरी सास फेरेको छ?",
    id: 6,
    type: "bool",
    order: 6,
  },
  {
    name: "IncreasedBreathing",
    title: "छिटोछिटो सास फेरेको छ?",
    id: 7,
    type: "bool",
    order: 7,
  },
  {
    name: "NoBreathing",
    title: "सास फेरेको छैन?",
    id: 8,
    type: "bool",
    order: 8,
  },
  { name: "Fever", title: "ज्वरो आएको छ?", id: 9, type: "bool", order: 9 },
  {
    name: "YellowishDiscoloration",
    title: "पहेलो देखिएको छ?",
    id: 10,
    type: "bool",
    order: 10,
  },
  {
    name: "DistendedAbdomen",
    title: "पेट फुलेको जस्तो भएको छ?",
    id: 11,
    type: "bool",
    order: 11,
  },
  {
    name: "Vomiting",
    title: "बान्ता भएको छ?",
    id: 12,
    type: "bool",
    order: 12,
  },
  {
    name: "AbnormalStools",
    title: "दिसा नराम्रो छ?",
    id: 13,
    type: "bool",
    order: 13,
  },
  {
    name: "CryingDuringMicturation",
    title: "पिसाब फेर्दा रुने गरेको छ?",
    id: 14,
    order: 14,
  },
  {
    name: "Hypothermia",
    title: "बच्चाको शरीर चिसो भएको छ?",
    id: 15,
    order: 15,
  },
  {
    name: "AbnormalBodyMovements",
    title: "बच्चा कामेको छ / मुर्छा परेको छ?",
    id: 16,
    order: 16,
  },
  { name: "Cyanosis", title: "निलो भएको छ?", id: 17, order: 17 },
  { name: "SkinRash", title: "छालामा दाग/डाबर आएको छ?", id: 18, order: 18 },
];

const terminationSymptoms = [
  {
    title: "तल्लो पेट दुखेको छ?",
    name: "lowerAbdominalPain",
    values: [
      {
        title: "लगातार दुखेको छ?",
        name: "continuous",
        id: "ContinousLowerAbdominalPain",
      },
      {
        title: "दुख्ने-छोड्ने, दुख्ने-छोड्ने भएको छ?",
        name: "episodic",
        id: "EpisodicLowerAbdominalPain",
      },
    ],
    id: "lowerAbdominalPain",
    order: 1,
  },
  {
    title: "योनिबाट रगत बगेको छ?",
    name: "PVBleeding",
    id: 36,
    type: "bool",
    order: 2,
  },
  {
    title: "ढाड दुखेको छ?",
    name: "backPain",
    id: "backpain",
    order: 3,
  },
  {
    title: "स्तन गान्निएको / सुन्निएको छ?",
    name: "engorgedBreast",
    id: 9,
    order: 4,
  },
  {
    title: "पिसाब पोलेको छ?",
    name: "burningUrination",
    id: 6,
    order: 5,
  },
  {
    title: "पिसाब कम भएको छ?",
    name: "decreasedUrineOutput",
    id: "decreasedUrineOutput",
    type: "bool",
    order: 6,
  },
  {
    title: "योनिबाट गन्हाउने पानी बगेको छ?",
    name: "PVDischargeFoulSmelling",
    id: 40,
    type: "bool",
    order: 7,
  },
  {
    title: "ज्वरो आएको छ?",
    name: "fever",
    id: "fever",
    type: "bool",
    order: 8,
  },
  {
    title: "चक्कर लागेको छ?",
    name: "dizziness",
    id: "dizziness",
    order: 9,
  },
  {
    title: "तपाइलाई निन्द्रा नलाग्ने समस्या कत्तिको छ जनाउनुहोस?",
    name: "sleep",
    id: 8,
    order: 10,
  },
  {
    title: "सधैं छटपटी/अशान्त जस्तो अनुभव गर्नुहुन्छ?",
    name: "peace",
    options: [
      { key: "हुँदै होइन", value: "हुँदै होइन" },
      { key: "त्यति बढ़ी होईन", value: "त्यति बढ़ी होईन" },
      { key: "अलि बढ़ी", value: "अलि बढ़ी" },
      { key: "वास्तवमा धेरै नै", value: "वास्तवमा धेरै नै" },
    ],
    id: 33,
    type: "bool",
    order: 11,
  },
  {
    title: "चिन्ताजनक विचारहरू दिमागमा आउँछन्?",
    name: "stress",
    options: [
      { key: "बिरलै", value: "बिरलै" },
      {
        key: "कहिले-कहिले तर त्यति धेरै आउँदैन",
        value: "कहिले-कहिले तर त्यति धेरै आउँदैन",
      },
      { key: "धेरै-जसो", value: "धेरै-जसो" },
      { key: "अत्यधिक मात्रामा", value: "अत्यधिक मात्रामा" },
    ],
    id: 32,
    type: "bool",
    order: 12,
  },
  {
    title: "दिक्क लाग्ने, निराश हुने वा केही गर्न सक्दिन जस्तो लाग्छ?",
    name: "sadness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 35,
    type: "bool",
    order: 13,
  },
  {
    title:
      "अन्य व्यक्तिहरू जति रमाउँछन्, त्यत्तिको रमाउन नसक्ने/खुशी हुन नसक्ने वा काम गर्न मन नलाग्ने हुन्छ?",
    name: "happiness",
    options: [
      { key: "कत्ति पनि हुँदैन", value: "कत्ति पनि हुँदैन" },
      { key: "कहिले-कहिले हुन्छ", value: "कहिले-कहिले हुन्छ" },
      { key: "धेरै-जसो हुन्छ", value: "धेरै-जसो हुन्छ" },
      { key: "सधैंजसो हुन्छ", value: "सधैंजसो हुन्छ" },
    ],
    id: 34,
    type: "bool",
    order: 14,
  },
  {
    title: "तपाईँलाई आफूले आफूलाई हानि गरूँ भन्ने जस्तो विचार आएको छ?",
    name: "selfHarm",
    id: 31,
    type: "bool",
    options: [
      { key: "छ", value: "छ" },
      { key: "छैन", value: "छैन" },
    ],
    order: 15,
  },
];

const symptoms = {
  FirstTrimester: trimesterOneSymptoms,
  SecondTrimester: trimesterTwoSymptoms,
  ThirdTrimester: trimesterThreeSymptoms,
  postNatalMother,
  postNatalBaby,
  termination: terminationSymptoms,
};

export default symptoms;
