/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackButton from "../../_elements/BackButton";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import {
  getPatientLogHeatmap,
  getPatientLogs,
} from "../../_redux/slices/report";
import DeleteModal from "../../components/DeleteModal";
import NepaliDatePicker from "../../components/InputField/DatePicker";
import { ADToBS } from "../../components/InputField/DatePicker/AdToBsConverter/AdBsConverter";
import PaginationComp from "../../components/Pagination";

const Report = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [patients, setPatients] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const { loading, patientLogs, totalResults } = useSelector(
    (state) => state.reports
  );
  const [filter, setFilter] = useState({
    from: ADToBS(
      moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format(
        "YYYY-MM-DD"
      )
    ),
    to: ADToBS(moment(new Date(Date.now())).format("YYYY-MM-DD")),
    name: null,
    page: pageNum,
  });

  const handleSetPage = (page) => {
    setFilter((prev) => ({ ...prev, page: page }));
    history.push(`${page}`);
  };

  const getPatients = useCallback(async () => {
    const query = Object.entries(filter).reduce((a, c, i) => {
      if (c[1]) {
        return (
          a +
          "&" +
          c[0] +
          "=" +
          (["from", "to"].includes(c[0]) ? c[1].split("/").join("-") : c[1])
        );
      }
      return a;
    }, "?");
    dispatch(getPatientLogs(query));
  }, [dispatch, filter]);

  useEffect(() => {
    try {
      getPatients();
    } catch (error) {}
  }, [getPatients]);

  const onDelete = async (id) => {
    try {
      await fetchWrapper.delete(`patient/${id}`);
      let tempPatients = patients.results.filter((p) => p?._id !== id);
      setPatients((prev) => ({
        ...prev,
        totalResults: prev.totalResults - 1,
        results: tempPatients,
      }));
    } catch (error) {}
  };

  function handleDateFilterChange(type) {
    return (...value) => {
      setFilter((prev) => ({ ...prev, [type]: value[2] }));
    };
  }

  return (
    <React.Fragment>
      <BackButton />
      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom d-flex justify-content-between">
              <h2>गर्भवती/सुत्केरी महिला</h2>
              <div
                className="card-header-form"
                css={css`
                  min-width: 260px;
                  display: flex;
                  column-gap: 0.5rem;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    column-gap: 0.5rem;
                    align-items: center;
                  `}
                >
                  <label htmlFor="from">From:</label>
                  <NepaliDatePicker
                    id={"from"}
                    onChange={handleDateFilterChange("from")}
                    value={filter.from}
                    placeholder={"दिनांक छान्नुहोस्"}
                  />
                  <label htmlFor="to">To:</label>
                  <NepaliDatePicker
                    id={"to"}
                    onChange={handleDateFilterChange("to")}
                    value={filter.to}
                    placeholder={"दिनांक छान्नुहोस्"}
                  />
                </div>
                <div className="input-group">
                  <Form.Control
                    type="text"
                    className="custom-search"
                    placeholder="Search Patient Name"
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                  <div className="input-group-btn">
                    <button className="btn btn-primary input-search-icon">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <p>Patient Name</p>
                        <p>Contact</p>
                      </th>
                      <th>
                        <p>Healthpost</p>
                        <p>ANM</p>
                      </th>
                      <th>
                        <p>Responsible Person</p>
                        <p>Contact</p>
                      </th>
                      <th>Logs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      patientLogs?.map((patient) => (
                        <tr>
                          <td>
                            <p>{patient?.name}</p>
                            <p>{patient?.user?.phone}</p>
                          </td>
                          <td>
                            <p>{patient?.healthpost?.name}</p>
                            <p>{patient?.anm?.name}</p>
                          </td>
                          <td>
                            <p>{patient?.responsiblePersonName}</p>
                            <p>{patient?.responsiblePersonNumber}</p>
                          </td>
                          <td>
                            <LogCount patient={patient} filter={filter} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={filter.page}
        total={totalResults}
        setPage={handleSetPage}
        perPage={20}
      />
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        onDelete={() => onDelete(showDelete)}
      />
    </React.Fragment>
  );
};

function LogCount({ patient, filter }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let count = useMemo(
    () =>
      (patient?.symptoms?.length || 0) +
      (patient?.postnatals?.length || 0) +
      (patient?.terminations?.length || 0),
    [patient]
  );
  return (
    <>
      {count}&nbsp;&nbsp;
      <Button
        // to={`/report/heatmap/${patient?._id}`}
        onClick={() => {
          dispatch(getPatientLogHeatmap({ filter, patient }));
          history.push(`/report/heatmap/${patient?._id}`);
        }}
        variant="link"
      >
        (view heatmap)
      </Button>
    </>
  );
}

export default Report;
