/** @jsxRuntime classic */
/** @jsx jsx */
import {
  jsx,
  // css
} from "@emotion/react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Password from "../../../components/Form/Password";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DoctorValidation } from "../../../_helpers/schema-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../../../components/Form/FormError";

const AddDoctor = ({ showAdd, setShowAdd, id, onAdd, setDoctors }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editData, setEditData] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(DoctorValidation),
  });

  useEffect(() => {
    if (editData) {
      setValue("name", editData?.name);
      setValue("email", editData?.email);
      setValue("age", editData?.age);
      setValue("specialistField", editData?.specialistField);
      setValue("councilNumber", editData?.councilNumber);
      setValue("associatedHospitalName", editData?.associatedHospitalName);
    }
  }, [editData, setValue]);

  useEffect(() => {
    const getDoctorData = async () => {
      let response = await fetchWrapper.get(`/doctor/${id}`);
      setEditData(response);
    };
    try {
      if (id) {
        setIsLoading(true);
        getDoctorData();
      }
    } catch (error) {}
    setIsLoading(false);
  }, [id]);
  const watchedPhone = watch("phone");
  const onSubmit = async (body) => {
    try {
      let data = { ...body };
      if (!Boolean(data?.password)) delete data?.password;
      let response = null;
      if (id) {
        response = await fetchWrapper.patch(`/doctor/${id}`, data);
      } else {
        response = await fetchWrapper.post("/doctor", data);
      }
      if (response?._id) {
        if (id) {
          toast.success("Doctor edited successfully");
          setDoctors((prev) => ({
            ...prev,
            results: prev?.results.map((doctor) =>
              doctor?._id === id ? response : doctor
            ),
          }));
        } else {
          toast.success("Doctor added successfully");
          onAdd(response);
        }
        reset();
        setShowAdd(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>{id ? "प्रयोगकर्ता सम्पादन गर्नुहोस्" : " नयाँ प्रयोगकर्ता"}</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>डाक्टरको नाम</Form.Label>
              <Form.Control
                type="text"
                {...register("name")}
                defaultValue={editData && editData?.name}
                placeholder="डाक्टरको नाम लेख्नुहोस्"
              />
              <FormError error={errors && errors?.name} />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>इमेल</Form.Label>
              <Form.Control
                type="email"
                {...register("email")}
                defaultValue={editData && editData?.email}
                placeholder="इमेल लेख्नुहोस्"
              />
              <FormError error={errors && errors?.email} />
            </Form.Group>
            {!id && (
              <Form.Group as={Col} md={4}>
                <Form.Label>फोन नम्बर</Form.Label>
                <Form.Control
                  type="number"
                  className="remove-step"
                  {...register("phone")}
                  defaultValue={editData && editData?.contact}
                  placeholder="फोन नम्बर लेख्नुहोस्"
                />
                <FormError error={errors && errors?.phone} />
              </Form.Group>
            )}
            <Form.Group as={Col} md={4}>
              <Form.Label>उमेर</Form.Label>
              <Form.Control
                type="number"
                {...register("age")}
                className="remove-step"
                defaultValue={editData && editData?.age}
                placeholder="उमेर लेख्नुहोस्"
              />
              <FormError error={errors && errors?.age} />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>विशेषज्ञता</Form.Label>
              <Form.Control
                type="text"
                {...register("specialistField")}
                defaultValue={editData && editData?.specialistField}
                placeholder="विशेषज्ञता लेख्नुहोस्"
              />
              <FormError error={errors && errors?.specialistField} />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>NMC नं.</Form.Label>
              <Form.Control
                type="number"
                className="remove-step"
                {...register("councilNumber")}
                defaultValue={editData && editData?.councilNumber}
                placeholder="NMC नं. लेख्नुहोस्"
              />
              <FormError error={errors && errors?.councilNumber} />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>अस्पताल</Form.Label>
              <Form.Control
                type="text"
                {...register("associatedHospitalName")}
                defaultValue={editData && editData?.associatedHospitalName}
                placeholder="अस्पतालको नाम लेख्नुहोस्"
              />
              <FormError error={errors && errors?.associatedHospitalName} />
            </Form.Group>
          </Row>
          {/* {!id && ( */}
          <Card.Header className="card-header-custom px-0">
            <h3>लग इन विवरण</h3>
          </Card.Header>
          {/* )} */}
          {/* {!id && ( */}
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="number"
                className="remove-step"
                value={watchedPhone || editData?.phone}
                disabled
                placeholder="username"
              />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>पासवर्ड</Form.Label>
              <Password
                register={register}
                id="password"
                placeholder="पासवर्ड लेख्नुहोस्"
              />
              <FormError error={errors && errors?.password} />
            </Form.Group>
          </Row>
          {/* )} */}
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              setShowAdd(!showAdd);
            }}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disabled={isLoading}>
            पेश गर्नुहोस्
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AddDoctor;
