import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import PaginationComp from "../../../components/Pagination";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { StyledTable } from "../../../components/Reusables";
import Print from "../../../components/Print";

const Examination = () => {
  const { id, pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [examinations, setExaminations] = useState(null);
  useEffect(() => {
    const getExaminations = async () => {
      let response = await fetchWrapper.get(
        `/patient/profile/${id}/examination?page=${page}`
      );
      setExaminations(response);
    };
    try {
      getExaminations();
    } catch (error) {}
  }, [id, page]);
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  return (
    <Fragment>
      <Card>
        <StyledTable>
          <Table striped hover responsive>
            <thead>
              <tr>
                <th rowSpan={2}>Date </th>
                <th rowSpan={2} className="text-center">
                  Weight (kg)
                </th>
                <th rowSpan={2} className="text-center">
                  Height (cm)
                </th>
                <th rowSpan={2} className="text-center">
                  BMI (kg/m2)
                </th>
                <th rowSpan={2} className="text-center">
                  Pulse (bpm)
                </th>
                <th colSpan={2} className="text-center">
                  BP (mmHg)
                </th>
                <th rowSpan={2} className="text-center">
                  Respiration Rate (breaths/min)
                </th>
                <th rowSpan={2} className="text-center text-truncate">
                  Temperature (Deg-F)
                </th>
                <th rowSpan={2} className="text-center">
                  Pallor
                </th>
                <th rowSpan={2} className="text-center">
                  Jaundice
                </th>
                <th colSpan={2} className="text-center">
                  Edema
                </th>
                <th rowSpan={2}>Actions</th>
              </tr>
              <tr>
                <th className="text-center">Systolic </th>
                <th className="text-center">Diastolic </th>
                <th className="text-center">Face </th>
                <th className="text-center">Pedal </th>
              </tr>
            </thead>
            <tbody>
              {examinations?.results?.map((exam) => (
                <tr key={exam?._id}>
                  <td>{exam?.postedAt}</td>
                  <td>{exam?.weight}</td>
                  <td className="text-center">{exam?.height}</td>
                  <td className="text-center">{exam?.BMI}</td>
                  <td className="text-center">{exam?.pulse}</td>
                  <td className="text-center">{exam?.BPSystolic}</td>
                  <td className="text-center">{exam?.BPDiastolic}</td>
                  <td className="text-center">{exam?.respirationRate}</td>
                  <td className="text-center">{exam?.temperature}</td>
                  <td className="text-center">{exam?.pallor ? "Y" : "N"}</td>
                  <td className="text-center">{exam?.Jaundice ? "Y" : "N"}</td>
                  <td className="text-center">{exam?.faceEdima ? "Y" : "N"}</td>
                  <td className="text-center">
                    {exam?.pedalEdima ? "Y" : "N"}
                  </td>
                  <td>
                    <Print
                      title="Examination"
                      data={exam}
                      fields={[
                        "weight__kg",
                        "height__cm",
                        "BMI__kg/m2",
                        "pulse__bpm",
                        "BPSystolic__mmHg",
                        "BPDiastolic__mmHg",
                        "respirationRate__breaths/min",
                        "temperature__deg-F",
                        "pallor",
                        "Jaundice",
                        "faceEdima",
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </StyledTable>
      </Card>
      <PaginationComp
        page={page}
        setPage={handleSetPage}
        total={examinations?.totalResults}
      />
    </Fragment>
  );
};

export default Examination;
