import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import {
	// useHistory,
	useParams,
} from 'react-router-dom';
// import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const MedicalHistory = () => {
	const { id, pageNum } = useParams();
	// const history = useHistory();
	// const [page, setPage] = useState(pageNum);
	const [histories, setHistories] = useState(null);
	useEffect(() => {
		const getHistories = async () => {
			let response = await fetchWrapper.get(`/patient/profile/${id}/surgery`);
			setHistories(response);
		};
		try {
			getHistories();
		} catch (error) {}
	}, [id]);
	// const handleSetPage = (page) => {
	// 	setPage(page);
	// 	history.push(`${page}`);
	// };
	console.log(histories);
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Hypertension</th>
								<th>Diabetes Mellitus</th>
								<th>Hypothyriodism</th>
								<th>Abdominal surgeries</th>
								<th>Previous cesarean section</th>
								<th>Others</th>
							</tr>
						</thead>
						<tbody>
							{histories?.results?.map((hist) => (
								<tr key={hist?._id}>
									<td>
										{hist?.hypertension ? 'Yes' : 'No'}{' '}
										{hist?.hypertensionRemarks &&
											` (${hist?.hypertensionRemarks})`}
									</td>
									<td>
										{hist?.diabetesMellitus ? 'Yes' : 'No'}
										{hist?.diabetesMellitusRemarks &&
											` (${hist?.diabetesMellitusRemarks})`}
									</td>
									<td>
										{hist?.hypothyriodism ? 'Yes' : 'No'}
										{hist?.hypothyriodismRemarks &&
											` (${hist?.hypothyriodismRemarks})`}{' '}
									</td>
									<td>
										{hist?.abdominalSurgeries ? 'Yes' : 'No'}{' '}
										{hist?.abdominalSurgeriesRemarks &&
											` (${hist?.abdominalSurgeriesRemarks})`}
									</td>
									<td>
										{hist?.previousCesareansSection ? 'Yes' : 'No'}{' '}
										{hist?.previousCesareansSectionRemarks &&
											` (${hist?.previousCesareansSectionRemarks})`}
									</td>
									<td>{hist?.others}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Card>
			{/* <PaginationComp page={page} setPage={handleSetPage} total={histories?.totalResults} /> */}
		</Fragment>
	);
};

export default MedicalHistory;
