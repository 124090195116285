/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Button, Container, Row, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { logValidationSchema } from '../../_helpers/schema-validation';
import { loginUser } from '../../_redux/slices/users';
import { connect, useSelector } from 'react-redux';
import Loader from '../../_elements/Loader';
import { eye, formInput } from '../../layout/ChangePasswordModal';
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import Versioning from '../../components/Versioning';

const Login = ({ loginUser }) => {
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(logValidationSchema),
	});
	const loading = useSelector((state) => state.users.loading);

	const onSubmit = async (data) => {
		loginUser(data, history);
	};
	const [showPassword, setShowPassword] = useState(false);

	return (
		<section className="section">
			<Container>
				<Row className="mt-5 justify-content-center">
					<div className="col-5">
						<div className="login-brand">
							<img src="/assets/images/Logo.jpeg" alt="logo" width="100" className="shadow-light rounded-circle" />
							<h3>
								Cloud In The Hills
								<br />
								Patan Academy of Health Science
							</h3>
							<p>Mobile Phone Based Application & Database For Pregnant Women In Rural Nepal</p>
						</div>

						<div className="card card-primary">
							<div className="card-body">
								<form
									method="POST"
									action="#"
									className="needs-validation"
									noValidate=""
									onSubmit={handleSubmit(onSubmit)}
								>
									<div className="form-group">
										<label htmlFor="phone">ईमेल</label>
										<input
											id="phone"
											type="phone"
											className="form-control"
											name="phone"
											tabIndex="1"
											required
											autoFocus
											{...register('phone')}
										/>
										<div className="invalid-feedback">कृपया तपाईंको ईमेल भर्नुहोस्</div>
										<p className="text-small text-danger">{errors.username?.message}</p>
									</div>

									<div className="form-group">
										<div className="d-block">
											<label htmlFor="password" className="control-label">
												पासवर्ड
											</label>
											<div className="float-right">
												<Link to="/forgot-password" className="text-small">
													पासवर्ड भुल्नु भयो?
												</Link>
											</div>
										</div>
										<div className="position-relative">
											<Form.Control
												css={formInput}
												type={showPassword ? 'text' : 'password'}
												name="password"
												{...register('password')}
												tabIndex="2"
											/>
											<div css={eye}>
												{showPassword ? (
													<AiFillEyeInvisible
														css={css`
															color: grey;
														`}
														onClick={() => setShowPassword(false)}
													/>
												) : (
													<AiFillEye
														css={css`
															color: grey;
														`}
														onClick={() => setShowPassword(true)}
													/>
												)}
											</div>
										</div>
										<div className="invalid-feedback">कृपया तपाईको पासवर्ड भर्नुहोस्</div>
										<p className="text-small text-danger">{errors.password?.message}</p>
									</div>
									<div className="form-group">
										<div className="custom-control custom-checkbox">
											<input
												type="checkbox"
												name="remember"
												className="custom-control-input"
												tabIndex="3"
												id="remember-me"
											/>
											<label className="custom-control-label text-muted" htmlFor="remember-me">
												मलाई सम्झनुहोस्
											</label>
										</div>
									</div>
									<div className="form-group">
										<Button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
											लगइन
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-12 mt-3">
						{/* <div className="footer-powered d-flex flex-column align-items-center">
							<p>Powered by:</p>
							<img src="/assets/images/Logo.jpeg" className="powered" alt="" />
						</div> */}
						<div className="simple-footer text-muted">
							Copyright &copy; {new Date().getFullYear()}{' '}
							<a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
								Ideabreed IT Solution Pvt. Ltd.
							</a>
							<Versioning color="#8a8080" />
						</div>
					</div>
				</Row>
			</Container>
			{loading && <Loader />}
		</section>
	);
};

export default connect(null, { loginUser })(Login);
