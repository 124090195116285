import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Card, Table, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import styled from '@emotion/styled';

const Labour = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [labour, setLabour] = useState(null);
	const [labourTypes, setLabourTypes] = useState('first-and-second');
	useEffect(() => {
		const getLabour = async () => {
			let response = await fetchWrapper.get(
				`/patient/profile/${id}/labor?page=${page}`
			);
			setLabour(response);
		};
		try {
			getLabour();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<div className="d-flex p-3 align-items-center">
					<Form.Label htmlFor="trimester-select" className="mr-3 my-0">
						Trimester
					</Form.Label>
					<Form.Control
						as="select"
						id="trimester-select"
						className="w-25"
						onChange={(e) => setLabourTypes(e.target.value)}
					>
						<option value="" disabled>
							Select
						</option>
						<option
							value="first-and-second"
							{...(labourTypes === 'first-and-second' && { selected: true })}
						>
							First And Second Stage{' '}
							{labourTypes === 'first-and-second' && '::Active'}
						</option>
						<option
							value="delivery"
							{...(labourTypes === 'delivery' && {
								selected: true,
							})}
						>
							Delivery Stage {labourTypes === 'delivery' && '::Active'}
						</option>
						<option
							value="third"
							{...(labourTypes === 'third' && { selected: true })}
						>
							Third Stage {labourTypes === 'third' && '::Active'}
						</option>
						<option
							value="baby"
							{...(labourTypes === 'baby' && { selected: true })}
						>
							Baby Examination {labourTypes === 'baby' && '::Active'}
						</option>
					</Form.Control>
				</div>
				<StyledTable>
					<Table striped hover responsive>
						<thead>
							<tr>
								{labourTypes === 'first-and-second' && (
									<Fragment>
										<th>Labor</th>
										<th className="text-center">Contraction</th>
										<th className="text-center">Cervical Os</th>
										<th className="text-center">Effacement</th>
										<th className="text-center">Bleeding</th>
										<th className="text-center">Membrane</th>
										<th className="text-center">Liquor</th>
										<th className="text-center">Pelvis</th>
									</Fragment>
								)}
								{labourTypes === 'delivery' && (
									<Fragment>
										<th>Time Of Full Dialation Of Cervix</th>
										<th>Time Of Delivery</th>
										<th>Mode Of Delivery</th>
									</Fragment>
								)}

								{labourTypes === 'third' && (
									<Fragment>
										<th>Oxytocin</th>
										<th>Ergometrine</th>
										<th>Manual Remove Of Placenta</th>
										<th>Placenta Removal Delivery</th>
										<th>Placenta Removal Operating</th>
										<th>Placenta</th>
										<th>Membranes</th>
										<th>Check Curettage</th>
										<th>Weight Of Placenta</th>
										<th>Weight Of Baby</th>
										<th>Blood Loss</th>
										<th>Pluse</th>
										<th>BP</th>
									</Fragment>
								)}
								{labourTypes === 'baby' && (
									<Fragment>
										<th>Amniotic Fluid</th>
										<th>Meconium Type</th>
										<th>Gestational Age</th>
										<th>Weight</th>
										<th>Sex</th>
										<th>Live</th>
										<th>Still Birth Type</th>
										<th>Cord Around The Neck</th>
										<th>Apgar Score 1Min</th>
										<th>Apgar Score 5Min</th>
										<th>Methods Of Resuscitation</th>
										<th>Misinformation</th>
										<th>Vitamin K</th>
										<th>Refered To Higher Center</th>
										<th>Breast Feeding At</th>
									</Fragment>
								)}
							</tr>
						</thead>
						<tbody>
							{labour?.results?.map((lab) => (
								<tr key={lab?._id}>
									{labourTypes === 'first-and-second' && (
										<Fragment>
											<td>{lab?.labor}</td>
											<td>{lab?.contraction}</td>
											<td>{lab?.cervicalOs}</td>
											<td>{lab?.effacement}</td>
											<td>{lab?.bleeding}</td>
											<td>{lab?.membrane}</td>
											<td>{lab?.liquor}</td>
											<td>{lab?.pelvis}</td>
										</Fragment>
									)}
									{labourTypes === 'delivery' && (
										<Fragment>
											<td>{lab?.timeOfFullDialationOFCervix}</td>
											<td>{lab?.timeOfDelivery}</td>
											<td>{lab?.modeOfDelivery}</td>
										</Fragment>
									)}
									{labourTypes === 'third' && (
										<Fragment>
											<td>{lab?.thirdStage?.oxytocin}</td>
											<td>{lab?.thirdStage?.ergometrine}</td>
											<td>{lab?.thirdStage?.manualRemoveOfPlacenta}</td>
											<td>{lab?.thirdStage?.placentaRemovalDelivery}</td>
											<td>{lab?.thirdStage?.placentaRemovalOperating}</td>
											<td>{lab?.thirdStage?.placenta}</td>
											<td>{lab?.thirdStage?.membranes}</td>
											<td>{lab?.thirdStage?.checkCurettage}</td>
											<td>{lab?.thirdStage?.weightOfPlacenta}</td>
											<td>{lab?.thirdStage?.weightOfBaby}</td>
											<td>{lab?.thirdStage?.bloodLoss}</td>
											<td>{lab?.thirdStage?.pluse}</td>
											<td>{lab?.thirdStage?.BP}</td>
										</Fragment>
									)}
									{labourTypes === 'baby' && (
										<Fragment>
											<td>{lab?.babyExamination?.amnioticFluid}</td>
											<td>{lab?.babyExamination?.meconiumType}</td>
											<td>{lab?.babyExamination?.gestationalAge}</td>
											<td>{lab?.babyExamination?.weight}</td>
											<td>{lab?.babyExamination?.sex}</td>
											<td>{lab?.babyExamination?.live}</td>
											<td>{lab?.babyExamination?.stillBirthType}</td>
											<td>{lab?.babyExamination?.cordAroundTheNeck}</td>
											<td>{lab?.babyExamination?.apgarScore1Min}</td>
											<td>{lab?.babyExamination?.apgarScore5Min}</td>
											<td>{lab?.babyExamination?.methodsOfResuscitation}</td>
											<td>{lab?.babyExamination?.misinformation}</td>
											<td>{lab?.babyExamination?.vitaminK}</td>
											<td>{lab?.babyExamination?.referedToHigherCenter}</td>
											<td>{lab?.babyExamination?.breastFeedingAt}</td>
										</Fragment>
									)}
								</tr>
							))}
						</tbody>
					</Table>
				</StyledTable>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={labour?.totalResults}
			/>
		</Fragment>
	);
};

export default Labour;

const StyledTable = styled.div`
	width: calc(100vw - 300px);
	table {
		table-layout: auto;
	}
`;
