/** @jsxRuntime classic */
/** @jsx jsx */
import {
	jsx,
	// css
} from '@emotion/react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AmbulanceValidation } from '../../../_helpers/schema-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from '../../../components/Form/FormError';

const AddAmbulance = ({ showAdd, setShowAdd, id, onAdd, setAmbulance }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [editData, setEditData] = useState(null);
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(AmbulanceValidation),
	});

	useEffect(() => {
		if (editData) {
			setValue('title', editData?.title);
			setValue('info', editData?.info);
			setValue('phone', editData?.phone);
		}
	}, [editData, setValue]);

	useEffect(() => {
		const getAmbulanceData = async () => {
			let response = await fetchWrapper.get(`/ambulance/${id}`);
			setEditData(response);
		};
		try {
			if (id) {
				setIsLoading(true);
				getAmbulanceData();
			}
		} catch (error) {}
		setIsLoading(false);
	}, [id]);
	const onSubmit = async (data) => {
		try {
			let response = null;
			if (id) {
				response = await fetchWrapper.patch(`/ambulance/${id}`, data);
			} else {
				response = await fetchWrapper.post('/ambulance', data);
			}
			if (response?._id) {
				if (id) {
					toast.success('Ambulance edited successfully');
					setAmbulance((prev) => ({
						...prev,
						results: prev?.results.map((amb) =>
							amb?._id === id ? response : amb
						),
					}));
				} else {
					toast.success('Ambulance added successfully');
					onAdd(response);
				}
				reset();
				setShowAdd(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
			<Card>
				<Card.Header className="card-header-custom">
					<h3>{id ? 'Help सम्पादन गर्नुहोस्' : ' नयाँ Help'}</h3>
				</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md={4}>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type="text"
								{...register('title')}
								defaultValue={editData && editData?.title}
								placeholder="Title"
							/>
							<FormError error={errors && errors?.title} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Info</Form.Label>
							<Form.Control
								type="text"
								{...register('info')}
								defaultValue={editData && editData?.info}
								placeholder="इमेल लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.info} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>फोन नम्बर</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								{...register('phone')}
								defaultValue={editData && editData?.phone}
								placeholder="फोन नम्बर लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.phone} />
						</Form.Group>
					</Row>
				</Card.Body>

				<Card.Footer className="d-flex justify-content-end align-items-center">
					<Button
						variant="secondary"
						className="mr-2"
						onClick={() => {
							setShowAdd(!showAdd);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button type="submit" variant="success" disabled={isLoading}>
						पेश गर्नुहोस्
					</Button>
				</Card.Footer>
			</Card>
		</Form>
	);
};

export default AddAmbulance;
