import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Table, Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const PreviousPregnancy = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [pregnancies, setPregnancies] = useState(null);
	useEffect(() => {
		const getPregnancies = async () => {
			const response = await fetchWrapper.get(`/patient/${id}`);
			setPregnancies(response);
		};
		try {
			getPregnancies();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	console.log(pregnancies);
	return (
		<Fragment>
			<Card>
				<Row className="p-4">
					<Col
						className="d-flex align-items-center"
						style={{ columnGap: '1rem' }}
					>
						<h6>Gravida:</h6>
						<p> {pregnancies?.gravida}</p>
					</Col>
					<Col
						className="d-flex align-items-center"
						style={{ columnGap: '1rem' }}
					>
						<h6>Para:</h6>
						<p> {pregnancies?.para}</p>
					</Col>
					<Col
						className="d-flex align-items-center"
						style={{ columnGap: '1rem' }}
					>
						<h6>Aborted:</h6>
						<p> {pregnancies?.aborted}</p>
					</Col>
					<Col
						className="d-flex align-items-center"
						style={{ columnGap: '1rem' }}
					>
						<h6>Living:</h6>
						<p> {pregnancies?.living}</p>
					</Col>
				</Row>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th className="center">SN</th>
								<th>Birth Date</th>
								<th>Gest. Age</th>
								<th>Living</th>
								<th>Gender</th>
								<th>B. Weight</th>
								<th>Mode of Delivery</th>
								<th>Complicated Pregnancy</th>
								<th>Complicated Delivery</th>
								<th>Remarks</th>

								{/* <th>Posted By</th> */}
							</tr>
						</thead>
						<tbody>
							{pregnancies?.previousPregnancy?.map((pregnancy, i) => (
								<tr key={pregnancy?._id}>
									<td className="text-center">{++i}</td>
									<td>{pregnancy?.date}</td>
									<td>{pregnancy?.gestAge}</td>
									<td>{pregnancy?.living ? 'Yes' : 'No'}</td>
									<td>{pregnancy?.gender}</td>
									<td>{pregnancy?.babyWeight}</td>
									<td>{pregnancy?.modeOfDelivery}</td>
									<td>{pregnancy?.complicatedPregnancy}</td>
									<td>{pregnancy?.complicatedDelivery}</td>
									<td>{pregnancy?.remarks}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				total={pregnancies?.totalResults}
				setPage={handleSetPage}
			/>
		</Fragment>
	);
};

export default PreviousPregnancy;
