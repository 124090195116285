import React from 'react';
import { useEffect } from 'react';
import { Card, Col, Jumbotron, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../../../_elements/BackButton';
import { getSingleMaterial } from '../../../_redux/slices/materials';
import { createMarkup } from '../../../_helpers/methods';
import YoutubeEmbed from '../../../components/YoutubeEmbed';

const MaterialPreview = () => {
	const dispatch = useDispatch();
	const material = useSelector((state) => state.materials.material);
	const { id } = useParams();
	useEffect(() => {
		dispatch(getSingleMaterial(id));
	}, [dispatch, id]);
	return (
		<>
			<BackButton />
			<Row>
				<Card as={Col} md={12}>
					<Card.Header>
						<h2>{material?.title}</h2>
					</Card.Header>
					<Card.Body>
						<Jumbotron>
							<p dangerouslySetInnerHTML={createMarkup(material?.description)}></p>
						</Jumbotron>
						<div>
							<Content type={material?.type} content={material?.content} />
						</div>
					</Card.Body>
				</Card>
			</Row>
		</>
	);
};

export default MaterialPreview;

const Content = ({ type, content }) => {
	if (type === 'article') {
		return (
			<div className="border p-4">
				<h4>Article</h4>
				<p dangerouslySetInnerHTML={createMarkup(content)}></p>
			</div>
		);
	} else if (type === 'video') {
		return (
			<div>
				<YoutubeEmbed url={content} />
			</div>
		);
	}
	return null;
};
