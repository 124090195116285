/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PaginationComp from '../../components/Pagination';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { FaEdit, FaEye, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import BackButton from '../../_elements/BackButton';
import Loading from '../../components/Loading';
import DeleteModal from '../../components/DeleteModal';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { PalikaValidation } from '../../_helpers/schema-validation';
import FormError from '../../components/Form/FormError';
import { getPalikas as getPalikaRedux } from '../../_redux/slices/palika';
import { useDispatch, useSelector } from 'react-redux';

const Palika = () => {
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [isLoading, setIsLoading] = useState(true);
	const [palika, setPalika] = useState();
	const [showAdd, setShowAdd] = useState(false);
	const [editData, setEditData] = useState(null);
	const dispatch = useDispatch();
	const palikas = useSelector((state) => state.palika.allPalikas);

	useEffect(() => {
		try {
			dispatch(getPalikaRedux());
		} catch (error) {}
	}, [dispatch]);

	useEffect(() => {
		const getPalikas = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get(`/palika?page=${pageNum}`);
			setPalika(response);
			setIsLoading(false);
		};
		getPalikas();
	}, [pageNum]);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	const {
		register,
		watch,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(PalikaValidation),
		defaultValues: { state: editData?.state, jilla: editData?.jilla },
	});

	const watchedState = watch('state');
	const watchedDistrict = watch('district');

	const onSubmit = async (data) => {
		try {
			if (editData) {
				let response = await fetchWrapper.patch(
					`/palika/${editData?._id}`,
					data
				);
				if (response?._id) {
					let tempPalika = palika?.results?.filter(
						(res) => res._id !== editData._id
					);
					setPalika((prev) => ({
						...prev,
						results: [response, ...tempPalika],
					}));
					// reset();
					setShowAdd(false);
					toast.success('Palika Edited Successfully');
				}
			} else {
				let response = await fetchWrapper.post('/palika', data);
				if (response?._id) {
					setPalika((prev) => ({
						...prev,
						results: [response, ...prev.results],
					}));
					setShowAdd(false);
					toast.success('Palika Added Successfully');
				}
			}
			reset();
		} catch (error) {}
	};

	const onDelete = async (id) => {
		try {
			await fetchWrapper.delete(`palika/${id}`);
			let tempPalika = palika?.results.filter((res) => res._id !== id);
			setPalika((prev) => ({ ...prev, results: tempPalika }));
			toast.success('Palika Deleted Successfully');
		} catch (error) {}
	};

	const [show, setShow] = useState(null);

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<BackButton />
				</Col>
			</Row>
			{showAdd && (
				<Card>
					<Card.Header className="card-header-custom d-block">
						<h3>नयाँ पालिका</h3>
					</Card.Header>
					<Card.Body className="p-4">
						<Row>
							<Col md={12}>
								<Form
									as={Row}
									className=" px-4"
									onSubmit={handleSubmit(onSubmit)}
								>
									<Col md={4}>
										<Form.Group>
											<Form.Label>प्रदेश:</Form.Label>
											<Form.Control
												{...register('state')}
												defaultValue={editData && editData?.state}
												as="select"
												size="sm"
											>
												<option value="" disabled selected>
													-- प्रदेश छान्नुहोस् --
												</option>
												{palikas?.map((pradesh) => (
													<option key={pradesh?.pid} value={pradesh?.pid}>
														{pradesh?.title}
													</option>
												))}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group>
											<Form.Label>जिल्ला:</Form.Label>
											<Form.Control
												as="select"
												{...register('district')}
												defaultValue={editData && editData?.district}
												disabled={editData ? !editData?.state : !watchedState}
												size="sm"
											>
												<option value="" disabled>
													-- जिल्ला छान्नुहोस् --
												</option>

												{palikas
													?.find(
														(pradesh) =>
															pradesh?.pid ===
															+(editData?.state ?? watchedState)
													)
													?.districts?.map((dist) => (
														<option key={dist?.did} value={dist?.did}>
															{dist?.title}
														</option>
													))}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group>
											<Form.Label>पालिका:</Form.Label>
											<Form.Control
												as="select"
												{...register('localBody')}
												defaultValue={editData && editData?.localBody}
												disabled={
													editData
														? !editData?.district
														: !watchedState || !watchedDistrict
												}
												size="sm"
											>
												<option value="" disabled>
													-- पालिका छान्नुहोस् --
												</option>

												{palikas
													?.find(
														(pradesh) =>
															pradesh?.pid ===
															+(editData?.state ?? watchedState)
													)
													?.districts?.find(
														(dist) =>
															dist?.did ===
															+(editData?.district ?? watchedDistrict)
													)
													?.local_levels?.map((localBody) => (
														<option
															key={localBody?._id}
															value={localBody?.title}
														>
															{localBody?.title}
														</option>
													))}
											</Form.Control>
										</Form.Group>
									</Col>

									<Form.Group as={Col} md={4}>
										<Form.Label>
											कुल वडा:<span className="text-danger"></span>
										</Form.Label>
										<Form.Control
											type="number"
											className="remove-step"
											placeholder="वडा संख्या लेख्नुहोस्"
											{...register('noOfWards')}
											defaultValue={editData && editData?.noOfWards}
										/>
										<FormError error={errors && errors?.noOfWards} />
									</Form.Group>

									<Form.Group as={Col} md={4}>
										<Form.Label>
											ठेगाना: <span className="text-danger"></span>
										</Form.Label>
										<Form.Control
											type="text"
											{...register('address')}
											defaultValue={editData && editData?.address}
											placeholder="ठेगाना लेख्नुहोस्"
										/>
										<FormError error={errors && errors?.address} />
									</Form.Group>
									<Form.Group as={Col} md={4}>
										<Form.Label>
											सम्पर्क नं:<span className="text-danger"></span>
										</Form.Label>
										<Form.Control
											type="number"
											className="remove-step"
											{...register('contactNo')}
											defaultValue={editData && editData?.contactNo}
											placeholder="सम्पर्क नं लेख्नुहोस्"
										/>
										<FormError error={errors && errors?.contactNo} />
									</Form.Group>
									<Form.Group as={Col} md={4}>
										<Form.Label>
											सम्पर्क व्यक्ति: <span className="text-danger"></span>
										</Form.Label>
										<Form.Control
											type="text"
											{...register('contactPerson')}
											defaultValue={editData && editData?.contactPerson}
											placeholder="सम्पर्क व्यक्तिको नाम लेख्नुहोस्"
										/>
										<FormError error={errors && errors?.contactPerson} />
									</Form.Group>
									<Form.Group as={Col} md={4}>
										<Form.Label>
											सम्पर्क व्यक्तिको पद:{' '}
											<span className="text-danger"></span>
										</Form.Label>
										<Form.Control
											type="text"
											{...register('contactPersonDesignation')}
											defaultValue={
												editData && editData?.contactPersonDesignation
											}
											placeholder="सम्पर्क व्यक्तिको पद लेख्नुहोस्"
										/>
										<FormError
											error={errors && errors?.contactPersonDesignation}
										/>
									</Form.Group>

									<div className=" d-flex justify-content-end col-md-12">
										<Button variant="success" onClick={handleSubmit(onSubmit)}>
											सुरक्षित
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			)}

			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom">
							<h2>पालिका</h2>
							<Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
								<FaPlusCircle />
								&nbsp; नयाँ पालिका
							</Button>
						</Card.Header>

						<Card.Body className="p-0 mt-4">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr>
											<th>पालिकाको नाम</th>
											<th>ठेगाना</th>
											<th>कुल वडा</th>
											<th>सम्पर्क व्यक्ति</th>
											<th>सम्पर्क नं</th>
											<th>कार्यहरू</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<tr>
												<td></td>
												<td></td>
												<td className="d-flex justify-content-center fs-5">
													<Loading size="2rem" />
												</td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										) : (
											palika?.results?.map((palika) => (
												<tr key={palika._id}>
													<td>{palika.localBody}</td>
													<td>{palika.address}</td>
													<td>{palika.noOfWards}</td>
													<td>{palika.contactPerson}</td>
													<td>{palika.contactNo}</td>
													<td>
														<Button
															variant="info"
															onClick={() => {
																history.push(
																	`/settings/palika/1/${palika?._id}`
																);
															}}
														>
															<FaEye />
														</Button>
														<Button
															variant="primary"
															className="ml-2"
															onClick={() => {
																setShowAdd(!showAdd);
																setEditData(palika);
															}}
														>
															<FaEdit />
														</Button>
														<Button
															variant="danger"
															onClick={() => setShow(palika._id)}
															className="ml-2"
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PaginationComp
				page={page}
				total={palika?.totalResults}
				setPage={handleSetPage}
			/>
			<DeleteModal
				show={show}
				onHide={() => setShow(false)}
				onDelete={() => onDelete(show)}
			/>
		</Fragment>
	);
};

export default Palika;
