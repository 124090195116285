import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const Investigation = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [invgs, setInvgs] = useState(null);
	const [profile, setProfile] = useState(null);
	useEffect(() => {
		const getInvgs = async () => {
			let response = await fetchWrapper.get(
				`/patient/profile/${id}/investigation`
			);
			setInvgs(response);
		};
		const getProfile = async () => {
			const response = await fetchWrapper.get(`/patient/${id}`);
			setProfile(response);
		};
		try {
			getInvgs();
			getProfile();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<Row className="p-4">
					<Col className="d-flex align-items-center">
						<h6>Blood Group: &nbsp;</h6>
						<p>{profile?.Bloodgroup}</p>
					</Col>
				</Row>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th className="text-center">Hb (g/dl)</th>
								<th className="text-center">Albumin (g/dl)</th>
								<th className="text-center">Urine Protein (kg)</th>
								<th className="text-center">Urine Sugar</th>
								<th className="text-center">Blood Sugar (mg/dl)</th>
								<th className="text-center">HBsAg (IU/mL)</th>
								<th className="text-center">VDRL</th>
								<th className="text-center">HIV</th>
								<th className="text-center">Platelets Count (/mm3)</th>
								{/* <th>Posted By</th> */}
							</tr>
						</thead>
						<tbody>
							{invgs?.results?.map((invg) => (
								<tr key={invg?._id}>
									<td>{invg?.postedAt}</td>
									<td className="text-center">{invg?.Hb}</td>
									<td className="text-center">{invg?.Albumin}</td>
									<td className="text-center">{invg?.UrineProtein}</td>
									<td className="text-center">{invg?.UrineSugar}</td>
									<td className="text-center">{invg?.BloodSugar}</td>
									<td className="text-center">{invg?.HBsAG}</td>
									<td className="text-center">{invg?.VDRL}</td>
									<td className="text-center">{invg?.Retrovirus}</td>
									<td className="text-center">{invg?.plateletsCount}</td>
									{/* <td></td> */}
								</tr>
							))}
							{/* <tr>
								<td>2022/09/10</td>
								<td className="text-center">10</td>
								<td className="text-center">2</td>
								<td className="text-center">3</td>
								<td className="text-center">5</td>
								<td className="text-center">6</td>
								<td className="text-center">10</td>
								<td className="text-center">10</td>
								<td className="text-center">10</td>
								<td>Mary Jane</td>
								<td>2022/09/10 01:23</td>
							</tr> */}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={invgs?.totalResults}
			/>
		</Fragment>
	);
};

export default Investigation;
