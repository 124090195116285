import React from 'react';
import { useLocation } from 'react-router';
import SubMenu from './SubMenu';

function SidebarButtonRenderer({ data, user }) {
	const location = useLocation();

	return (
		<>
			{data.map((item) => (
				<SubMenu item={item} key={item.id} currentPath={location.pathname} />
			))}
		</>
	);
}

export default SidebarButtonRenderer;
