/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FormError from "../components/Form/FormError";
import { FAQValidation } from "../_helpers/schema-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table, Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import BackButton from "../_elements/BackButton";
import PaginationComp from "../components/Pagination";
import { useHistory } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
// import { useSelector,} from 'react-redux';
import { fetchWrapper } from "../_helpers/fetchWrapper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const FAQ = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [FAQs, setFAQs] = useState({});
  const [showAdd, setShowAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(pageNum);
  // const materials = useSelector((state) => state.materials);

  useEffect(() => {
    const getFAQs = async () => {
      let res = await fetchWrapper.get(`/faq?page=${page}`);
      setFAQs(res);
    };
    try {
      getFAQs();
    } catch (error) {}
  }, [page]);
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`/faq/${page}`);
  };

  const onDelete = async (id) => {
    try {
      await fetchWrapper.delete(`/faq/${id}`);
      setFAQs((prev) => {
        let temp = prev?.results?.filter((v) => v?._id !== id);
        return { ...prev, results: temp };
      });
    } catch (error) {}
  };

  return (
    <Fragment>
      <BackButton />
      {showAdd && (
        <AddFAQ
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          editData={editData}
          setFAQs={setFAQs}
        />
      )}

      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom">
              <h2>Frequently Asked Questions</h2>
              <div>
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={() => setShowAdd((prev) => !prev)}
                >
                  <FaPlusCircle />
                  &nbsp; नयाँ FAQ
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Question</th>
                      <th>Answer</th>
                      <th style={{ width: "15%" }}>कार्य</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FAQs?.results?.map((faq, i) => (
                      <tr key={faq?._id}>
                        <td>{page * 10 - 9 + i}</td>
                        <td>{faq?.question}</td>
                        <td>{faq?.answer}</td>
                        <td>
                          <Button variant="info" className="ml-2">
                            <FaEdit
                              onClick={() => {
                                setEditData(faq);
                                setShowAdd(true);
                              }}
                            />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => onDelete(faq?._id)}
                            className="ml-2"
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={page}
        total={FAQs?.totalResults}
        setPage={handleSetPage}
      />
    </Fragment>
  );
};

const AddFAQ = ({ showAdd, setShowAdd, editData, setFAQs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(FAQValidation),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (editData) {
        let res = await fetchWrapper.patch(`/faq/${editData?._id}`, data);
        setFAQs((prev) => {
          let temp = prev?.results?.filter((v) => v?._id !== editData?._id);
          return { ...prev, results: [res, ...temp] };
        });
      } else {
        let res = await fetchWrapper.post(`/faq/`, data);
        setFAQs((prev) => ({
          ...prev,
          results: [res, ...prev.results],
        }));
      }
      setIsLoading(false);
      reset();
      setShowAdd(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>{false === "edit" ? "FAQ सम्पादन गर्नुहोस्" : " नयाँ FAQ"}</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label className="mb-0">Type</Form.Label>
              <Form.Control
                as="select"
                {...register("type")}
                defaultValue={editData && editData?.question}
                placeholder="Question लेख्नुहोस्"
              >
                <option disabled>-- Choose type --</option>
                <option value="anm">स्वास्थ्यकर्मी</option>
                <option value="patient">गर्भवती/सुत्केरी महिला</option>
              </Form.Control>
              <FormError error={errors && errors?.question} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={12}>
              <Form.Label className="mb-0">Question</Form.Label>
              <Form.Control
                as="textarea"
                {...register("question")}
                defaultValue={editData && editData?.question}
                placeholder="Question लेख्नुहोस्"
              />
              <FormError error={errors && errors?.question} />
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <Form.Label className="mb-0">Answer</Form.Label>
              <Form.Control
                as="textarea"
                {...register("answer")}
                defaultValue={editData && editData?.answer}
                placeholder="Answer लेख्नुहोस्"
              />
              <FormError error={errors && errors?.answer} />
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              reset();
              setShowAdd(!showAdd);
            }}
            disabled={false}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disabled={isLoading}>
            पेश गर्नुहोस्
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default FAQ;
