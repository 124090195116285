/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import PaginationComp from "../../components/Pagination";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { ageCalculator } from "../../_helpers/methods";
import { useSelector } from "react-redux";
import moment from "moment";
import camelToNormal from "../../_helpers/camelToNormal";

const Verified = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [filter, setFilter] = useState({ name: null });
  const [logs, setPatients] = useState();
  const user = useSelector((state) => state.users.user);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  useEffect(() => {
    const getPatients = async () => {
      let response = await fetchWrapper.get(
        `patient/profile/all/dailySymptoms?page=${page}${
          filter?.name ? `&name=${filter.name}` : ""
        }`
      );
      setPatients(response);
    };
    try {
      getPatients();
    } catch (error) {}
  }, [page, filter]);

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card
            css={css`
              min-height: 600px;
            `}
          >
            <Card.Header className="card-header-custom">
              <h2>Activity Log</h2>
              <div
                className="card-header-form"
                css={css`
                  min-width: 260px;
                `}
              >
                <div className="input-group">
                  <Form.Control
                    type="text"
                    className="custom-search"
                    placeholder="Search Patient Name"
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />

                  <div className="input-group-btn">
                    <button className="btn btn-primary input-search-icon">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: left;
                        }
                      `}
                    >
                      <th>Patient Name</th>
                      <th style={{ width: "10%" }}>Contact No.</th>
                      <th style={{ width: "4%" }}>Age</th>
                      <th style={{ width: "15%" }}>
                        <p>ANM Name/</p>
                        <p>Health Post</p>
                      </th>
                      <th style={{ width: "12%" }}>
                        <p>Posted Date</p>
                      </th>
                      <th style={{ width: "25%" }}>Symptoms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs?.results?.map((log) => (
                      <tr key={log._id}>
                        <td>{log?.patient?.name}</td>
                        <td>{log?.patient?.user?.phone}</td>
                        <td>
                          {ageCalculator(
                            user?.today,
                            log?.patient?.dateOfBirth
                          )}
                        </td>
                        <td>
                          <p>{log?.patient?.anm?.name}</p>
                          <p>{log?.patient?.healthpost?.name}</p>

                          <br />
                          {log?.anm?.healthpost}
                        </td>
                        <td>
                          {log?.postedAt} <br />{" "}
                          {moment(log?.createdAt).format("hh:mm A")}
                        </td>
                        <td
                          css={css`
                            & > div {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              gap: 4px;
                              border-bottom: 1px solid #ccc;
                            }
                            & > div:last-child {
                              border-bottom: none;
                            }
                          `}
                        >
                          <Logs log={log} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PaginationComp
        page={page}
        total={logs?.totalResults}
        setPage={handleSetPage}
      />
    </Fragment>
  );
};

function Logs({ log }) {
  if (log?.symptomsType === "firstTrimester") {
    return (
      <>
        <div>
          <p>{camelToNormal("symptomsType")}</p>
          <p>{camelToNormal(log?.symptomsType)}</p>
        </div>
        <div>
          <p>{camelToNormal("upperAbdominalPain")}</p>
          <p>{log?.upperAbdominalPain}</p>
        </div>
        <div>
          <p>{camelToNormal("backPain")}</p>
          <p>{log?.backPain}</p>
        </div>
        <div>
          <p>{camelToNormal("headache")}</p>
          <p>{log?.headache}</p>
        </div>
        <div>
          <p>{camelToNormal("jaundice")}</p>
          <p>{log?.jaundice}</p>
        </div>
        <div>
          <p>{camelToNormal("shortnessOfBreath")}</p>
          <p>{log?.shortnessOfBreath}</p>
        </div>
        <div>
          <p>{camelToNormal("cough")}</p>
          <p>{log?.cough}</p>
        </div>
        <div>
          <p>{camelToNormal("burningUrination")}</p>
          <p>{log?.burningUrination}</p>
        </div>
        <div>
          <p>{camelToNormal("constipation")}</p>
          <p>{log?.constipation}</p>
        </div>
        <div>
          <p>{camelToNormal("engorgedBreast")}</p>
          <p>{log?.engorgedBreast}</p>
        </div>

        <div>
          <p>{camelToNormal("lowerAbdominalPain")}</p>
          <div>
            <p>Continuous: {log?.lowerAbdominalPain?.continuous}</p>
            <p>Episodic: {log?.lowerAbdominalPain?.episodic}</p>
          </div>
        </div>
        <div>
          <p>{camelToNormal("swelling")}</p>
          <div>
            <p>Face: {log?.swelling?.face}</p>
            <p>Feet: {log?.swelling?.feet}</p>
            <p>Whole Body: {log?.swelling?.wholeBody}</p>
          </div>
        </div>
        <div>
          <p>{camelToNormal("sleep")}</p>
          <p>{log?.sleep}</p>
        </div>
        <div>
          <p>{camelToNormal("other")}</p>
          <p>{log?.other}</p>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          <p>{camelToNormal("symptomsType")}</p>
          <p>{camelToNormal(log?.symptomsType)}</p>
        </div>
        <div>
          <p>{camelToNormal("backPain")}</p>
          <p>{log?.backPain}</p>
        </div>
        <div>
          <p>{camelToNormal("burningUrination")}</p>
          <p>{log?.burningUrination}</p>
        </div>
        <div>
          <p>{camelToNormal("constipation")}</p>
          <p>{log?.constipation}</p>
        </div>
        <div>
          <p>{camelToNormal("cough")}</p>
          <p>{log?.cough}</p>
        </div>
        <div>
          <p>{camelToNormal("headache")}</p>
          <p>{log?.headache}</p>
        </div>
        <div>
          <p>{camelToNormal("engorgedBreast")}</p>
          <p>{log?.engorgedBreast}</p>
        </div>
        <div>
          <p>{camelToNormal("jaundice")}</p>
          <p>{log?.jaundice}</p>
        </div>
        <div>
          <p>{camelToNormal("shortnessOfBreath")}</p>
          <p>{log?.shortnessOfBreath}</p>
        </div>

        <div>
          <p>{camelToNormal("lowerAbdominalPain")}</p>
          <div>
            <p>Continuous: {log?.lowerAbdominalPain?.continuous}</p>
            <p>Episodic: {log?.lowerAbdominalPain?.episodic}</p>
          </div>
        </div>
        <div>
          <p>{camelToNormal("swelling")}</p>
          <div>
            <p>Face: {log?.swelling?.face}</p>
            <p>Feet: {log?.swelling?.feet}</p>
            <p>Whole Body: {log?.swelling?.wholeBody}</p>
          </div>
        </div>
        <div>
          <p>{camelToNormal("sleep")}</p>
          <p>{log?.sleep}</p>
        </div>
        <div>
          <p>{camelToNormal("upperAbdominalPain")}</p>
          <p>{log?.upperAbdominalPain}</p>
        </div>
        {/* <div>
          <p>{camelToNormal("other")}</p>
          <p>{log?.other}</p>
        </div> */}
      </>
    );
  }
}

export default Verified;
