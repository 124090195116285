import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import AbdominalExam from "./tabs/AbdominalExam";
import Contraception from "./tabs/Contraception";
import DailySymptoms from "./tabs/DailySymptoms";
import EmergencyAlert from "./tabs/EmergencyAlert";
import Examination from "./tabs/Examination";
import Investigation from "./tabs/Investigation";
import MedicalHistory from "./tabs/MedicalHistory";
import Medication from "./tabs/Medication";
import PatientProfile from "./tabs/PatientProfile";
import PregnancyHistory from "./tabs/PregnancyHistory";
import UltraSound from "./tabs/UltraSound";

//
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { weekCalculator } from "../../_helpers/methods";
import Labour from "./tabs/Labour";
import PostNatal from "./tabs/PostNatal";

const Profile = () => {
  const { path, url, params } = useRouteMatch();
  const { id } = useParams();
  const [profileInfo, setProfileInfo] = useState(null);
  const [phase, setPhase] = useState({ phase: null, trimester: null }); // postnatal | termination | prenatal | null
  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await fetchWrapper.get(`/patient/${id}`);
      setProfileInfo({
        ...response,
        weeks: Math.trunc(weekCalculator(response?.dateOfLastPeroid)),
      });
    };
    const getPhase = async () => {
      const response = await fetchWrapper.get(`/patient/${id}/getTrimester`);
      switch (response?.phase) {
        case "postnatal":
          setPhase({ phase: "postnatal", trimester: "postnatal" });
          break;
        case "prenatal":
          setPhase({ phase: "prenatal", trimester: response?.trimester });
          break;
        case "termination":
          setPhase({ phase: "termination", trimester: "termination" });
          break;
        default:
          setPhase(null);
          break;
      }
    };
    try {
      getProfileInfo();
      getPhase();
    } catch (error) {}
  }, [id]);
  console.log("phase", phase);
  return (
    <div className="profile-tab-container">
      <Router>
        <Tab.Container defaultActiveKey="profile">
          <Nav className="profile-tabs">
            <Nav.Item>
              <Nav.Link
                eventKey="profile"
                as={NavLink}
                to={`/profile/${id}/`}
                exact
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Link
              eventKey="daily_symptoms"
              as={NavLink}
              to={`/profile/${id}/dailysymptoms/${phase?.trimester}/1`}
              exact
            >
              Daily Symptoms
            </Nav.Link>

            <Nav.Link
              eventKey="pregnancy-history"
              as={NavLink}
              to={`/profile/${id}/previouspregnancy/1`}
              exact
            >
              Previous Pregnancies
            </Nav.Link>
            <Nav.Link
              eventKey="ultrasound"
              as={NavLink}
              to={`/profile/${id}/ultrasound/1`}
              exact
            >
              UltraSound
            </Nav.Link>
            <Nav.Link
              eventKey="investigation"
              as={NavLink}
              to={`/profile/${id}/investigation/1`}
              exact
            >
              Investigation
            </Nav.Link>
            <Nav.Link
              eventKey="examination"
              as={NavLink}
              to={`/profile/${id}/examination/1`}
              exact
            >
              Examination
            </Nav.Link>
            <Nav.Link
              eventKey="abdominal_examination"
              as={NavLink}
              to={`/profile/${id}/abdominalexam/1`}
              exact
            >
              Abdominal Examination
            </Nav.Link>
            <Nav.Link
              eventKey="medication"
              as={NavLink}
              to={`/profile/${id}/medication/1`}
              exact
            >
              Medication
            </Nav.Link>
            <Nav.Link
              eventKey="emergency_alert"
              as={NavLink}
              to={`/profile/${id}/emergencyalert/1`}
              exact
            >
              Emergency Alert
            </Nav.Link>
            <Nav.Link
              eventKey="contraception_history"
              as={NavLink}
              to={`/profile/${id}/contraceptionhistory/1`}
              exact
            >
              Contraception History
            </Nav.Link>
            <Nav.Link
              eventKey="medical_history"
              as={NavLink}
              to={`/profile/${id}/medicalhistory/1`}
              exact
            >
              Medical/Surgical History
            </Nav.Link>
            <Nav.Link
              eventKey="labour"
              as={NavLink}
              to={`/profile/${id}/labour/1`}
              exact
            >
              Labour And Delivery
            </Nav.Link>
            <Nav.Link
              eventKey="post_natal"
              as={NavLink}
              to={`${url}/postnatal/1`}
              exact
            >
              Post Natal
            </Nav.Link>
          </Nav>
        </Tab.Container>
        <Tab.Content>
          <Switch>
            <Route
              exact
              path={"/profile/:id"}
              render={() => <PatientProfile profileInfo={profileInfo} />}
            />
            <Route exact path={`${path}/dailysymptoms/:trimester/:pageNum`}>
              <DailySymptoms />
            </Route>
            <Route exact path={`${path}/previouspregnancy/:pageNum`}>
              <PregnancyHistory />
            </Route>
            <Route exact path={`${path}/ultrasound/:pageNum`}>
              <UltraSound />
            </Route>
            <Route exact path={`${path}/investigation/:pageNum`}>
              <Investigation />
            </Route>
            <Route exact path={`${path}/examination/:pageNum`}>
              <Examination />
            </Route>
            <Route exact path={`${path}/abdominalexam/:pageNum`}>
              <AbdominalExam />
            </Route>
            <Route exact path={`${path}/medication/:pageNum`}>
              <Medication />
            </Route>
            <Route exact path={`${path}/emergencyalert/:pageNum`}>
              <EmergencyAlert />
            </Route>
            <Route exact path={`${path}/contraceptionhistory/:pageNum`}>
              <Contraception />
            </Route>
            <Route exact path={`${path}/medicalhistory/:pageNum`}>
              <MedicalHistory />
            </Route>
            <Route exact path={`${path}/labour/:pageNum`}>
              <Labour />
            </Route>
            <Route exact path={`${path}/postnatal/:pageNum`}>
              <PostNatal />
            </Route>
          </Switch>
        </Tab.Content>
      </Router>
    </div>
  );
};

export default Profile;
