import { combineReducers } from "@reduxjs/toolkit";
import materialReducer from "./materials";
import palikaReducer from "./palika";
import reportReducer from "./report";
import uiReducer from "./ui";
import userReducer from "./users";

const rootReducer = combineReducers({
  users: userReducer,
  ui: uiReducer,
  palika: palikaReducer,
  materials: materialReducer,
  reports: reportReducer,
});

export default rootReducer;
