import React from 'react';
import { useMemo } from 'react';
import { VscLoading } from 'react-icons/vsc';

const Loading = ({ size, color }) => {
	const style = useMemo(() => {
		return {
			fontSize: size ? size : '1rem',
			color: color ? color : '#333',
		};
	}, [size, color]);
	return (
		<div>
			<VscLoading style={style} />
		</div>
	);
};

export default Loading;
