import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { BSToAD } from "../../components/InputField/DatePicker/AdToBsConverter/AdBsConverter";

const transformData = (d) => {
  const dateMap = {};
  const data = Object.entries(d?.patient)
    .filter((item) =>
      ["symptoms", "postnatals", "terminations"].includes(item[0])
    )
    .map((item) => item[1])
    .flat()
    .map((item) => item?.postedAt);

  let startDate = new Date(BSToAD(d?.filter?.from));
  let endDate = new Date(BSToAD(d?.filter?.to));

  const generateDateRange = () => {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };
  data.forEach((date) => {
    const dateStr = BSToAD(date).replace(/\//g, "-");
    if (!dateMap[dateStr]) {
      dateMap[dateStr] = 0;
    }
    dateMap[dateStr]++;
  });

  const allDates = generateDateRange();

  console.log("alldates", allDates, dateMap);

  const series = [
    {
      name: "Daily Logs",
      data: allDates.map((date) => {
        return {
          x: date,
          y: dateMap[date] || 0,
        };
      }),
    },
  ];

  return series;
};

const LogsHeatMap = () => {
  const { patientLogHeatmap } = useSelector((state) => state.reports);

  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (patientLogHeatmap) {
      const transformedData = transformData(patientLogHeatmap);
      setSeries(transformedData);
    }
  }, [patientLogHeatmap]);

  const options = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#e0e0e0",
              name: "No Activity",
            },
            {
              from: 1,
              to: 2,
              color: "#90ee7e",
              name: "Low Activity",
            },
            {
              from: 3,
              to: 5,
              color: "#58924c",
              name: "Medium Activity",
            },
            {
              from: 6,
              to: 10,
              color: "#2b4427",
              name: "High Activity",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    title: {
      text: `${patientLogHeatmap?.patient?.name} Daily Logs Heatmap`,
    },
  };

  if (!patientLogHeatmap) {
    return <Redirect to="/reports/1" />;
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="heatmap"
        height={window.innerHeight * 0.8}
      />
    </div>
  );
};

export default LogsHeatMap;
