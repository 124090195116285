export function jwtCheck() {
	let userAccess;

	try {
		userAccess = JSON.parse(localStorage.getItem('userAccess'));
	} catch (error) {
		userAccess = null;
	}
	if (userAccess && userAccess.token) {
		return userAccess.token;
	} else {
		return false;
	}
}

export const setToken = (accessToken, refreshToken) => {
	localStorage.setItem('userAccess', JSON.stringify(accessToken));
	localStorage.setItem('userRefresh', JSON.stringify(refreshToken));
	// localStorage.setItem("role", JSON.stringify(role));
};
