/** @jsxRuntime classic */
/** @jsx jsx */
import {
	jsx,
	//  css
} from '@emotion/react';
import { Fragment, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

import AddFCHV from './AddFCHV';
import BackButton from '../../../_elements/BackButton';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import Loading from '../../../components/Loading';
import DeleteModal from '../../../components/DeleteModal';
import { toast } from 'react-toastify';

const FCHV = () => {
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [isLoading, setIsLoading] = useState(false);
	const [fchv, setfchv] = useState({});
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	useEffect(() => {
		const getFCHV = async () => {
			let response = await fetchWrapper.get(`/fchv?page=${page}`);
			setfchv(response);
		};
		try {
			setIsLoading(true);
			getFCHV();
		} catch (error) {}
		setIsLoading(false);
	}, [page]);

	const [showAdd, setShowAdd] = useState(false);
	const [id, setId] = useState(null);
	const [show, setShow] = useState(null);
	const onDelete = async (id) => {
		try {
			setIsLoading(true);
			await fetchWrapper.delete(`/fchv/${id}`);
			let tempfchv = fchv?.results.filter((res) => res._id !== id);
			setfchv((prev) => ({ ...prev, results: tempfchv }));
			toast.success('FCHV deleted successfully');
		} catch (error) {}
		setIsLoading(false);
	};

	const onAdd = (data) => {
		setfchv((prev) => ({ ...prev, results: [data, ...prev.results] }));
	};

	return (
		<Fragment>
			<Row>
				<Col md={12}>
					<BackButton />
				</Col>
			</Row>
			{showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<AddFCHV
							showAdd={showAdd}
							setShowAdd={setShowAdd}
							onAdd={onAdd}
							editId={id}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom">
							<h2>महिला सामुदायिक स्वास्थ्य स्वयंसेविका</h2>
							<Button
								variant="primary"
								onClick={() => {
									setShowAdd(true);
									setId(null);
								}}
							>
								<FaPlusCircle />
								&nbsp; नयाँ FCHV
							</Button>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr>
											<th>नाम</th>
											<th>पालिका</th>
											<th>स्वास्थ्य संस्था</th>
											<th>उमेर</th>
											<th>फोन</th>
											<th>कार्यहरू</th>
										</tr>
									</thead>

									<tbody>
										{isLoading ? (
											<tr>
												<td></td>
												<td></td>
												<td>
													<Loading size="2rem" />
												</td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										) : (
											fchv?.results?.map((volunteer) => (
												<tr key={volunteer?._id}>
													<td>{volunteer?.name}</td>
													<td>{volunteer?.palika?.localBody}</td>
													<td>{volunteer?.healthpost?.name}</td>
													<td>{volunteer?.age}</td>
													<td>{volunteer?.phone}</td>
													<td>
														<Button
															variant="primary"
															onClick={() => {
																setShowAdd(true);
																setId(volunteer?._id);
															}}
														>
															<FaEdit />
														</Button>
														<Button
															variant="danger"
															onClick={() => setShow(volunteer?._id)}
															className="ml-2"
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<DeleteModal
				show={show}
				onHide={() => setShow(false)}
				onDelete={() => onDelete(show)}
			/>
			<PaginationComp
				page={page}
				total={fchv?.totalResults}
				setPage={handleSetPage}
			/>
		</Fragment>
	);
};

export default FCHV;
