import axios from "axios";
import { authHeader } from "./auth-header";
import { setToken } from "./jwt-check";
// import { toast } from 'react-toastify';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // baseURL: 'https://jsonplaceholder.typicode.com/',
  headers: authHeader(),
});

const requestInterceptors = axiosInstance.interceptors.request.use(
  async (config) => {
    // before request is sent
    let token;
    try {
      token = JSON.parse(localStorage.getItem("userAccess"));
    } catch (error) {}
    if (token) {
      // if (new Date(token.expires).valueOf() < Date.now()) {
      //   const refresh = JSON.parse(localStorage.getItem("userRefresh"));
      //   if (refresh && refresh.token) {
      //     let response = await axios({
      //       method: "POST",
      //       url: process.env.REACT_APP_BACKEND_URL + "auth/refresh-tokens",
      //       data: { refreshToken: refresh.token },
      //     });
      //     setToken(response.data.access, response.data.refresh);

      //     return {
      //       ...config,
      //       headers: {
      //         ...config.headers,
      //         Authorization: `Bearer ${response.data.access.token}`,
      //       },
      //     };
      //   } else {
      //     // setToken(null, null);
      //     localStorage.removeItem("userAccess");
      //     localStorage.removeItem("userRefresh");
      //   }
      // }
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token.token}` },
      };
    } else {
      localStorage.removeItem("userAccess");
      localStorage.removeItem("userRefresh");
    }
    return config;
  },
  async (error) => {
    return error;
  }
);

const responseInterceptors = axiosInstance.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    console.log("axios", error?.response?.data);
    if (error?.response?.data?.code == 401) {
      localStorage.removeItem("userAccess");
      localStorage.removeItem("userRefresh");
    }
    return {
      error: true,
      ...error.response.data,
    };
  }
);

// if (error.response.status === 403) {
// 	toast.error('तपाईलाई यस कार्यको अनुमति छैन।');
// 	// return Promise.reject(response);
// }
// if (error.response.status === 401) {
// 	toast.error('माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन |');
// 	// return Promise.reject(response);
// }
// if (error.response.status === 500) {
// 	toast.error('Something went wrong!');
// 	// return Promise.reject(response);
// }

// for removing request interceptors
export const removeReqInterceptors = () => {
  return axiosInstance.interceptors.request.eject(requestInterceptors);
};

//for removing response interceptors
export const removeResInterceptors = () => {
  return axiosInstance.interceptors.response.eject(responseInterceptors);
};
