/** @jsxRuntime classic */
/** @jsx jsx */
import {
	jsx,
	//  css
} from '@emotion/react';
import { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Password from '../../../components/Form/Password';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { ANMValidation } from '../../../_helpers/schema-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from '../../../components/Form/FormError';
import { useSelector } from 'react-redux';

const AddANM = ({ showAdd, setShowAdd, editId, onAdd }) => {
	const [editData, setEditData] = useState(null);
	const user = useSelector((state) => state.users.user);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(ANMValidation(user?.role)),
	});

	const [palikas, setPalikas] = useState({});
	const [healthposts, setHealthPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (editData) {
			setValue('palika', editData?.palika);
			setValue('nhpc', editData?.nhpc);
			setValue('name', editData?.name);
			setValue('post', editData.post);
			setValue('address', editData?.address);
		}
		if (editData && healthposts?.length > 0)
			setValue('healthpost', editData?.healthpost?._id);
	}, [editData, setValue, healthposts]);

	useEffect(() => {
		const getPalikas = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get('/palika?limit=100');
			setPalikas(response);
		};
		const getANMData = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get(`/anm/${editId}`);
			setEditData(response);
		};
		try {
			user?.role === 'admin' && getPalikas();
			if (editId) {
				getANMData();
			}
		} catch (error) {}
		setIsLoading(false);
	}, [editId, user]);
	const watchedPalika = watch('palika');
	const watchedPhone = watch('phone');
	useEffect(() => {
		const getHealthPosts = async () => {
			let response = await fetchWrapper.get(
				`/healthpost?limit=100&${
					watchedPalika
						? `&palika=${watchedPalika}`
						: editData
						? `&palika=${editData?.palika}`
						: ''
				}`
			);
			setHealthPosts(response?.results);
		};
		try {
			user?.role !== 'healthpostUser' && getHealthPosts();
		} catch (error) {}
	}, [watchedPalika, editData, user]);

	const onSubmit = async (body) => {
		try {
			let data = { ...body };
			if (!Boolean(data?.password)) delete data?.password;
			let response = null;
			if (editId) {
				response = await fetchWrapper.patch(`/anm/${editId}`, data);
				if (response?._id) toast.success('ANM Edited Successfully');
			} else {
				response = await fetchWrapper.post('/anm', data);
				if (response?._id) {
					toast.success('ANM Added Successfully');
					await onAdd(response);
				}
			}
			if (response?._id) {
				reset();
				setShowAdd(false);
			}
		} catch (error) {}
	};

	console.log(errors);

	return (
		<Form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
			<Card>
				<Card.Header className="card-header-custom">
					<h3>
						{editData ? 'प्रयोगकर्ता सम्पादन गर्नुहोस्' : ' नयाँ प्रयोगकर्ता'}
					</h3>
				</Card.Header>
				<Card.Body>
					<Row>
						{user?.role === 'admin' && (
							<Form.Group as={Col} md={4}>
								<Form.Label>पालिका</Form.Label>
								<Form.Control
									as="select"
									{...register('palika')}
									defaultValue={editData && editData?.palika}
									size="sm"
								>
									<option value="" disabled selected>
										--पालिका छान्नुहोस्--
									</option>
									{palikas?.results?.map((palika) => (
										<option key={palika._id} value={palika._id}>
											{palika.localBody}
										</option>
									))}
									<FormError error={errors && errors?.palika} />
								</Form.Control>
							</Form.Group>
						)}
						{user?.role !== 'healthpostUser' && (
							<Form.Group as={Col} md={4}>
								<Form.Label>स्वास्थ्य संस्था</Form.Label>
								<Form.Control as="select" {...register('healthpost')} size="sm">
									<option value="">--स्वास्थ्य संस्था छान्नुहोस्--</option>
									{healthposts?.map((healthpost) => (
										<option key={healthpost._id} value={healthpost._id}>
											{healthpost.name}
										</option>
									))}
									<FormError error={errors && errors?.healthpost} />
								</Form.Control>
							</Form.Group>
						)}
						<Form.Group as={Col} md={4}>
							<Form.Label>स्वास्थ्यकर्मी को नाम</Form.Label>
							<Form.Control
								type="text"
								{...register('name')}
								defaultValue={editData && editData?.name}
								placeholder="स्वास्थ्यकर्मी को नाम लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.name} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Post</Form.Label>
							<Form.Control
								// type="text"
								as="select"
								{...register('post')}
								defaultValue={editData && editData?.post}
								placeholder="Post लेख्नुहोस्"
							>
								<option value="स्वास्थ्य संस्था प्रमुख">
									स्वास्थ्य संस्था प्रमुख
								</option>
								<option value="जन स्वास्थ्य निरीक्षक">
									जन स्वास्थ्य निरीक्षक
								</option>
								<option value="हेल्थ असिस्टेंट">हेल्थ असिस्टेंट</option>
								<option value="स्टाफ नर्स">स्टाफ नर्स</option>
								<option value="सि अ हे व">सि अ हे व</option>
								<option value="सि अ न मी">सि अ न मी</option>
								<option value="अ हे व">अ हे व</option>
								<option value="अ न मि">अ न मि</option>
								<option value="अन्य स्वास्थ्यकर्मी">अन्य स्वास्थ्यकर्मी</option>
							</Form.Control>
							<FormError error={errors && errors?.post} />
						</Form.Group>
						{/* <Form.Group as={Col} md={4}>
							<Form.Label>उमेर</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								{...register('age')}
								defaultValue={editData && editData?.age}
								placeholder="उमेर"
							/>
							<FormError error={errors && errors?.age} />
						</Form.Group> */}
						<Form.Group as={Col} md={4}>
							<Form.Label>NMC/NNC/NHPC नं.</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								{...register('nhpc')}
								defaultValue={editData && editData?.nhpc}
								// disabled={editId}
								placeholder="दर्ता नं. लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.nhpc} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>फोन नं.</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								{...register('phone')}
								defaultValue={editData && editData?.user?.phone}
								placeholder="फोन नं. लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.phone} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>ठेगाना</Form.Label>
							<Form.Control
								type="text"
								{...register('address')}
								defaultValue={editData && editData?.address}
								placeholder="ठेगाना लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.address} />
						</Form.Group>
					</Row>
					{/* {!editId && ( */}
					<Card.Header className="card-header-custom px-0">
						<h3>लग इन विवरण</h3>
					</Card.Header>

					<Row>
						<Form.Group as={Col} md={4}>
							<Form.Label>Username</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								disabled
								value={watchedPhone || editData?.user?.phone}
								placeholder="Username"
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>पासवर्ड</Form.Label>
							<Password
								register={register}
								id="password"
								placeholder="पासवर्ड लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.password} />
						</Form.Group>
					</Row>
					{/* )} */}
				</Card.Body>

				<Card.Footer className="d-flex justify-content-end align-items-center">
					<Button
						variant="secondary"
						className="mr-2"
						onClick={() => {
							setShowAdd(!showAdd);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button type="submit" variant="success" disabled={isLoading}>
						पेश गर्नुहोस्
					</Button>
				</Card.Footer>
			</Card>
		</Form>
	);
};

export default AddANM;
