import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError } from "./ui";

const initialState = {
  patientLogs: [],
  isLoading: false,
  totalResults: 0,
  patientLogHeatmap: null,
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPatientLogs: (state, action) => {
      state.patientLogs = action.payload.results;
      state.totalResults = action.payload.totalResults;
    },
    setPatientLogHeatmap: (state, action) => {
      state.patientLogHeatmap = action.payload;
    },
  },
});

const { setLoading, setPatientLogs, setPatientLogHeatmap } =
  reportSlice.actions;

export default reportSlice.reducer;

export const getPatientLogs = (query) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/patient/log${query}`);
    dispatch(setPatientLogs(response));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setUiError(error.message));
    dispatch(setLoading(false));
  }
};

export const getPatientLogHeatmap = (data) => async (dispatch) => {
  try {
    dispatch(setPatientLogHeatmap(data));
  } catch (error) {
    dispatch(setUiError(error.message));
  }
};
