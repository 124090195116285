/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Dropdown } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { logoutUser } from '../_redux/slices/users';
import { useState } from 'react';
import ChangePasswordModal from './ChangePasswordModal';
import { Link } from 'react-router-dom';

const Header = ({ logoutUser }) => {
	const user = useSelector((state) => state.users.user);

	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const handleShowChangePasswordModal = () => {
		setShowChangePasswordModal(true);
	};
	const handleCloseChangePasswordModal = () => {
		setShowChangePasswordModal(false);
	};

	return (
		<div className="header">
			<div className="header-inner">
				<div className="header-left d-flex align-items-center">
					<h4 className="text-white">
						<span>
							Mobile Phone Based Application & Database For Pregnant Women In
							Rural Nepal
						</span>
					</h4>
				</div>
				<div className="header-right">
					<img
						src="/assets/images/avatar.png"
						className="header-avatar"
						alt=""
					/>

					{user && (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								line-height: 1;
								.role {
									font-size: 12px;
									line-height: 1;
								}
							`}
						>
							<p>{user?.profile?.name || user?.phone}</p>
							<p className="role">{getUserRole(user?.type || user?.role)}</p>
						</div>
					)}

					<Dropdown>
						<Dropdown.Toggle
							variant="primary"
							id="dropdown-basic"
							css={css`
								&.dropdown-toggle {
									color: white !important;
								}
							`}
						></Dropdown.Toggle>

						<Dropdown.Menu
							css={css`
								transform: translate(-155px, 40px) !important;
							`}
						>
							<Link
								css={css`
									display: block;
									width: 100%;
									padding: 0.25rem 1.5rem;
									clear: both;
									font-weight: 400;
									color: #212529;
									text-align: inherit;
									white-space: nowrap;
									background-color: transparent;
									border: 0;
									:hover {
										color: #16181b;
										text-decoration: none;
										background-color: #f8f9fa;
									}
								`}
								to="/settings/profile"
							>
								मेरो प्रोफाइल
							</Link>
							<Dropdown.Item
								onClick={() => {
									handleShowChangePasswordModal();
								}}
							>
								पासवर्ड परिवर्तन गर्नुहोस्
							</Dropdown.Item>
							<Dropdown.Item onClick={() => logoutUser()}>लगआउट</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					{/* <h5>
						
						{language === 'english' ? 'English' : 'नेपाली'}
					</h5> */}
				</div>
				{showChangePasswordModal && (
					<ChangePasswordModal
						show={showChangePasswordModal}
						handleCloseChangePasswordModal={handleCloseChangePasswordModal}
						showChangePasswordModal={showChangePasswordModal}
					/>
				)}
			</div>
		</div>
	);
};

export default connect(null, { logoutUser })(Header);

function getUserRole(role) {
	switch (role) {
		case 'healthpostUser':
			return 'स्वास्थ्यसंस्था प्रयोगकर्ता';
		case 'palikaUser':
			return 'पालिका प्रयोगकर्ता';
		default:
			return role;
	}
}
