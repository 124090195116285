/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { Fragment, useState, useEffect } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import BackButton from "../../../_elements/BackButton";
import PaginationComp from "../../../components/Pagination";
import { useHistory, useParams } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getMaterials, deleteMaterial } from "../../../_redux/slices/materials";
import { createMarkup } from "../../../_helpers/methods";
import DeleteModal from "../../../components/DeleteModal";
import { toast } from "react-toastify";

const Patient = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    dispatch(getMaterials([["page", page]]));
  }, [dispatch, page]);
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  const onDelete = async (id) => {
    dispatch(deleteMaterial(id));
    toast.success("Material deleted successfully");
  };

  return (
    <Fragment>
      <BackButton />

      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom">
              <h2>सामग्रीहरु</h2>
              {user?.role === "admin" && (
                <div>
                  <Button
                    variant="primary"
                    className="mr-2"
                    onClick={() => history.push("/materials/add/article")}
                  >
                    <FaPlusCircle />
                    &nbsp; नयाँ Article
                  </Button>
                  <Button
                    variant="primary"
                    className="mr-2"
                    onClick={() => history.push("/materials/add/video")}
                  >
                    <FaPlusCircle />
                    &nbsp; नयाँ Video
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => history.push("/materials/add/audio")}
                  >
                    <FaPlusCircle />
                    &nbsp; नयाँ Audio
                  </Button>
                </div>
              )}
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "6%" }}>S.N.</th>
                      <th>Title</th>
                      <th style={{ width: "50%" }}>Description</th>
                      <th style={{ width: "10%" }}>Type</th>
                      {user?.role === "admin" && (
                        <th style={{ width: "15%" }}>कार्य</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {materials?.materials?.map((material, i) => (
                      <tr key={material?._id || i}>
                        <td>{page * 10 + i - 9}</td>
                        <td>{material.title}</td>
                        <td>
                          <div
                            style={{ maxHeight: "2.5rem", overflow: "hidden" }}
                          >
                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                material?.description
                              )}
                            ></p>
                          </div>
                        </td>
                        <td>{material.type}</td>
                        {user?.role === "admin" && (
                          <td>
                            <>
                              <Button variant="primary">
                                <FaEye
                                  onClick={() =>
                                    history.push(
                                      `/materials/view/${material._id}`
                                    )
                                  }
                                />
                              </Button>
                              <Button variant="info" className="ml-2">
                                <FaEdit
                                  onClick={() =>
                                    history.push(
                                      `/materials/update/${material.type}/${material._id}`
                                    )
                                  }
                                />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => setShow(material?._id)}
                                className="ml-2"
                              >
                                <FaTrashAlt />
                              </Button>
                            </>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={page}
        total={materials?.count}
        setPage={handleSetPage}
      />
      <DeleteModal
        show={show}
        onHide={() => setShow(false)}
        onDelete={() => onDelete(show)}
      />
    </Fragment>
  );
};

export default Patient;
