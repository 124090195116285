import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import { mediaUrlify } from '../_helpers/_methods';

const StyledUserImage = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 1000px;
	background-color: ${(props) => props.backgroundColor};
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin-right: 0.1rem; */

	&.square {
		border-radius: 0;
	}
	&.rounded_square {
		border-radius: 5px;
	}

	&.md {
		width: 60px;
		height: 60px;
	}
	&.lg {
		width: 80px;
		height: 80px;
	}

	.userProfileImg {
		object-fit: cover;
		width: 100%;
		height: 100%;
		background: white;
	}
	.lettersDiv {
		color: white;
	}
	&.md .lettersDiv {
		font-size: 1.8rem;
	}
	&.lg .lettersDiv {
		font-size: 4.5rem;
	}
	&.count {
		color: white;
		background: #2c3e50;
	}
`;

function UserImage({ userName, avatar, style, userId, type, size, count }) {
	return (
		<>
			<StyledUserImage
				className={`${type} ${size} ${count ? 'count' : ''}`}
				style={style}
				backgroundColor={getRandomColor(userId)}
			>
				{count ? (
					count
				) : avatar ? (
					<img src={avatar} alt={userName} className="userProfileImg" />
				) : (
					<div className="lettersDiv">{getInitials(userName)}</div>
				)}
			</StyledUserImage>
		</>
	);
}

export default UserImage;

UserImage.defaultProps = {
	userName: 'Sushil Bajracharya',
	avatar: null,
	userId: '123123f',
	type: 'round',
	size: 'sm',
};

UserImage.propTypes = {
	/**
	 * Username to generate Inital Letters.
	 */
	userName: PropTypes.string,
	/**
	 * your profile image
	 */
	avatar: PropTypes.string,
	/**
	 * UserId for generating color
	 */
	userId: PropTypes.string,
	/**
	 * How large should the user image be?
	 */
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	/**
	 * border of user image
	 */
	type: PropTypes.string,
};

export const getInitials = (name) => {
	if (!name) return null;
	var nameArray = name.split(' ');

	if (nameArray.length <= 1) {
		return name.charAt(0).toUpperCase();
	}

	return nameArray[0].charAt(0).toUpperCase() + nameArray[nameArray.length - 1].charAt(0).toUpperCase();
};
const getRandomColor = (id) => {
	if (id) {
		const lastChar = id.slice(-1);
		return colors[lastChar];
	} else {
		return null;
	}
};

var colors = {
	a: '#1abc9c',
	b: '#f1c40f',
	c: '#3498db',
	d: '#9b59b6',
	e: '#34495e',
	f: '#16a085',
	0: '#27ae60',
	1: '#2980b9',
	2: '#7f8c8d',
	3: '#2c3e50',
	4: '#21878A',
	5: '#e67e22',
	6: '#e74c3c',
	7: '#99B523',
	8: '#f39c12',
	9: '#5E44D2',
};
