/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormError from '../../../components/Form/FormError';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { toast } from 'react-toastify';
import { FCHVValidation } from '../../../_helpers/schema-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

const AddFCHV = ({ showAdd, setShowAdd, documentDetail, editId, onAdd }) => {
	const [palikas, setPalikas] = useState({});
	const [healthposts, setHealthPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [editData, setEditData] = useState(null);
	const user = useSelector((state) => state.users.user);

	const {
		register,
		reset,
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(FCHVValidation(user?.role)),
	});

	useEffect(() => {
		if (editData) {
			setValue('name', editData?.name);
			setValue('palika', editData?.palika);
			setValue('healthpost', editData?.healthpost);
			setValue('age', editData?.age);
			setValue('address', editData?.address);
			setValue('phone', editData?.phone);
		}
	}, [editData, setValue]);

	useEffect(() => {
		const getPalikas = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get('/palika?limit=100');
			setPalikas(response);
		};

		const getFCHV = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get(`/fchv/${editId}`);
			setEditData(response);
		};
		try {
			user?.role === 'admin' && getPalikas();
			if (editId) {
				getFCHV();
			}
		} catch (error) {}
		setIsLoading(false);
	}, [editId, user]);

	const watchedPalika = watch('palika');
	console.log(errors);

	useEffect(() => {
		const getHealthPosts = async () => {
			let response = await fetchWrapper.get(
				`/healthpost?limit=100&${
					watchedPalika ? `&palika=${watchedPalika}` : ''
				}`
			);
			//eslint-disable-next-line
			setHealthPosts(response?.results);
		};
		try {
			user?.role !== 'healthpostUser' && getHealthPosts();
		} catch (error) {}
	}, [watchedPalika, user]);

	const onSubmit = async (data) => {
		try {
			let response = null;
			if (editId) {
				response = await fetchWrapper.patch(`/fchv/${editId}`, data);
				if (response?._id) toast.success('FCHV Edited Successfully');
			} else {
				response = await fetchWrapper.post('/fchv', data);
				if (response?._id) toast.success('FCHV Added Successfully');
				await onAdd(response);
			}
			if (response?._id) {
				reset();
				setShowAdd(false);
			}
		} catch (error) {}
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Card>
				<Card.Header className="card-header-custom">
					<h3>
						{documentDetail?.eventType === 'edit'
							? 'FCHV सम्पादन गर्नुहोस्'
							: ' नयाँ FCHV'}
					</h3>
				</Card.Header>
				<Card.Body>
					<Row>
						{user?.role === 'admin' && (
							<Form.Group as={Col} md={4}>
								<Form.Label>पालिका</Form.Label>
								<Form.Control
									as="select"
									{...register('palika')}
									defaultValue={editData && editData?.palika}
									size="sm"
								>
									<option value="">--पालिका छान्नुहोस्--</option>
									{palikas?.results?.map((palika) => (
										// eslint-disable-next-line
										<option
											key={palika._id}
											value={palika._id}
											selected={palika._id == editData?.palika}
										>
											{palika.localBody}
										</option>
									))}
									<FormError error={errors && errors?.palika} />
								</Form.Control>
							</Form.Group>
						)}
						{user?.role !== 'healthpostUser' && (
							<Form.Group as={Col} md={4}>
								<Form.Label>स्वास्थ्य संस्था</Form.Label>
								<Form.Control
									as="select"
									{...register('healthpost')}
									defaultValue={editData && editData?.healthpost}
									size="sm"
									// disabled={editId}
								>
									<option value="">--स्वास्थ्य संस्था छान्नुहोस्--</option>
									{healthposts?.map((healthpost) => (
										// eslint-disable-next-line
										<option
											key={healthpost._id}
											value={healthpost._id}
											selected={healthpost._id == editData?.healthpost}
										>
											{healthpost.name}
										</option>
									))}
									<FormError error={errors && errors?.healthpost} />
								</Form.Control>
							</Form.Group>
						)}
						<Form.Group as={Col} md={4}>
							<Form.Label className="mb-0">नाम</Form.Label>
							<Form.Control
								type="text"
								{...register('name')}
								defaultValue={editData && editData?.name}
								placeholder="नाम लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.name} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label className="mb-0">उमेर</Form.Label>
							<Form.Control
								type="number"
								className="remove-step"
								{...register('age')}
								defaultValue={editData && editData?.age}
								placeholder="उमेर लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.age} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label className="mb-0">ठेगाना</Form.Label>
							<Form.Control
								type="text"
								{...register('address')}
								defaultValue={editData && editData?.address}
								placeholder="ठेगाना लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.address} />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label className="mb-0">फोन</Form.Label>
							<Form.Control
								type="text"
								className="remove-step"
								{...register('phone')}
								defaultValue={editData && editData?.phone}
								placeholder="फोन नं. लेख्नुहोस्"
							/>
							<FormError error={errors && errors?.phone} />
						</Form.Group>
					</Row>
				</Card.Body>

				<Card.Footer className="d-flex justify-content-end align-items-center">
					<Button
						variant="secondary"
						className="mr-2"
						onClick={() => {
							reset();
							setShowAdd(!showAdd);
						}}
						disabled={false}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button type="submit" variant="success" disabled={isLoading}>
						पेश गर्नुहोस्
					</Button>
				</Card.Footer>
			</Card>
		</Form>
	);
};

export default AddFCHV;
