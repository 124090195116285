import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const initialState = {
  isLoading: false,
  materials: [],
  material: null,
  count: 0,
};

const materialSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMaterials: (state, action) => {
      state.materials = action.payload.results;
      state.count = action.payload.totalResults;
    },
    addMaterial: (state, action) => {
      state.materials = [action.payload, ...state.materials];
      state.count = ++state.count;
    },
    removeMaterial: (state, action) => {
      let materials = state.materials.filter(
        (material) => material?._id !== action.payload
      );
      state.materials = materials;
      state.count = state.count - 1;
    },
    setMaterial: (state, action) => {
      state.material = action.payload;
    },
    editMaterials: (state, action) => {
      let index = state.materials.findIndex(
        (material) => material._id === action.payload._id
      );
      state.materials[index] = action.payload;
    },
  },
});

const {
  setMaterials,
  addMaterial,
  removeMaterial,
  setMaterial,
  editMaterials,
} = materialSlice.actions;

export default materialSlice.reducer;

export const getMaterials = (queries) => async (dispatch) => {
  const query = queries
    .map((q, i) => {
      if (i === 0) return "?" + q.join("=");
      else return q.join("=");
    })
    .join("&");
  const response = await fetchWrapper.get(
    // `/explore${type ? `?type=${type}` : ""}`
    `/explore${query}`
  );
  dispatch(setMaterials(response));
};

export const addMaterials = (data) => async (dispatch) => {
  const response = await fetchWrapper.post("/explore", data);
  dispatch(addMaterial(response));
};

export const deleteMaterial = (id) => async (dispatch) => {
  await fetchWrapper.delete(`/explore/${id}`);
  dispatch(removeMaterial(id));
};

export const getSingleMaterial = (id) => async (dispatch) => {
  const response = await fetchWrapper.get(`/explore/${id}`);
  dispatch(setMaterial(response));
};

export const editMaterial = (id, data) => async (dispatch) => {
  const response = await fetchWrapper.patch(`/explore/${id}`, data);
  dispatch(editMaterials(response));
};
