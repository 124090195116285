const threshold = {
  FirstTrimester: {
    nausea: 6,
    vomiting: 3,
    decreasedAppetite: 6,
    abdominalDistension: 7,
    upperAbdominalPain: 4,
    constipation: 8,
    Diarrhea: true,
    fever: true,
    headache: 4,
    backPain: 8,
    dizziness: 4,
    cough: 6,
    shortnessOfBreath: 7,
    burningUrination: 5,
    decreasedUrineOutput: true,
    lowerAbdominalPain: {
      continuous: 6,
      episodic: 6,
    },
    PVSpotting: true,
    PVBleeding: true,
    PVDischarge: 6,
    PVDischargeFoulSmelling: true,
    PVShow: true,
    sleep: 6,
    peace: ["अलि बढ़ी", "वास्तवमा धेरै नै"],
    stress: ["धेरै-जसो", "अत्यधिक मात्रामा"],
    sadness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    happiness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    selfHarm: "छ",
  },
  SecondTrimester: {
    nausea: 6,
    vomiting: 3,
    decreasedAppetite: 6,
    abdominalDistension: 7,
    upperAbdominalPain: 6,
    constipation: 8,
    Diarrhea: true,
    jaundice: 3,
    fever: true,
    headache: 6,
    backPain: 8,
    dizziness: 4,
    cough: 6,
    shortnessOfBreath: 7,
    palpitation: 5,
    burningUrination: 5,
    decreasedUrineOutput: true,
    blurringOfVision: true,
    swelling: {
      face: 3,
      feet: 8,
      wholeBody: 4,
    },
    convulsions: true,
    engorgedBreast: 6,
    lowerAbdominalPain: {
      continuous: 6,
      episodic: 6,
    },
    decreasedFetalMovement: true,
    PVSpotting: true,
    PVBleeding: true,
    PVDischarge: true,
    PVDischargeFoulSmelling: true,
    PVShow: true,
    waterBreak: true,
    sleep: 6,
    peace: ["अलि बढ़ी", "वास्तवमा धेरै नै"],
    stress: ["धेरै-जसो", "अत्यधिक मात्रामा"],
    sadness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    happiness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    selfHarm: "छ",
  },
  ThirdTrimester: {
    nausea: 6,
    vomiting: 3,
    decreasedAppetite: 6,
    abdominalDistension: 7,
    upperAbdominalPain: 6,
    constipation: 8,
    Diarrhea: true,
    jaundice: 3,
    fever: true,
    headache: 6,
    backPain: 8,
    dizziness: 4,
    cough: 6,
    shortnessOfBreath: 7,
    palpitation: 5,
    burningUrination: 5,
    decreasedUrineOutput: true,
    blurringOfVision: true,
    swelling: {
      face: 3,
      feet: 8,
      wholeBody: 4,
    },
    convulsions: true,
    engorgedBreast: 6,
    lowerAbdominalPain: {
      continuous: 6,
      episodic: 6,
    },
    decreasedFetalMovement: true,
    PVSpotting: true,
    PVBleeding: true,
    PVDischarge: true,
    PVDischargeFoulSmelling: true,
    PVShow: true,
    waterBreak: true,
    labor: "छ",
    sleep: 6,
    peace: ["अलि बढ़ी", "वास्तवमा धेरै नै"],
    stress: ["धेरै-जसो", "अत्यधिक मात्रामा"],
    sadness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    happiness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    selfHarm: "छ",
  },

  postNatalMother: {
    nausea: 6,
    vomiting: 3,
    abdominalPain: 7,
    fever: true,
    abdominalDistension: 8,
    PVBleeding: true,
    PVDischarge: true,
    PVDischargeFoulSmelling: true,
    headache: 8,
    jaundice: 3,
    swelling: {
      face: 3,
      feet: 8,
      wholeBody: 4,
    },
    blurringOfVision: true,
    convulsions: true,
    shortnessOfBreath: 7,
    cough: 7,
    burningUrination: 3,
    diarrhea: true,
    constipation: 8,
    decreasedUrineOutput: true,
    engorgedBreast: 6,
    breastFeeding: false,
    sleep: 6,
    appetite: "कत्ति पनि रुची छैन",
    canTakeCareOFBaby: "हो",
    stressedAboutBaby: ["धेरै-जसो", "अत्यधिक मात्रामा"],
    selfOrBabyHarm: "छ",
    happiness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    sadness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
  },
  postNatalBaby: {
    isSuckingWell: false,
    isFeedingWell: false,
    ExcessiveCrying: true,
    Lethargic: true,
    Difficultbreathing: true,
    IncreasedBreathing: true,
    NoBreathing: true,
    Fever: true,
    YellowishDiscoloration: true,
    DistendedAbdomen: true,
    Vomiting: true,
    AbnormalStools: true,
    CryingDuringMicturation: true,
    Hypothermia: true,
    AbnormalBodyMovements: true,
    Cyanosis: true,
    SkinRash: true,
  },
  termination: {
    lowerAbdominalPain: {
      continuous: 6,
      episodic: 6,
    },
    PVBleeding: ["अत्याधिक मात्रामा"],
    backPain: 8,
    engorgedBreast: 6,
    burningUrination: 5,
    decreasedUrineOutput: true,
    PVDischargeFoulSmelling: true,
    fever: true,
    dizziness: 4,
    sleep: 6,
    peace: ["अलि बढ़ी", "वास्तवमा धेरै नै"],
    stress: ["धेरै-जसो", "अत्यधिक मात्रामा"],
    sadness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    happiness: ["धेरै-जसो हुन्छ", "सधैंजसो हुन्छ"],
    selfHarm: "छ",
  },
};

export default threshold;
