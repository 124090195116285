import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
	FaAmbulance,
	FaBuilding,
	FaStethoscope,
	FaUserAlt,
	FaUserNurse,
} from 'react-icons/fa';
import { GiNurseFemale } from 'react-icons/gi';
import { RiGovernmentFill, RiArticleFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { englishToNepaliNumber as np } from 'nepali-number';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { checkPermission } from '../../_helpers/roles/check-roles';
import { useSelector } from 'react-redux';

const Settings = () => {
	const [stats, setStats] = useState({});
	const user = useSelector((state) => state.users.user);
	useEffect(() => {
		const getStats = async () => {
			let response = await fetchWrapper.get('stats');
			setStats(response);
		};
		try {
			getStats();
		} catch (error) {}
	}, []);

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom d-block">
							<h2 className="text-center">सेटिङ्स</h2>
						</Card.Header>
					</Card>
				</Col>

				{checkPermission(user?.role, 'view:palika') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/palika/1">
								<span className="settings-badge position-absolute">
									{np(stats?.palika || 0)}
								</span>
								<div className="icon">
									<RiGovernmentFill size={48} />
									<h6 className="card-text mt-3">पालिका</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}

				{checkPermission(user?.role, 'view:healthpost') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/healthpost/1">
								<span className="settings-badge position-absolute">
									{np(stats?.healthpost || 0)}
								</span>
								<div className="icon">
									<FaBuilding size={48} />
									<h6 className="card-text mt-3">स्वास्थ्य संस्था</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}

				{checkPermission(user?.role, 'view:anm') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/anm/1">
								<span className="settings-badge position-absolute">
									{np(stats?.anm || 0)}
								</span>
								<div className="icon">
									<GiNurseFemale size={48} />
									<h6 className="card-text mt-3">स्वास्थ्यकर्मी</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}
				{checkPermission(user?.role, 'view:doctor') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/doctor/1">
								<span className="settings-badge position-absolute">
									{np(stats?.doctor || 0)}
								</span>
								<div className="icon">
									<FaStethoscope size={48} />
									<h6 className="card-text mt-3">डाक्टर</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}
				{checkPermission(user?.role, 'view:help') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/ambulance/1">
								<span className="settings-badge position-absolute">
									{np(stats?.ambulance || 0)}
								</span>
								<div className="icon">
									<FaAmbulance size={48} />
									<h6 className="card-text mt-3">Help</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}

				{checkPermission(user?.role, 'view:fchv') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/fchv/1">
								<span className="settings-badge position-absolute">
									{np(stats?.fchv || 0)}
								</span>
								<div className="icon">
									<FaUserNurse size={48} />
									<h6 className="card-text mt-3">FCHV</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}
				{checkPermission(user?.role, 'view:patient') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/patient/1">
								<span className="settings-badge position-absolute">
									{np(stats?.patient || 0)}
								</span>
								<div className="icon">
									<FaUserAlt size={48} />
									<h6 className="card-text mt-3">गर्भवती/सुत्केरी महिला</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}

				{checkPermission(user?.role, 'view:materials') && (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/materials/1">
								<span className="settings-badge position-absolute">
									{np(stats?.explore || 0)}
								</span>
								<div className="icon">
									<RiArticleFill size={48} />
									<h6 className="card-text mt-3">सामग्री</h6>
								</div>
							</Link>
						</div>
					</Col>
				)}
			</Row>
		</Fragment>
	);
};

export default Settings;
