/** @jsxRuntime classic */
/** @jsx jsx */
import {
	jsx,
	// css
} from '@emotion/react';
import { Fragment, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import AddAmbulance from './AddAmbulance';
import BackButton from '../../../_elements/BackButton';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import Loading from '../../../components/Loading';
import DeleteModal from '../../../components/DeleteModal';
import { toast } from 'react-toastify';

const Ambulance = ({ match }) => {
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [showAdd, setShowAdd] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [ambulance, setAmbulance] = useState();
	const [editId, setEditId] = useState(null);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	useEffect(() => {
		const getDoctors = async () => {
			let response = await fetchWrapper.get(`/ambulance?page=${pageNum}`);
			setAmbulance(response);
		};
		try {
			setIsLoading(true);
			getDoctors();
		} catch (error) {}
		setIsLoading(false);
	}, [pageNum]);

	const onDelete = async (id) => {
		try {
			await fetchWrapper.delete(`/ambulance/${id}`);
			const tempAmb = ambulance?.results?.filter((ambu) => ambu._id !== id);
			setAmbulance((prev) => ({ ...prev, results: tempAmb }));
			toast.success('Ambulance deleted Successfully');
		} catch (error) {}
	};
	const [show, setShow] = useState(null);

	const onAdd = (data) => {
		setIsLoading(true);
		setAmbulance((prev) => ({ ...prev, results: [data, ...prev.results] }));
		setIsLoading(false);
	};

	return (
		<Fragment>
			<BackButton />
			{showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<AddAmbulance
							showAdd={showAdd}
							setShowAdd={setShowAdd}
							id={editId}
							onAdd={onAdd}
							setAmbulance={setAmbulance}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col md={12}>
					<Card className="px-1">
						<Card.Header className="card-header-custom">
							<h2>Help</h2>
							<Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
								<FaPlusCircle />
								&nbsp; नयाँ Help
							</Button>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr>
											<th>Title</th>
											<th>Info</th>
											<th>फोन</th>
											<th>कार्यहरू</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<tr>
												<td></td>
												<td>
													<Loading size="2rem" />
												</td>
												<td></td>
												<td></td>
											</tr>
										) : (
											ambulance?.results?.map((amb) => (
												<tr key={amb._id}>
													<td>{amb.title}</td>
													<td>{amb.info}</td>
													<td>{amb.phone}</td>
													<td>
														<Button
															variant="primary"
															onClick={() => {
																setEditId(amb._id);
																setShowAdd(true);
															}}
														>
															<FaEdit />
														</Button>
														<Button
															variant="danger"
															onClick={() => setShow(amb._id)}
															className="ml-2"
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PaginationComp
				page={page}
				total={ambulance?.totalResults}
				setPage={handleSetPage}
			/>
			<DeleteModal
				show={show}
				onHide={() => setShow(false)}
				onDelete={() => onDelete(show)}
			/>
		</Fragment>
	);
};

export default Ambulance;
