import React, { useState } from 'react';
import UserImage from './UserImage';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { ageCalculator } from '../../_helpers/methods';
import PaginationComp from '../../components/Pagination';

const ANMProfile = () => {
	const { id } = useParams();
	const [anmProfile, setAnmProfile] = useState(null);
	const [patientsList, setPatientsList] = useState([]);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);

	useEffect(() => {
		const getPatientList = async () => {
			let response = await fetchWrapper.get(`/patient?anm=${id}&page=${page}`);
			setPatientsList(response?.results);
			setCount(response?.totalResults);
		};
		try {
			getPatientList();
		} catch (error) {}
	}, [id, page]);

	useEffect(() => {
		const getAnmProfile = async () => {
			let response = await fetchWrapper.get(`/anm/${id}`);
			setAnmProfile(() => response);
		};
		try {
			getAnmProfile();
		} catch (error) {}
	}, [id]);
	return (
		<div className="profile-container row">
			<div className="profile-card col-md-3 p-3">
				<div className="profile-personal-info">
					<div className="profile-avatar">
						<UserImage
							avatar={null}
							userName={anmProfile?.name}
							userId={anmProfile?._id}
							style={{ width: '100px', height: '100px' }}
							size="md"
							fontSize={100}
						/>
						<h4 className=" gray-100">{anmProfile?.name}</h4>
						<p className="font-20-regular text-primary">
							{anmProfile?.healthpost?.name}
						</p>
					</div>
				</div>
				<hr className="w-100" />
				<div className="personal-info">
					<h5>Personal Detail</h5>
					<p>NHPC: {anmProfile?.nhpc}</p>
					<p>{anmProfile?.name}</p>
					<p>{anmProfile?.address}</p>
					<p>{anmProfile?.user?.phone}</p>
				</div>
				<hr className="w-100" />
				<div className="responsible-person-info">
					<h5>Health Post Detail</h5>
					<p>{anmProfile?.healthpost?.name}</p>
					<p>{anmProfile?.healthpost?.address}</p>
					<p>{anmProfile?.healthpost?.phone}</p>
					<p></p>
				</div>
				<hr className="w-100" />
			</div>
			<div className="col-md-9 recent-activities">
				<h5 className="text-info">Patient List</h5>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Patient ID</th>
								<th>Patient Name</th>
								<th>Contact No.</th>
								<th>Age</th>
								<th colSpan={2}>Responsible Person</th>
							</tr>
						</thead>
						<tbody>
							{patientsList?.map((patient) => (
								<tr>
									<td>{patient?._id}</td>
									<td>
										<span className="week-badge">4</span>
										{patient?.name}
									</td>
									<td>{patient?.user?.phone}</td>
									<td>{ageCalculator(patient?.dateOfBirth)}</td>
									<td colSpan={2}>
										<p>{patient?.responsiblePersonName}</p>
										<p>{patient?.responsiblePersonNumber}</p>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<PaginationComp page={page} setPage={setPage} total={count} />
				</div>
			</div>
		</div>
	);
};

export default ANMProfile;
