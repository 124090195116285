import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
// import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
// import { fetchWrapper } from '../../_helpers/fetchWrapper';

export const SidebarLink = styled(NavLink)`
	display: flex;
	color: #78828a;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 50px;
	letter-spacing: 0.3px;
	text-decoration: none;
	font-weight: 600;
	&:hover {
		background-color: #f5f5f5;
		color: #6677ef;
		opacity: 1;
	}
`;

const Badge = styled.p`
	background-color: red;
	padding: 2px 5px;
	line-height: 1;
	border-radius: 25% / 50%;
	font-size: 12px;
	color: white;
`;

export const Submenu = styled.div`
	background-color: #f5f5f5;
	padding: 0 10px;
`;

export const DropDownLink = styled(NavLink)`
	height: 50px;
	padding-left: 1rem;
	padding-right: 0.5rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #78828a;
	padding-top: 20px;
	padding-bottom: 20px;
	.menu-items {
		padding-left: 1.2rem;
		span {
			font-size: 12px;
		}
	}
	&:hover {
		color: #6677ef;
	}
`;

const SubMenu = ({ item, currentPath, showMenu }) => {
	const isMenuOpen = React.useMemo(() => {
		if (!item.subNav) return false;
		const index = item.subNav.findIndex((nav) => nav.path === currentPath);
		if (index !== -1) return true;
		return false;
	}, [currentPath, item.subNav]);

	const [subnav, setSubnav] = useState(isMenuOpen);
	return (
		<>
			<SidebarLink
				to={item.path ? item.path : ''}
				onClick={() => item.subNav && setSubnav(!subnav)}
				activeClassName="navbar__link--active"
				exact={true}
			>
				<div className="menu-items">
					{item.icon}
					<span>{item.title}</span>
				</div>
				{item?.badge && item?.badge}
				<div className="chevron">
					{item.subNav && subnav
						? item.iconOpened
						: item.subNav
						? item.iconClosed
						: null}
				</div>
			</SidebarLink>
			<Submenu>
				{subnav &&
					item.subNav.map((item, index) => {
						if (item.path) {
							return (
								<DropDownLink
									to={item?.path}
									key={index}
									activeClassName="navbar__link--active"
									exact={true}
								>
									<div className="menu-items" data-tip={item.title}>
										{/* {item.icon} */}
										<span>{item.title}</span>
									</div>
								</DropDownLink>
							);
						}
						return null;
					})}
			</Submenu>
		</>
	);
};

export default SubMenu;
