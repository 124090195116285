import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const Medication = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [medicines, setMedicines] = useState(null);
	useEffect(() => {
		const getMedicines = async () => {
			let response = await fetchWrapper.get(
				`/patient/profile/${id}/medication?page=${page}`
			);
			setMedicines(response);
		};
		try {
			getMedicines();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th className="text-center">Folic Acid</th>
								<th className="text-center">Iron</th>
								<th className="text-center">Calcium</th>
								<th className="text-center">Albendazole</th>
								<th className="text-center">Vitamin A</th>
								<th className="text-center">Tetanus Toxoid</th>
								<th className="text-center">Other Medicines</th>
							</tr>
						</thead>
						<tbody>
							{medicines?.results?.map((medicine) => (
								<tr key={medicine?._id}>
									<td>{medicine?.postedAt}</td>
									<td className="text-center">
										<div>
											<p>
												{(medicine?.formicAcid?.dosage &&
													medicine?.formicAcid?.dosage + 'mg') || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.formicAcid?.medicineTimings || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.formicAcid?.noOfDays || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												days
											</p>
											<i>
												{medicine?.formicAcid?.remarks && (
													<small>
														{medicine?.formicAcid?.remarks &&
															'remarks: ' + medicine?.formicAcid?.remarks}
													</small>
												)}
											</i>
										</div>
									</td>
									<td className="text-center">
										<div>
											<p>
												{(medicine?.iron?.dosage &&
													medicine?.iron?.dosage + 'mg') || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.iron?.medicineTimings || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.iron?.noOfDays || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												days
											</p>
											<i>
												{medicine?.iron?.remarks && (
													<small>
														{medicine?.iron?.remarks &&
															'remarks: ' + medicine?.iron?.remarks}
													</small>
												)}
											</i>
										</div>
									</td>
									<td className="text-center">
										<div>
											<p>
												{(medicine?.calcium?.dosage &&
													medicine?.calcium?.dosage + 'mg') || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.calcium?.medicineTimings || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.calcium?.noOfDays || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												days
											</p>
											<i>
												{medicine?.calcium?.remarks && (
													<small>
														{medicine?.calcium?.remarks &&
															'remarks: ' + medicine?.calcium?.remarks}
													</small>
												)}
											</i>
										</div>
									</td>
									<td className="text-center">
										<div>
											<p>
												{(medicine?.Albendazole?.dosage &&
													medicine?.Albendazole?.dosage + 'mg') || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.Albendazole?.medicineTimings || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.Albendazole?.noOfDays || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												days
											</p>
											<i>
												{medicine?.Albendazole?.remarks && (
													<small>
														{medicine?.Albendazole?.remarks &&
															'remarks: ' + medicine?.Albendazole?.remarks}
													</small>
												)}
											</i>
										</div>
									</td>
									<td className="text-center">
										<div>
											<p>
												{(medicine?.VitaminA?.dosage &&
													medicine?.VitaminA?.dosage + 'IU') || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.VitaminA?.medicineTimings || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												|{' '}
												{medicine?.VitaminA?.noOfDays || (
													<small>
														<i>-</i>
													</small>
												)}{' '}
												days
											</p>
											<i>
												{medicine?.VitaminA?.remarks && (
													<small>
														{medicine?.VitaminA?.remarks &&
															'remarks: ' + medicine?.VitaminA?.remarks}
													</small>
												)}
											</i>
										</div>
									</td>
									<td className="text-center">
										{JSON.stringify(medicine?.tetanusToxoid || {})
											.replace(/[{}"]/g, ' ')
											.split(',')
											.map((t, i) => (
												<p key={i}>{t}</p>
											))}
									</td>

									<td colSpan={2}>
										{medicine?.medicines?.map((med) => (
											<div key={med?._id}>
												<p>
													<strong>{med?.name}</strong> |{' '}
													{med?.dosage ? med?.dosage + 'mg' : '-'}
												</p>
												<p>
													{med?.medicineTimings} | {med?.noOfDays}days
												</p>
												<i>{med?.remarks && <p>{med?.remarks}</p>}</i>
											</div>
										))}
									</td>
								</tr>
							))}
							{/* <tr>
								<td className="text-center">Paracetamol</td>
								<td className="text-center">500mg</td>
								<td className="text-center">5</td>
								<td className="text-center">2</td>
								<td className="text-center">-</td>
								<td>After Meal</td>
								<td>Mary Jane</td>
							</tr> */}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={medicines?.totalResults}
			/>
		</Fragment>
	);
};

export default Medication;
