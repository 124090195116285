import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function BackButton() {
	const history = useHistory();
	return (
		<div className="mb-3">
			<Button onClick={() => history.goBack()}>पछाडी जानुहोस</Button>
		</div>
	);
}

export default BackButton;
