import * as Yup from "yup";
// import { array, object } from "yup/lib/locale";

export const logValidationSchema = Yup.object().shape({
  phone: Yup.string().required("Phone is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export const changePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string()
    .required({ en: "Password is required", np: "हालको पासवर्ड अनिवार्य छ" })
    .min(8, {
      en: "Password must be at least 8 characters",
      np: "पासवर्ड 8 वर्णको हुनुपर्छ",
    }),
  // .matches(/\d/, "Password must contain atleast one number")
  // .matches(/[a-zA-Z]/, "Password must contain atleast one letter")
  password: Yup.string()
    // .matches(/\d/, "Password must contain atleast one number")
    // .matches(/[a-zA-Z]/, "Password must contain atleast one letter")
    .required({ en: "Password is required", np: "नयाँ पासवर्ड अनिवार्य छ" })
    .min(8, {
      en: "Password must be at least 8 characters",
      np: "पासवर्ड 8 वर्णको हुनुपर्छ",
    })
    .notOneOf([Yup.ref("currentPassword"), null], {
      en: "New Password cannot be same as Old Password",
      np: "नयाँ पासवर्ड, हाल पासवर्डभन्दा फरक हुनुपर्छ",
    }),
  confirmPassword: Yup.string()
    .required({
      en: "Confirm Password is required",
      np: "पुन: नयाँ पासवर्ड अनिवार्य छ",
    })
    .min(8, {
      np: "पासवर्ड 8 वर्णको हुनुपर्छ",
      en: "Password must be at least 8 characters",
    })
    .oneOf([Yup.ref("password"), null], {
      en: "Confirm Password must match with New Password",
      np: "नयाँ पासवर्ड समान हुनुपर्छ",
    }),
});

export const PalikaValidation = Yup.object().shape({
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  localBody: Yup.string().required("Local Body is required"),
  noOfWards: Yup.string().required("No of Wards is required"),
  address: Yup.string().required("Address is required"),
  contactNo: Yup.string()
    .required("Contact Number is required")
    .length(10, "Phone number must be 10 digits"),
  contactPerson: Yup.string().required("Contact Person is required"),
  contactPersonDesignation: Yup.string().required(
    "Contact Person Designation is required"
  ),
});

export const PalikaUserValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  post: Yup.string().required("Post is required"),
  address: Yup.string().required("Address is required"),
  // phone: Yup.string().required('Phone is required'),
});
export const HealthPostUserValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  post: Yup.string().required("Post is required"),
  address: Yup.string().required("Address is required"),
  // phone: Yup.string().required('Phone is required'),
});

export const HealthPostValidation = Yup.object().shape({
  palika: Yup.string(),
  // .required('Palika is required'),
  ward: Yup.string().required("Ward is required"),
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .required("Phone is required")
    .length(10, "Phone number must be at least 10 digits"),
  address: Yup.string().required("Address is required"),
  contactPerson: Yup.string().required("Contact Person is required"),
  contactPersonDesignation: Yup.string().required(
    "Contact Person Designation is required"
  ),
  contactPersonPhone: Yup.string().length(
    10,
    "Phone number must be at least 10 digits"
  ),
  noOfSwyamSewak: Yup.string().required("No of Swyam Sewak is required"),
});

export const ANMValidation = (role) =>
  Yup.object().shape({
    palika:
      role === "admin"
        ? Yup.string().required("Palika is required")
        : Yup.string(),
    healthpost:
      role !== "healthpostUser"
        ? Yup.string().required("Health Post is required")
        : Yup.string(),
    name: Yup.string()
      .matches(/^([A-Za-z](\s?))+$/g, "Must contain alphabets only")
      .required("Name is required"),
    nhpc: Yup.string().required("NHPC no. is required"),
    phone: Yup.string().length(10, "Phone number must be at least 10 digits"),
    address: Yup.string().required("Address is required"),
    // password: Yup.string().required('Password is required'),
  });
export const FCHVValidation = (role) =>
  Yup.object().shape({
    palika:
      role === "admin"
        ? Yup.string().required("Palika is required")
        : Yup.string(),
    healthpost:
      role !== "healthpostUser"
        ? Yup.string().required("Health Post is required")
        : Yup.string(),
    name: Yup.string()
      .matches(/^([A-Za-z](\s?))+$/g, "Must contain alphabets only")
      .required("Name is required"),
    age: Yup.string().required("Age is required"),
    phone: Yup.string().length(10, "Phone number must be at least 10 digits"),
    address: Yup.string().required("Address is required"),
    // password: Yup.string().required('Password is required'),
  });

export const DoctorValidation = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^([A-Za-z\.](\s?))+$/g, "Must contain alphabets only"),
  email: Yup.string(),
  phone: Yup.string().length(10, "Phone number must be at least 10 digits"),
  age: Yup.string(),
  specialistField: Yup.string().required("Specialist Field is required"),
  councilNumber: Yup.string().required("Council no. is required"),
  associatedHospitalName: Yup.string().required("Hospital Name is required"),
  // password: Yup.string().required('Password is required'),
});

export const FAQValidation = Yup.object().shape({
  question: Yup.string().required("Question is Required"),
  answer: Yup.string().required("Answer is Required"),
});

export const AmbulanceValidation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  info: Yup.string(),
  phone: Yup.string().length(8, "Phone number must be at least 8 digits"),
});
