import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearUiMessage } from "./_redux/slices/ui";
import { getUserData } from "./_redux/slices/users";
import { PrivateRoute } from "./auth-route";
import ActivityLog from "./pages/ActivityLog/ActivityLog";
import Dashboard from "./pages/Dashboard";
import Palika from "./pages/Settings/Palika";
import Settings from "./pages/Settings/Settings";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import "./sass/style.scss";
// import Ward from './pages/Settings/Ward/Ward';
import Layout from "./layout/Layout";
import Emergency from "./pages/Emergency/Emergency";
import Faq from "./pages/Faq";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import ANMProfile from "./pages/Profile/ANMProfile";
import Report from "./pages/Report";
import LogsHeatMap from "./pages/Report/LogsHeatmap";
import Ambulance from "./pages/Settings/Ambulance/Ambulance";
import Doctor from "./pages/Settings/Doctor/Doctor";
import FCHV from "./pages/Settings/FCHV/FCHV";
import ANM from "./pages/Settings/HealthPost/ANM";
import AddHealthPost from "./pages/Settings/HealthPost/AddHealthPost";
import HealthPost from "./pages/Settings/HealthPost/HealthPost";
import HealthPostUser from "./pages/Settings/HealthPost/HealthPostUser";
import Materials from "./pages/Settings/Materials";
import AddMaterials from "./pages/Settings/Materials/AddMaterials";
import MaterialPreview from "./pages/Settings/Materials/MaterialPreview";
import PalikaUsers from "./pages/Settings/PalikaUsers";
import Patient from "./pages/Settings/Patient";
import Support from "./pages/Support";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

function App({ clearUiMessage, getUserData }) {
  const message = useSelector((state) => state.ui.message);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      getUserData();
    }
  }, [getUserData, isLoggedIn]);

  useEffect(() => {
    if (message) {
      if (message.error === false) {
        toast.success(message.message);
        clearUiMessage();
      } else if (message) {
        toast.error(message.message);
        clearUiMessage();
      } else {
        toast.success(message);
      }
    }
  }, [message, clearUiMessage]);

  const adminRoles = ["admin", "healthpostUser", "palikaUser"];

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password/reset" component={ResetPassword} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        {/* <Route path="/*" render={({ match }) => <RootRoutes basePath={match.path} />} /> */}
        <PrivateRoute
          exact
          path="/"
          component={Dashboard}
          accessGranted={adminRoles}
        />

        <PrivateRoute
          exact
          path="/activitylog/:pageNum"
          component={ActivityLog}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/emergency/:pageNum"
          component={Emergency}
          accessGranted={adminRoles}
        />
        {/* settings */}
        <PrivateRoute
          exact
          path="/settings"
          component={Settings}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/palika/:pageNum"
          component={Palika}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/palika/:pageNum/:id"
          component={PalikaUsers}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/healthpost/:pageNum/:palikaId/:id"
          component={HealthPostUser}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/healthpost/:pageNum"
          component={HealthPost}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/healthpost/create/"
          component={AddHealthPost}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/healthpost/update/:id"
          component={AddHealthPost}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/anm/profile/:id/"
          component={ANMProfile}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/anm/:pageNum"
          component={ANM}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/doctor/:pageNum"
          component={Doctor}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/ambulance/:pageNum"
          component={Ambulance}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/fchv/:pageNum"
          component={FCHV}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/patient/:pageNum"
          component={Patient}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/settings/materials/:pageNum"
          component={Materials}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/materials/add/:type"
          component={AddMaterials}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/materials/update/:type/:id"
          component={AddMaterials}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/materials/view/:id"
          component={MaterialPreview}
          accessGranted={adminRoles}
        />

        <PrivateRoute
          // exact
          path="/profile/:id"
          component={Profile}
          accessGranted={adminRoles}
        />

        <PrivateRoute
          exact
          path="/reports/:pageNum"
          component={Report}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/report/heatmap/:id"
          component={LogsHeatMap}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/faq/:pageNum"
          component={Faq}
          accessGranted={adminRoles}
        />
        <PrivateRoute
          exact
          path="/support"
          component={Support}
          accessGranted={adminRoles}
        />
        <Route path="*" component={NotFound} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default connect(null, {
  clearUiMessage,
  getUserData,
})(App);

const NotFound = () => <Redirect to="/" />;

const RootRoutes = ({ basePath }) => (
  <Layout>
    <PrivateRoute exact path="/" component={Dashboard} />

    <PrivateRoute exact path="/activitylog/:pageNum" component={ActivityLog} />
    <PrivateRoute exact path="/emergency/:pageNum" component={Emergency} />
    {/* settings */}
    <PrivateRoute exact path="/settings" component={Settings} />
    <PrivateRoute exact path="/settings/palika/:pageNum" component={Palika} />
    <PrivateRoute
      exact
      path="/settings/healthpost/:pageNum"
      component={HealthPost}
    />
    <PrivateRoute exact path="/healthpost/create/" component={AddHealthPost} />
    <PrivateRoute
      exact
      path="/healthpost/update/:id"
      component={AddHealthPost}
    />
    <PrivateRoute
      exact
      path="/settings/anm/profile/:id/"
      component={ANMProfile}
    />
    <PrivateRoute exact path="/settings/anm/:pageNum" component={ANM} />
    <PrivateRoute exact path="/settings/doctor/:pageNum" component={Doctor} />
    <PrivateRoute exact path="/settings/fchv/:pageNum" component={FCHV} />
    <PrivateRoute exact path="/settings/patient/:pageNum" component={Patient} />

    <PrivateRoute exact path="/profile/:id" component={Profile} />

    <PrivateRoute exact path="/reports" component={Report} />
    <PrivateRoute exact path="/faq" component={Faq} />
    <PrivateRoute exact path="/support" component={Support} />
  </Layout>
);
