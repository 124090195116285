import React from "react";
import { isEmptyObject } from "../../../_helpers/methods";
import UserImage from "../UserImage";

function weekAndDaysCalculator(daysSinceLastPeriod = 0) {
  const weeks = Math.floor(daysSinceLastPeriod / 7);
  const days = daysSinceLastPeriod % 7;
  let result = "";
  if (weeks > 0) {
    result += `${weeks} weeks `;
  }
  if (days > 0) {
    result += `${days} days`;
  }
  return result;
}

const PatientProfile = ({ profileInfo }) => {
  return (
    <>
      <div className="profile-container row">
        <div className="profile-card col-md-4 p-3">
          <div className="profile-personal-info">
            <div className="profile-avatar">
              <UserImage
                avatar={profileInfo?.image}
                userName={profileInfo?.name}
                userId={profileInfo?._id}
                style={{ width: "100px", height: "100px" }}
                size="md"
                fontSize={100}
              />
              <h4 className=" gray-100">{profileInfo?.name}</h4>
              <p className="font-20-regular text-primary">
                {isEmptyObject(profileInfo?.termination)
                  ? weekAndDaysCalculator(profileInfo?.daysSinceLastPeriod)
                  : profileInfo?.termination?.typeOfTermination}
              </p>
            </div>
          </div>
          <hr className="w-100" />
          <div className="personal-info">
            <h5>व्यक्तिगत विवरण</h5>
            <p>पुरा नाम: {profileInfo?.name}</p>
            <p>जन्म मिति: {profileInfo?.dateOfBirth}</p>
            <p> नागरिकता नं:{profileInfo?.citizenship}</p>
            <p>रास्ट्रिय परिचयपत्र नं: {profileInfo?.rastriyaParichayaPatra}</p>
            <p>सम्पर्क नं: {profileInfo?.user?.phone}</p>
            <p> टोल: {profileInfo?.address}</p>
            <p>विवाहको मिति: {profileInfo?.dateOfMarriage}</p>
          </div>
          <hr className="w-100" />
          <div className="responsible-person-info">
            <h5>जिम्मेवार व्यक्तिको विवरण</h5>
            <p> पुरा नाम: {profileInfo?.responsiblePersonName}</p>
            <p>नाता: {profileInfo?.responsiblePersonRelation}</p>
            <p>सम्पर्क नं: {profileInfo?.responsiblePersonNumber}</p>
          </div>
          <hr className="w-100" />
        </div>
        <div className="col-md-8 profile-card">
          <div className="responsible-person-info">
            <h5>महिनावारी विवरण</h5>
            <p> रक्त समूह: {profileInfo?.Bloodgroup}</p>
            <p>
              पछिल्लो महिनावारीको पहिलो दिन: {profileInfo?.dateOfLastPeroid}
            </p>
            <p> रक्तश्रावको अवधि: {profileInfo?.daysOfBleeding}</p>
            <p> महिनावारी चक्र: {profileInfo?.daysOfCycle}</p>
            <p>Remarks: {profileInfo?.remarks}</p>
          </div>
          <hr className="w-100" />

          <div className="responsible-person-info">
            <h5>स्वास्थ्य चौकी विवरण</h5>
            <p> स्वास्थ्य चौकी: {profileInfo?.healthpost?.name}</p>
            <p>स्वास्थ्यकर्मी: {profileInfo?.anm?.name}</p>
            <p>स्वास्थ्यकर्मी सम्पर्क नं: {profileInfo?.anm?.user?.phone}</p>
            <p>स्वास्थ्य स्वयंसेविका: {profileInfo?.fchv?.name}</p>
            <p>स्वास्थ्य स्वयंसेविका सम्पर्क नं: {profileInfo?.fchv?.phone}</p>
          </div>
          <hr className="w-100" />
          <div className="responsible-person-info">
            <h5>पालिका विवरण</h5>
            <p> पालिका: {profileInfo?.municipality?.localBody}</p>
            <p> वडा: {profileInfo?.ward}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientProfile;
