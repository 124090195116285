/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import PaginationComp from "../../components/Pagination";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { FaEdit, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import BackButton from "../../_elements/BackButton";
import Loading from "../../components/Loading";
import Password from "../../components/Form/Password";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { PalikaUserValidation } from "../../_helpers/schema-validation";
import FormError from "../../components/Form/FormError";

const PalikaUsers = () => {
  const { pageNum, id } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [isLoading, setIsLoading] = useState(true);
  const [palikaUsers, setPalikaUsers] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    const getPalikas = async () => {
      setIsLoading(true);
      let response = await fetchWrapper.get(
        `/palikaUser?page=${pageNum}&palika=${id}`
      );
      setPalikaUsers(response);
      setIsLoading(false);
    };
    getPalikas();
  }, [pageNum, id]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PalikaUserValidation),
  });

  const watchedPhone = watch("phone");

  const onSubmit = async (body) => {
    try {
      const data = { ...body, palika: id };
      if (editData) {
        if (data.password === "") delete data.password;
        delete data.phone;
        let response = await fetchWrapper.patch(
          `/palikaUser/${editData?._id}`,
          data
        );
        if (response?._id) {
          let tempPalikaUsers = palikaUsers?.results?.filter(
            (res) => res._id !== editData._id
          );
          setPalikaUsers((prev) => ({
            ...prev,
            results: [response, ...tempPalikaUsers],
          }));
          // reset();
          setShowAdd(false);
          toast.success("Palika User Edited Successfully");
        }
      } else {
        let response = await fetchWrapper.post("/palikaUser", data);
        if (response?._id) {
          setPalikaUsers((prev) => ({
            ...prev,
            results: [response, ...prev.results],
          }));
          setShowAdd(false);
          toast.success("Palika User Added Successfully");
        }
      }
      reset();
    } catch (error) {}
  };

  const onDelete = async (id) => {
    try {
      await fetchWrapper.delete(`palikaUser/${id}`);
      let tempPalika = palikaUsers?.results.filter((res) => res._id !== id);
      setPalikaUsers((prev) => ({ ...prev, results: tempPalika }));
      toast.success("Palika User Deleted Successfully");
    } catch (error) {}
  };

  const [show, setShow] = useState(null);

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>
      {showAdd && (
        <Card>
          <Card.Header className="card-header-custom d-block">
            <h3>नयाँ पालिका प्रयोगकर्ता</h3>
          </Card.Header>
          <Card.Body className="p-4">
            <Form className=" px-4" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Form.Group as={Col} md={4}>
                  <Form.Label>नाम:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="नाम लेख्नुहोस्"
                    {...register("name")}
                    defaultValue={editData && editData?.name}
                  />
                  <FormError error={errors && errors?.name} />
                </Form.Group>

                <Form.Group as={Col} md={4}>
                  <Form.Label>
                    पद: <span className="text-danger"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("post")}
                    defaultValue={editData && editData?.post}
                    placeholder="ठेगाना लेख्नुहोस्"
                  />
                  <FormError error={errors && errors?.post} />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <Form.Label>
                    ठेगाना: <span className="text-danger"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("address")}
                    defaultValue={editData && editData?.address}
                    placeholder="ठेगाना लेख्नुहोस्"
                  />
                  <FormError error={errors && errors?.address} />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <Form.Label>
                    सम्पर्क नं:<span className="text-danger"></span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className="remove-step"
                    {...register("phone")}
                    defaultValue={editData && editData?.user?.phone}
                    disabled={editData}
                    placeholder="सम्पर्क नं लेख्नुहोस्"
                  />
                  <FormError error={errors && errors?.phone} />
                </Form.Group>
              </Row>
              <Card.Header className="card-header-custom px-0">
                <h3>लग इन विवरण</h3>
              </Card.Header>

              <Row>
                <Form.Group as={Col} md={4}>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="number"
                    className="remove-step"
                    disabled
                    value={watchedPhone || editData?.user?.phone}
                    placeholder="Username"
                  />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <Form.Label>पासवर्ड</Form.Label>
                  <Password
                    register={register}
                    id="password"
                    placeholder="पासवर्ड लेख्नुहोस्"
                  />
                  <FormError error={errors && errors?.password} />
                </Form.Group>
              </Row>
              <div className=" d-flex justify-content-end col-md-12">
                <Button variant="success" onClick={handleSubmit(onSubmit)}>
                  सुरक्षित
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}

      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>पालिका प्रयोगकर्ता</h2>
              <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                <FaPlusCircle />
                &nbsp; नयाँ पालिका प्रयोगकर्ता
              </Button>
            </Card.Header>

            <Card.Body className="p-0 mt-4">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>नाम</th>
                      <th>पद</th>
                      <th>ठेगाना</th>
                      <th>सम्पर्क नं</th>
                      <th>कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="d-flex justify-content-center fs-5">
                          <Loading size="2rem" />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      palikaUsers?.results?.map((palikaUser) => (
                        <tr key={palikaUser._id}>
                          <td>{palikaUser?.name}</td>
                          <td>{palikaUser?.post}</td>
                          <td>{palikaUser?.address}</td>
                          <td>{palikaUser?.user?.phone}</td>
                          <td>
                            {/* <Button
															variant="info"
															onClick={() => {
																history.push(
																	`/settings/palikaUsers/1/${palikaUsers?._id}`
																);
															}}
														>
															<FaEye />
														</Button> */}
                            <Button
                              variant="primary"
                              className="ml-2"
                              onClick={() => {
                                setShowAdd(!showAdd);
                                setEditData(palikaUser);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => setShow(palikaUser._id)}
                              className="ml-2"
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={page}
        total={palikaUsers?.totalResults}
        setPage={handleSetPage}
      />
      <DeleteModal
        show={show}
        onHide={() => setShow(false)}
        onDelete={() => onDelete(show)}
      />
    </Fragment>
  );
};

export default PalikaUsers;
