import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./layout/Layout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PrivateRoute = ({ component: Component, accessGranted, ...rest }) => {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);
  const role = user?.role;

  useEffect(() => {
    const localStorageUser = window.localStorage.getItem("userAccess");
    !localStorageUser && history.push("/login");
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          if (!accessGranted.includes(role)) return <Redirect to="/login" />;
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
      }}
    />
  );
};

// const FirstConnectedComponent = ContainerCreater(PrivateRoute);
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

const FirstConnectedComponent = PrivateRoute;
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

export {
  FirstConnectedComponent as PrivateRoute,
  // , SecondConnectedComponent as AuthRoute
};
