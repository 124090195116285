/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
//eslint-disable-next-line no-unused-vars
import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrashAlt, FaEye } from 'react-icons/fa';
import { Link, useParams, useHistory } from 'react-router-dom';
import AddHealthPost from './AddHealthPost';
import BackButton from '../../../_elements/BackButton';
import PaginationComp from '../../../components/Pagination';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import Loading from '../../../components/Loading';
import DeleteModal from '../../../components/DeleteModal';
import { toast } from 'react-toastify';

const HealthPost = () => {
	const [showAdd, setShowAdd] = useState(false);
	const { pageNum } = useParams();
	const [page, setPage] = useState(pageNum);
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [healthposts, setHealthPosts] = useState({});
	const [show, setShow] = useState(null);

	useEffect(() => {
		const getHealthPosts = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get(
				`/healthpost?page=${pageNum}&sortBy=createdAt:desc`
			);
			setHealthPosts(response);
			setIsLoading(false);
		};
		try {
			getHealthPosts();
		} catch (error) {
			toast.error(error?.message);
		}
	}, [pageNum]);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	const onDelete = async (id) => {
		try {
			await fetchWrapper.delete(`/healthpost/${id}`);
			let tempHP = healthposts?.results?.filter((hp) => hp._id !== id);
			setHealthPosts((prev) => ({ ...prev, results: tempHP }));
			toast.success('Healthpost Successfully Deleted');
		} catch (error) {}
	};

	return (
		<Fragment>
			<BackButton />
			{showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<AddHealthPost showAdd={showAdd} setShowAdd={setShowAdd} />
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom">
							<h2>स्वास्थ्य संस्थाहरु</h2>
							{!showAdd && (
								<Link
									to="/healthpost/create"
									className="btn btn-primary"
									variant="primary"
								>
									<FaPlusCircle />
									&nbsp; नयाँ स्वास्थ्य संस्था
								</Link>
							)}
						</Card.Header>
						<Card.Body className="p-0 mt-4">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr>
											<th>स्वास्थ्य संस्थाको नाम</th>
											<th>पालिका</th>
											<th>ठेगाना</th>
											<th>सम्पर्क व्यक्ति</th>
											<th>सम्पर्क नं</th>
											<th>FCHV संख्या</th>
											<th>कार्यहरू</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<tr>
												<td></td>
												<td></td>
												<td className="d-flex justify-content-center fs-5">
													<Loading size="2rem" />
												</td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										) : (
											healthposts?.results?.map((hp) => (
												<tr>
													<td>{hp.name}</td>
													<td>{hp?.palika?.localBody}</td>
													<td>{hp.address}</td>
													<td>{hp.contactPerson}</td>
													<td>{hp.contactPersonPhone}</td>
													<td>{hp.noOfSwyamSewak}</td>
													<td>
														<Button
															variant="info"
															onClick={() => {
																history.push(
																	`/settings/healthpost/1/${hp?.palika?._id}/${hp?._id}`
																);
															}}
														>
															<FaEye />
														</Button>
														<Button
															className="ml-2"
															onClick={() =>
																history.push(`/healthpost/update/${hp._id}`)
															}
														>
															<FaEdit />
														</Button>
														<Button
															variant="danger"
															className="ml-2"
															onClick={() => setShow(hp._id)}
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<PaginationComp
				page={page}
				total={healthposts?.totalResults || 0}
				setPage={handleSetPage}
			/>
			<DeleteModal
				show={show}
				onHide={() => setShow(false)}
				onDelete={() => onDelete(show)}
			/>
		</Fragment>
	);
};

export default HealthPost;
