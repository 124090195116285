/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrashAlt, FaEye } from 'react-icons/fa';
import AddANM from './AddANM';
import BackButton from '../../../_elements/BackButton';
import PaginationComp from '../../../components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import Loading from '../../../components/Loading';
import DeleteModal from '../../../components/DeleteModal';
import { toast } from 'react-toastify';

const ANM = () => {
	const { pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [showAdd, setShowAdd] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [anm, setAnm] = useState(null);
	const [editId, setEditId] = useState(null);

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	useEffect(() => {
		const getANM = async () => {
			setIsLoading(true);
			let response = await fetchWrapper.get(
				`/anm?page=${pageNum}&sortBy=createdAt:desc`
			);
			setAnm(response);
			setIsLoading(false);
		};
		try {
			getANM();
		} catch (error) {
			toast.error(error.message);
		}
	}, [pageNum]);
	const [show, setShow] = useState(null);

	const onDelete = async (id) => {
		try {
			await fetchWrapper.delete(`/anm/${id}`);
			let tempData = anm?.results?.filter((prev) => prev._id !== id);
			setAnm((prev) => ({ ...prev, count: prev.count - 1, results: tempData }));
			toast.success('ANM deleted successfully');
		} catch (error) {}
	};
	const onAdd = (data) => {
		setIsLoading(true);
		setAnm((prev) => ({ ...prev, results: [data, ...prev.results] }));
		setIsLoading(false);
	};
	return (
		<Fragment>
			<BackButton />
			{showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<AddANM
							showAdd={showAdd}
							setShowAdd={setShowAdd}
							editId={editId}
							onAdd={onAdd}
						/>
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col md={12}>
					<Card className="px-1">
						<Card.Header className="card-header-custom">
							<h2>स्वास्थ्यकर्मी</h2>
							<Button
								variant="primary"
								onClick={() => {
									setEditId(null);
									setShowAdd(!showAdd);
								}}
							>
								<FaPlusCircle />
								&nbsp; नयाँ प्रयोगकर्ता
							</Button>
						</Card.Header>
						<Card.Body className="p-0">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr>
											<th>नाम</th>
											<th>पालिका</th>
											<th>स्वास्थ्य संस्था</th>
											<th>पद</th>
											<th>फोन</th>
											<th>NHPC नं.</th>
											<th>कार्यहरू</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<tr>
												<td></td>
												<td></td>
												<td>
													<Loading size="2rem" />
												</td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										) : (
											anm?.results?.map((v) => (
												<tr key={v?._id}>
													<td>{v?.name}</td>
													<td>{v?.palika?.localBody}</td>
													<td>{v?.healthpost?.name}</td>
													<td>{v?.post}</td>
													<td>{v?.user?.phone}</td>
													<td>{v?.nhpc}</td>
													<td>
														<Button
															variant="primary"
															onClick={() => history.push(`profile/${v?._id}`)}
														>
															<FaEye />
														</Button>
														<Button
															variant="info"
															className="ml-2"
															onClick={() => {
																setEditId(v?._id);
																setShowAdd(true);
															}}
														>
															<FaEdit />
														</Button>
														<Button
															variant="danger"
															className="ml-2"
															onClick={() => setShow(v?._id)}
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PaginationComp
				page={page}
				total={anm?.totalResults}
				setPage={handleSetPage}
			/>
			<DeleteModal
				show={show}
				onHide={() => setShow(false)}
				onDelete={() => onDelete(show)}
			/>
		</Fragment>
	);
};

export default ANM;
