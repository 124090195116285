import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const AbdominalExam = () => {
	const { id, pageNum } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [abds, setAbds] = useState(null);
	useEffect(() => {
		const getAbds = async () => {
			const response = await fetchWrapper.get(
				`/patient/profile/${id}/abdomenalExamination?page=${page}`
			);
			setAbds(response);
		};
		try {
			getAbds();
		} catch (error) {}
	}, [id, page]);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th className="text-center">Fundal Height (cm)</th>
								<th className="text-center">Fetal Lie</th>
								<th className="text-center">Fetal Presentation</th>
								<th className="text-center">Fetal Heart Rate (bpm)</th>
								{/* <th>Posted By</th> */}
							</tr>
						</thead>
						<tbody>
							{abds?.results?.map((abd) => (
								<tr>
									<td>{abd?.postedAt}</td>
									<td className="text-center">{abd?.fundalHeight}</td>
									<td className="text-center">{abd?.fetalLie}</td>
									<td className="text-center">{abd?.fetalPresentation}</td>
									<td className="text-center">{abd?.fetalHeartSound}</td>
									{/* <td></td> */}
								</tr>
							))}
							{/* <tr>
								<td>2022/09/10</td>
								<td className="text-center">150</td>
								<td className="text-center">2</td>
								<td className="text-center">3</td>
								<td>Mary Jane</td>
							</tr> */}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={abds?.totalResults}
			/>
		</Fragment>
	);
};

export default AbdominalExam;
