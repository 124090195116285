import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useState } from 'react';
import { useEffect } from 'react';

const EditorComp = ({ editorStyle, defaultValue, disabled, onChange, setValue, id }) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const onEditorStateChange = (editorState) => {
		setEditorState(editorState);
		let tempContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		setValue(id, tempContent);
		onChange && onChange(editorState);
	};
	useEffect(() => {
		if (defaultValue) {
			const contentBlock = htmlToDraft(defaultValue);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const defaultEditorState = EditorState.createWithContent(contentState);
				setEditorState(defaultEditorState);
			}
		}
	}, [defaultValue]);
	return (
		<StyledEditorContainer style={editorStyle}>
			<Editor
				editorState={editorState}
				wrapperClassName="demo-wrapper"
				editorClassName="demo-editor"
				onEditorStateChange={onEditorStateChange}
				readOnly={disabled}
			/>
		</StyledEditorContainer>
	);
};

export default EditorComp;

export const StyledEditorContainer = styled.div`
	border: 1px solid #b8bcb8;
	border-radius: 5px;
	/* overflow: hidden; */
	padding: 0 0.75rem;
	background: white;
	min-height: 295px;
`;
