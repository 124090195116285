/** @jsxRuntime classic */
/** @jsx jsx */
import {
  jsx,
  // css
} from "@emotion/react";
import { Fragment, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import AddDoctor from "./AddDoctor";
import BackButton from "../../../_elements/BackButton";
import { useHistory, useParams } from "react-router-dom";
import PaginationComp from "../../../components/Pagination";
import { useEffect } from "react";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import Loading from "../../../components/Loading";
import DeleteModal from "../../../components/DeleteModal";
import { toast } from "react-toastify";

const Doctor = ({ match }) => {
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [doctors, setDoctors] = useState();
  const [editId, setEditId] = useState(null);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  useEffect(() => {
    const getDoctors = async () => {
      let response = await fetchWrapper.get(`/doctor?page=${pageNum}`);
      setDoctors(response);
    };
    try {
      setIsLoading(true);
      getDoctors();
    } catch (error) {}
    setIsLoading(false);
  }, [pageNum]);

  const onDelete = async (id) => {
    try {
      await fetchWrapper.delete(`/doctor/${id}`);
      const tempdoc = doctors?.results?.filter((doc) => doc._id !== id);
      setDoctors((prev) => ({ ...prev, results: tempdoc }));
      toast.success("Doctor deleted Successfully");
    } catch (error) {}
  };
  const [show, setShow] = useState(null);

  const onAdd = (data) => {
    setIsLoading(true);
    setDoctors((prev) => ({ ...prev, results: [data, ...prev.results] }));
    setIsLoading(false);
  };

  return (
    <Fragment>
      <BackButton />
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddDoctor
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              id={editId}
              onAdd={onAdd}
              setDoctors={setDoctors}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom">
              <h2>डाक्टर</h2>
              <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                <FaPlusCircle />
                &nbsp; नयाँ प्रयोगकर्ता
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>नाम</th>
                      <th>अस्पताल</th>
                      <th>फोन</th>
                      <th>NMC नं.</th>
                      <th>कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <Loading size="2rem" />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      doctors?.results?.map((doc) => (
                        <tr key={doc._id}>
                          <td>{doc.name}</td>
                          <td>{doc.associatedHospitalName}</td>
                          <td>{doc.phone}</td>
                          <td>{doc.councilNumber}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setEditId(doc._id);
                                setShowAdd(true);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => setShow(doc._id)}
                              className="ml-2"
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginationComp
        page={page}
        total={doctors?.totalResults}
        setPage={handleSetPage}
      />
      <DeleteModal
        show={show}
        onHide={() => setShow(false)}
        onDelete={() => onDelete(show)}
      />
    </Fragment>
  );
};

export default Doctor;
