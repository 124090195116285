import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import {
  FaChartBar,
  FaCog,
  FaFile,
  FaHeadphones,
  FaHome,
  FaQuestionCircle,
  FaUser,
} from "react-icons/fa";
import { MdPregnantWoman } from "react-icons/md";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

export const sidebarData = [
  {
    id: 1,
    title: "ड्यासबोर्ड",
    title_en: "Dashboard",
    icon: <FaHome />,
    path: "/",
  },
  {
    id: 2,
    title: "Activity Log",
    title_en: "Activity Log",
    icon: <FaFile />,
    path: "/activitylog/1",

    rule: "view:jariVayekoSifaris",
  },

  {
    id: 3,
    title: "आकस्मिक संकेत",
    title_en: "आकस्मिक संकेत",
    icon: <FaUser />,
    badge: <EmergencyBadge />,
    path: "/emergency/1",
    rule: "view:sifarisAnurodPranali",
  },
  {
    id: "patient",
    title: "गर्भवती/सुत्केरी महिला",
    title_en: "Pregnant Women",
    icon: <MdPregnantWoman />,
    path: "/settings/patient/1",
    rule: "view:patient",
  },
  {
    id: 6,
    title: "सेटिंग्स",
    title_en: "Settings",
    icon: <FaCog />,
    path: "/settings",
    rule: "view:settings",
  },
  {
    id: 7,
    title: "रिपोर्ट",
    title_en: "Report",
    icon: <FaChartBar />,
    path: "/reports/1",
    rule: "view:reports",
  },

  {
    id: 9,
    title: "FAQ",
    icon: <FaQuestionCircle />,
    path: "/faq/1",
    rule: "view:faq",
  },
  {
    id: 10,
    title: "सपोर्ट",
    title_en: "Support",
    icon: <FaHeadphones />,
    path: "/support",
    rule: "view:support",
  },
];

function EmergencyBadge() {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const getAlertCount = async () => {
      let response = await fetchWrapper.get("stats");
      setCount(response?.alert);
    };
    try {
      getAlertCount();
    } catch (error) {}
  }, []);
  return <Badge variant="danger">{count}</Badge>;
}
