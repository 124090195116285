import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PaginationComp from '../../../components/Pagination';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';

const Contraception = ({ count }) => {
	const { pageNum, id } = useParams();
	const history = useHistory();
	const [page, setPage] = useState(pageNum);
	const [contraceptive, setContraceptive] = useState(null);
	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};

	useEffect(() => {
		const getContraceptives = async () => {
			const response = await fetchWrapper.get(
				`/patient/profile/${id}/contraceptive`
			);
			setContraceptive(response);
		};
		try {
			getContraceptives();
		} catch (error) {}
	}, [id]);
	return (
		<Fragment>
			<Card>
				<div className="table-responsive">
					<Table striped hover responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th>
									<p>Condom</p>
									<p>Duration</p>
								</th>
								<th>
									<p>Depo Provera</p>
									<p>Duration</p>
								</th>
								<th>
									<p>IUCD</p>
									<p>Duration</p>
								</th>
								<th>
									<p>Implant</p>
									<p>Duration</p>
								</th>
								<th>
									<p>OCP</p>
									<p>Duration</p>
								</th>
								<th>
									<p>ECP</p>
									<p>Duration</p>
								</th>
								<th>
									<p>Sayana Press</p>
									<p>Duration</p>
								</th>
								<th>Other</th>
							</tr>
						</thead>
						<tbody>
							{contraceptive?.results?.map((c) => (
								<tr>
									<td>{c?.postedAt}</td>
									<td>
										<p>{c?.Condom || '-'}</p>
										<p>{durationConverter(c?.CondomDuration)}</p>
									</td>
									<td>
										<p>{c?.DepoProvera || '-'}</p>
										<p>{durationConverter(c?.DepoProveraDuration)}</p>
									</td>
									<td>
										<p>{c?.IUCD || '-'}</p>
										<p>{durationConverter(c?.IUCDDuration)}</p>
									</td>
									<td>
										<p>{c?.Norplant || '-'}</p>
										<p>{durationConverter(c?.NorplantDuration)}</p>
									</td>
									<td>
										<p>{c?.OCP || '-'}</p>
										<p>{durationConverter(c?.OCPDuration)}</p>
									</td>
									<td>
										<p>{c?.ECP || '-'}</p>
										<p>{durationConverter(c?.ECPDuration)}</p>
									</td>
									<td>
										<p>{c?.SayanaPress || '-'}</p>
										<p>{durationConverter(c?.SayanaPressDuration)}</p>
									</td>
									<td>
										<p>{c?.other?.name}</p>
										<p>{c?.other?.date}</p>
										<p>{durationConverter(c?.other?.duration)}</p>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Card>
			<PaginationComp
				page={page}
				setPage={handleSetPage}
				total={contraceptive?.totalResults}
			/>
		</Fragment>
	);
};

export default Contraception;

function durationConverter(duration = ':') {
	const [year, month] = duration.split(':');
	return `${year ? `${year} year/s ` : ''}${month ? `${month} month/s` : ''}`;
}
