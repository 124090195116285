import React, { useRef, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { MdPrint } from "react-icons/md";
import camelToNormal from "../_helpers/camelToNormal";
import { useReactToPrint } from "react-to-print";

export default function Print({ title, data = {}, fields = [] }) {
  const [open, setOpen] = useState(false);
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        size="sm"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
        }}
        onClick={open ? handleClose : handleOpen}
      >
        <MdPrint /> Print
      </Button>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body className="content" ref={printRef}>
          <div>
            <h3 className="text-center mb-4">{title}</h3>
          </div>
          {data?.postedAt && (
            <div className="d-flex mb-3">
              <p className="font-weight-bold">Date:</p>
              <p className="ml-2">{data?.postedAt}</p>
            </div>
          )}
          <ListGroup>
            {fields?.map((field, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{camelToNormal(field.split("__")[0])}</p>
                  <p>
                    {typeof data?.[field] === "boolean"
                      ? data?.[field.split("__")[0]]
                        ? "Yes"
                        : "No"
                      : data?.[field.split("__")[0]] ?? "-"}{" "}
                    {field.split("__")[1]}
                  </p>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handlePrint}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
            }}
          >
            <MdPrint /> Print
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
