export default function camelToNormal(camelCase) {
  // Split the camelCase string into an array of words
  var words = camelCase.split(/(?=[A-Z])/);

  // Convert the words to lowercase and capitalize the first letter of each word
  var text = words
    .map(function (word) {
      var capitalizedWord =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      return capitalizedWord;
    })
    .join(" ");

  return text;
}
