/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
// eslint-disable-next-line
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from '../../../_elements/BackButton';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { HealthPostValidation } from '../../../_helpers/schema-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from '../../../components/Form/FormError';
import { useSelector } from 'react-redux';

export const InputFieldStyleOverwrite = css`
	border: 1px solid #ccc !important;
	border-radius: 0.25rem;
	width: 164px !important;
	height: calc(1.5em + 0.75rem + 2px);
	font: 12px 'Roboto-Regular' !important;
	color: #333 !important;
	padding: 3px 4px !important;
	background-color: #fff !important;

	:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;

const AddHealthPost = () => {
	const { id } = useParams();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [palikas, setPalikas] = useState();
	const [healthPost, setHealthPost] = useState(null);
	const user = useSelector((state) => state.users.user);
	const {
		register,
		watch,
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(HealthPostValidation),
		defaultValues: {},
	});

	useEffect(() => {
		const getPalikas = async () => {
			let response = await fetchWrapper.get('/palika?limit=100');
			setPalikas(response);
		};
		const getHealthPost = async () => {
			let response = await fetchWrapper.get(`/healthpost/${id}`);
			setHealthPost(response);
		};
		try {
			setIsLoading(true);
			user?.role === 'admin' && getPalikas();
			if (id) {
				getHealthPost();
			}
		} catch (error) {}
		setIsLoading(false);
	}, [id, user]);
	const selectedPalika = watch('palika');
	const ward = useMemo(() => {
		if (selectedPalika) {
			let noOfWards = palikas?.results?.find(
				(palika) => palika._id === selectedPalika
			).noOfWards;
			return noOfWards;
		}
		if (healthPost) {
			return palikas?.results?.find(
				(palika) => palika._id === healthPost?.palika
			)?.noOfWards;
		}

		return 0;
	}, [selectedPalika, palikas, healthPost]);

	useEffect(() => {
		if (healthPost) {
			setValue('address', healthPost?.address);
			setValue('contactPerson', healthPost?.contactPerson);
			setValue(
				'contactPersonDesignation',
				healthPost?.contactPersonDesignation
			);
			setValue('contactPersonPhone', healthPost?.contactPersonPhone);
			setValue('name', healthPost?.name);
			setValue('noOfSwyamSewak', healthPost?.noOfSwyamSewak);
			setValue('phone', healthPost?.phone);
			if (palikas?.results?.length > 0) {
				setValue('palika', healthPost?.palika);
				setValue('ward', healthPost?.ward);
			}
		}
	}, [healthPost, setValue, palikas]);

	const onSubmit = async (data) => {
		try {
			let response = null;
			if (id) {
				response = await fetchWrapper.patch(`/healthpost/${id}`, data);
				if (response?._id) toast.success('Healthpost successfully edited');
			} else {
				response = await fetchWrapper.post('/healthpost', data);
				if (response?._id) toast.success('Healthpost successfully added');
			}
			if (response?._id) {
				reset();
				history.goBack();
			}
		} catch (error) {}
	};

	return (
		<Fragment>
			<BackButton />
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Card>
					<Card.Header className="card-header-custom">
						<h3>स्वास्थ्य संस्थाको विवरण</h3>
					</Card.Header>
					<Card.Body>
						<Row>
							{user?.role === 'admin' && (
								<Form.Group as={Col} md={4}>
									<Form.Label>पालिका</Form.Label>
									<Form.Control as="select" {...register('palika')} size="sm">
										<option value="">--पालिका छान्नुहोस्--</option>
										{palikas?.results?.map((option) => (
											<option
												key={option._id}
												value={option._id}
												{...(healthPost && {
													selected: option._id === healthPost?.palika,
												})}
											>
												{option.localBody}
											</option>
										))}
										<FormError error={errors && errors?.palika} />
									</Form.Control>
								</Form.Group>
							)}
							<Form.Group as={Col} md={4}>
								<Form.Label>वडा</Form.Label>
								<Form.Control as="select" {...register('ward')} size="sm">
									<option value="">--वडा छान्नुहोस्--</option>
									{Array.from({
										length:
											user?.role === 'admin'
												? parseInt(ward)
												: parseInt(user?.profile?.municipality?.noOfWards),
									}).map((_, i) => (
										<option key={i + 1} value={(i + 1).toString()}>
											{i + 1}
										</option>
									))}
								</Form.Control>
								<FormError error={errors && errors?.ward} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label>स्वास्थ्य संस्थाको नाम</Form.Label>
								<Form.Control
									type="text"
									{...register('name')}
									defaultValue={healthPost && healthPost?.name}
									placeholder="स्वास्थ्य संस्थाको नाम लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.name} />
							</Form.Group>

							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">फोन</Form.Label>
								<Form.Control
									type="number"
									autoComplete="new-password"
									className="remove-step"
									{...register('phone')}
									defaultValue={healthPost && healthPost?.phone}
									placeholder="फोन नं. लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.phone} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">ठेगाना</Form.Label>
								<Form.Control
									type="text"
									{...register('address')}
									defaultValue={healthPost && healthPost?.address}
									placeholder="ठेगाना लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.address} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">सम्पर्क व्यक्ति</Form.Label>
								<Form.Control
									type="text"
									{...register('contactPerson')}
									defaultValue={healthPost && healthPost?.contactPerson}
									placeholder="सम्पर्क व्यक्तिको नाम लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.contactPerson} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">सम्पर्क व्यक्तिको पद </Form.Label>
								<Form.Control
									type="text"
									{...register('contactPersonDesignation')}
									defaultValue={
										healthPost && healthPost?.contactPersonDesignation
									}
									placeholder="सम्पर्क व्यक्तिको पद लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.contactPersonDesignation} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">सम्पर्क व्यक्तिको नं</Form.Label>
								<Form.Control
									type="number"
									className="remove-step"
									{...register('contactPersonPhone')}
									defaultValue={healthPost && healthPost?.contactPersonPhone}
									placeholder="सम्पर्क व्यक्तिको नं लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.contactPersonPhoneNo} />
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label className="mb-0">
									स्वास्थ्य स्वयंसेविकाको संख्या
								</Form.Label>
								<Form.Control
									type="number"
									{...register('noOfSwyamSewak')}
									defaultValue={healthPost && healthPost?.noOfSwyamSewak}
									placeholder="स्वास्थ्य स्वयंसेविकाको संख्या लेख्नुहोस्"
								/>
								<FormError error={errors && errors?.noOfSwyamSewak} />
							</Form.Group>
						</Row>
					</Card.Body>

					<Card.Footer className="d-flex justify-content-end align-items-center">
						<Button
							variant="secondary"
							className="mr-2"
							onClick={() => history.goBack()}
							disabled={isLoading}
						>
							रद्द गर्नुहोस्
						</Button>
						<Button type="submit" variant="success" disabled={isLoading}>
							{isLoading ? (
								<VscLoading className="spin" />
							) : (
								<Fragment>{id ? 'अपडेट' : 'सुरक्षित'} गर्नुहोस्</Fragment>
							)}
						</Button>
					</Card.Footer>
				</Card>
			</Form>
		</Fragment>
	);
};

export default AddHealthPost;
