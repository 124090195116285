/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sidebarData } from './SidebarData';
import SidebarButtonRenderer from './SidebarButtonRenderer';
// import { checkPermission } from '../../_helpers/roles/check-roles';

const Sidebar = () => {
	const user = useSelector((state) => state.users?.user);
	return (
		<div className="sidebar-wrapper">
			<div className="sidebar-top">
				<Link to="/">
					<img src={'/assets/images/Logo.jpeg'} className="logo" alt="" />
				</Link>
				<div>
					<h5>Patan Academy of Health Science(PAHS)</h5>

					<p
						css={css`
							font-size: 0.9rem;
						`}
					>
						Lagankhel, Lalitpur, Nepal
					</p>
				</div>
			</div>
			<hr
				css={css`
					margin: 0 auto;
					width: 90%;
					border-top-color: #dedede !important;
				`}
			/>
			<h4
				css={css`
					text-align: center;
					padding: 0.5rem 0;
					color: black;
				`}
			>
				Cloud In The Hills
			</h4>
			<hr
				css={css`
					margin: 0 auto;
					width: 90%;
					border-top-color: #dedede !important;
				`}
			/>
			<div className="menus">{sidebarData && <SidebarButtonRenderer data={sidebarData} user={user} />}</div>
		</div>
	);
};

export default Sidebar;
