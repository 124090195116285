import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';

const Password = ({ placeholder, register, onChange, id }) => {
	const [type, setType] = useState('password');

	const onView = () => {
		if (type === 'password') {
			setType('text');
		} else if (type === 'text') {
			setType('password');
		} else {
			setType('password');
		}
	};

	return (
		<div className="password-input">
			<Form.Control
				type={type}
				{...register(id)}
				placeholder={placeholder}
				{...(onChange && { onChange })}
			/>
			<FaEye className="pw-view" onClick={onView} />
		</div>
	);
};

export default Password;
