import React from 'react';
import { useMemo } from 'react';

const YoutubeEmbed = ({ url }) => {
	const youtubeEmbedId = useMemo(() => url?.split('/')[3], [url]);
	return (
		<div>
			<iframe
				width="853"
				height="480"
				src={`https://www.youtube.com/embed/${youtubeEmbedId}`}
				// frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
				className="w-100"
			/>
		</div>
	);
};

export default YoutubeEmbed;
