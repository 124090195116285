/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useState, useEffect, useCallback } from "react";
import { Card, Col, Row, Table, Form, Modal, Button } from "react-bootstrap";
import { FaEye, FaSearch, FaTrash, FaUser } from "react-icons/fa";
import PaginationComp from "../../components/Pagination";
import { useParams, useHistory } from "react-router-dom";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { ageCalculator } from "../../_helpers/methods";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setUiMessage } from "../../_redux/slices/ui";

const Emergency = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum);
  const [filter, setFilter] = useState({
    name: null,
    resolved: "",
  });

  const [showRemark, setShowRemark] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [remarks, setRemarks] = useState();
  const [emergencyLists, setEmergencyLists] = useState();

  const user = useSelector((state) => state.users?.user);

  const getEmergencyAlert = useCallback(async () => {
    let response = await fetchWrapper.get(
      `/alert?page=${page}${filter?.name ? `&name=${filter?.name}` : ""}${
        filter?.resolved
          ? filter?.resolved === "resolved"
            ? `&resolved=true`
            : filter?.resolved === "notResolved"
            ? `&resolved=false`
            : ""
          : ""
      }`
    );
    setEmergencyLists(response);
  }, [page, filter]);

  useEffect(() => {
    try {
      getEmergencyAlert();
    } catch (error) {}
  }, [getEmergencyAlert]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  async function getRemarks(id) {
    try {
      const response = await fetchWrapper.get(`/alert/${id}/remarks`);
      setRemarks(response);
      setShowRemark(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleDeleteEmergency(id) {
    return async () => {
      try {
        await fetchWrapper.delete(`/alert/${id}`);
        dispatch(setUiMessage("Emergency Alert Deleted"));
        getEmergencyAlert();
        setShowDelete(false);
      } catch (error) {
        console.log(error);
      }
    };
  }

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card
            css={css`
              min-height: 600px;
            `}
          >
            <Card.Header className="card-header-custom">
              <h2>आकस्मिक संकेत विवरण</h2>
              <div
                className="card-header-form"
                css={css`
                  display: flex;
                  flex-grow: 1;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 8px;
                  .input-group {
                    width: auto;
                  }
                  .form-control {
                    min-width: 300px;
                  }
                  select {
                    border-radius: 5% / 50%;
                    height: 38px;
                  }
                `}
              >
                <div>
                  <Form.Control
                    as="select"
                    placeholder="Search Patient Name"
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        resolved: e.target.value,
                      }))
                    }
                  >
                    <option value="">All Patient</option>
                    <option value="notResolved">Emergency</option>
                    <option value="resolved">Resolved</option>
                  </Form.Control>
                </div>
                <div className="input-group">
                  <Form.Control
                    type="text"
                    className="custom-search"
                    placeholder="Search Patient Name"
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />

                  <div className="input-group-btn">
                    <button className="btn btn-primary input-search-icon">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: left;
                        }
                      `}
                    >
                      <th>Patient Name</th>
                      <th style={{ width: "10%" }}>Contact No.</th>
                      <th style={{ width: "4%" }}>Age</th>
                      <th style={{ width: "15%" }}>
                        <p>ANM Name/</p>
                        <p>Health Post</p>
                      </th>
                      <th style={{ width: "12%" }}>
                        <p>Posted Date</p>
                      </th>
                      <th style={{ width: "25%" }}>Emergency Alert Info</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emergencyLists?.results?.map((emergency) => (
                      <tr key={emergency._id}>
                        <td>{emergency?.patient?.name}</td>
                        <td>{emergency?.patient?.user?.phone}</td>
                        <td>
                          {ageCalculator(
                            user?.today,
                            emergency?.patient?.dateOfBirth
                          )}
                        </td>
                        <td>
                          <p>{emergency?.anm?.name}</p>
                          <p>{emergency?.healthpost?.name}</p>

                          <br />
                          {emergency?.anm?.healthpost}
                        </td>
                        <td>
                          {emergency?.postedAt} <br />{" "}
                          {moment(emergency?.createdAt).format("hh:mm A")}
                        </td>
                        <td>
                          {JSON.stringify(emergency?.reason || {})
                            .replace(/[{}"]/g, " ")
                            .split(",")
                            .map((t, i) => (
                              <p key={i}>{t}</p>
                            ))}
                        </td>
                        <td>
                          <div className="p-2">
                            {emergency?.remarks?.length > 0 && (
                              <Button
                                size="sm"
                                className="mr-2"
                                onClick={() => getRemarks(emergency?._id)}
                              >
                                <FaEye />
                              </Button>
                            )}
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() => setShowDelete(emergency?._id)}
                            >
                              <FaTrash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PaginationComp
        page={page}
        total={emergencyLists?.totalResults}
        setPage={handleSetPage}
      />
      {showRemark && (
        <Modal show={showRemark} onHide={() => setShowRemark(false)}>
          <Modal.Header>
            <h2>Comments</h2>
          </Modal.Header>
          <Modal.Body>
            <div>
              {remarks && remarks?.remarks?.length > 0 ? (
                remarks?.remarks?.map((remark) => (
                  <div className="p-2 border-bottom mb-2 shadow-sm">
                    <h4 className="d-flex align-items-center">
                      <FaUser className="mr-2" />
                      <span>{remark?.postedBy?.profile?.name}</span>
                    </h4>
                    <p className="mb-2">
                      <small>
                        <i>{remark?.postedAt}</i>
                      </small>
                    </p>
                    <p>{remark?.remark}</p>
                  </div>
                ))
              ) : (
                <>No Remarks</>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showDelete && (
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header>
            <h2>Confirm Delete?</h2>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Are you sure you want to delete this emergency?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDelete(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteEmergency(showDelete)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export default Emergency;
