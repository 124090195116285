import styled from "@emotion/styled";
import React from "react";
import StatCard from "../_elements/StatCard";
import { fetchWrapper } from "../_helpers/fetchWrapper";
import Chart from "react-apexcharts";
import { Row } from "react-bootstrap";
import { RiGovernmentFill, RiArticleFill } from "react-icons/ri";
import { GiNurseFemale } from "react-icons/gi";
import { englishToNepaliNumber as np } from "nepali-number";
import {
  FaBuilding,
  FaStethoscope,
  FaUserAlt,
  FaUserNurse,
} from "react-icons/fa";

function Dashboard() {
  const [stats, setStats] = React.useState({});
  const [chartData, setChartData] = React.useState({
    palikaStats: [],
  });

  React.useEffect(() => {
    const getDashBoardStats = async () => {
      try {
        let response = await fetchWrapper.get("/stats/");
        setStats(response);
      } catch (err) {
        console.log(err);
      }
    };
    getDashBoardStats();
  }, []);

  React.useEffect(() => {
    const getChartData = async () => {
      try {
        let response = await fetchWrapper.get(`/stats/dashboard`);
        // console.log("dashboard", response);
        if (response) {
          setChartData(response);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getChartData();
  }, []);
  const options = React.useMemo(() => {
    let categories =
      chartData?.palikaStats?.length > 0
        ? chartData?.palikaStats?.map((data) => data?.name)
        : [];
    return {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        logarithmic: true,
        logBase: 0.25,
        label: {
          formatter: (value) => np(value),
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return np(val);
          },
        },
      },
    };
  }, [chartData]);

  const series = React.useMemo(() => {
    let data = chartData?.palikaStats?.reduce(
      (acc, curr) => [
        {
          name: "गर्भवती/सुत्केरी महिला",
          data: [...acc[0]?.data, curr?.patients],
        },
        {
          name: "स्वास्थ्य संस्था",
          data: [...acc[1]?.data, curr?.healthposts],
        },
        {
          name: "स्वास्थ्यकर्मी",
          data: [...acc[2]?.data, curr?.anms],
        },
      ],
      [{ data: [] }, { data: [] }, { data: [] }]
    );

    return data;
  }, [chartData]);

  return (
    <div>
      <Row className="mt-3 mb-2">
        <StatCard
          title="पालिका"
          number={stats?.palika}
          icon={<RiGovernmentFill />}
          path="settings/palika/1"
        />
        <StatCard
          title="स्वास्थ्य संस्था"
          number={stats?.healthpost}
          icon={<FaBuilding />}
          path="settings/healthpost/1"
        />
        <StatCard
          title="स्वास्थ्यकर्मी"
          number={stats?.anm}
          icon={<GiNurseFemale />}
          path="settings/anm/1"
        />
        <StatCard
          title="गर्भवती/सुत्केरी महिला"
          number={stats?.patient}
          icon={<FaUserAlt />}
          path="settings/patient/1"
        />
        <StatCard
          title="डाक्टर"
          number={stats?.doctor}
          icon={<FaStethoscope />}
          path="settings/doctor/1"
        />
        <StatCard
          title="स्वास्थ्य स्वयंसेविका"
          number={stats?.fchv}
          icon={<FaUserNurse />}
          path="settings/fchv/1"
        />
        <StatCard
          title="सामग्री"
          number={stats?.explore}
          icon={<RiArticleFill />}
          path="settings/materials/1"
        />
      </Row>
      <StyledChartsContainer className="shadow-md row">
        <div className="col-8 bg-white p-4 rounded-5">
          <div>
            <Chart options={options} series={series} type="bar" key={options} />
          </div>
        </div>
        <div className="col-4 bg-white p-4 ">
          <h4 className="">स्वास्थ्य संस्था</h4>
          <div className="overflow-auto" style={{ height: "500px" }}>
            {chartData?.healthpostStats?.map((stat) => (
              <div
                key={stat._id}
                className="bg-light p-2 d-flex justify-content-between align-items-center mb-2"
              >
                <span>{stat?.name}</span>
                <span
                  className="count-badge rounded-circle bg-primary text-white cursor-pointer"
                  title="गर्भवती/सुत्केरी महिला"
                >
                  {np(stat.patients)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </StyledChartsContainer>
    </div>
  );
}

export default Dashboard;

const StyledChartsContainer = styled.div`
  padding: 2rem 0;
  height: max-content;
  .chart-btn {
    border: none;
    background: transparent;
    margin-inline: 1rem;
    &:focus,
    &:active,
    &:hover {
      outline: none;
    }
  }
  .count-badge {
    width: 3rem;
    aspect-ratio: 1 / 1;
    display: grid;
    place-content: center;
  }
`;

// this.state = {
//   series: ,
// };
