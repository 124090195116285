import React from 'react';

const FormError = ({ error }) => {
	if (error) {
		return (
			<p className="form-error m-0" style={{ lineHeight: 1.2 }}>
				{error?.message}
			</p>
		);
	} else return null;
};

export default FormError;
